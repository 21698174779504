import * as xlsx from 'xlsx';

export const exportToExcel = (data: any[]) => {


  const wb = xlsx.utils.book_new();
 // const ws = xlsx.utils.json_to_sheet(data, {cellDates: true, cellStyles: true , dateNF: 'yyyy-mm-dd HH:mm:ss'});
  const ws = xlsx.utils.json_to_sheet(data, {cellDates: true, cellStyles: true , dateNF: 'dd/mm/yyyy HH:mm:ss', WTF: false});



  xlsx.utils.book_append_sheet(wb,ws,'slc-dashboard');
  xlsx.writeFile(wb,'slc-export.xlsx');



}
