import React, { useContext, useEffect, useState } from 'react';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { useNavigate, useParams } from 'react-router-dom';
import { fetcher } from '../../../../extras/fetcher';
import { Alert, Badge, Button, Card, InputNumber, message, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CopyOutlined } from '@ant-design/icons';
import { IkeaTripInterface } from '../../../../types/ikeaTrip.interface';
import { CheckDeliveryAlert } from './CheckDeliveryAlert';
import Cookies from 'universal-cookie';
import { PermError } from '../../NotFoundPage/PermError';


interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}



export const SerialsOfPosition = (props: Props) => {

  interface SerialsInterface { codeId: number, code: string, serials: string[], position: number, count: number};
  const cookies = new Cookies();
  const [serialsData, setSerialsData] = useState<SerialsInterface[]>([]);

  const [serialRow, setSerialRow] = useState<number>(cookies.get('serialRow') ? cookies.get('serialRow') : 20);

  const [value, setValue] = useState<string>('TEST TEST');

  const [loading, setLoading] = useState<boolean>(false);

  const [truckNotLoaded ,setTruckNotLoaded] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const {windowHeight} = useWindowDimensions();

  const [messageApi, contextHolder] = message.useMessage();

  const [permError, setPermError] = useState<boolean>(false);



  useEffect(() => {
    cookies.set('serialRow',serialRow);
  },[serialRow]);


  const linkParams = useParams();
  const navi = useNavigate();

  useEffect(() => {

    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Wysylka',
        label: 'Wysyłka',
      },

      {
        href: '/trip/',
        key: 'Tripy',
        label: 'Tripy',
      },

      {
        href: `/trip/${linkParams.trip}`,
        key: 'oneTrip',
        label: linkParams.trip,
      },

      {
        href: `/trip/${linkParams.trip}/${linkParams.delivery}`,
        key: 'oneDelivery',
        label: linkParams.delivery,
      },

      {
        href: `/trip/${linkParams.trip}/${linkParams.delivery}/serials`,
        key: 'oneDelivery',
        label: 'Numery Seryjne',
      },
    ];


    props.handleSetMainTitle(`Delivery: ${linkParams.delivery}`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['tripy']);

  }, []);



  const copyClick = (val: string,message: string) => {

    navigator.clipboard.writeText(val);

    messageApi.open({
      type: 'success',
      content: message,
    });
  };



  const getData = async () => {
    await setLoading(true);

    const data = await fetcher(`ikea/trip/${linkParams.trip}/${linkParams.delivery}/serials`);


    if(data.status === 403) {
      setPermError(true);
      return;
    }

    const trip = await fetcher(`ikea/trip/${linkParams.trip}`);

    const tripData : IkeaTripInterface = trip.result;

    if(tripData.loadedAt === null) {
      setTruckNotLoaded(true);
    }

    setSerialsData(data.result);

    await setLoading(false);

  };

  useEffect(() => {
    getData();
  },[]);


  function createTextArea(serials: string[], pos: number) {

    const copySerials = [...serials];

    const textAreaCount = Math.ceil(copySerials.length/serialRow);


    let finnaly = <></>;

    for (let i = 1; i <= textAreaCount; i++) {

      const val = copySerials.filter((_, index ) => (index < serialRow * i) && (index >= serialRow * (i-1))).map((serial) => serial)
        .reduce((prev, curr,index, arr) => {
          return prev + curr +( arr.length > index + 1 ? '\n' : '');
        },'');

      finnaly= <>{finnaly}
        <div style={{width: 150, textAlign: 'right', float: 'left', margin: 15}}>
          <Button type={'text'} onClick={() => copyClick(val, `Dane zostały zapisane w schowku.`)} icon={<CopyOutlined />}></Button>
        <TextArea autoSize={{minRows: serialRow, maxRows: serialRow}} style={{width: 150, whiteSpace: 'pre-line',}} value={val} />

        </div>
      </>

    }

    return finnaly;


  }


  if(permError) {
    return <PermError/>
  }

  return <>
    <div className="site-card-border-less-wrapper">


        <Card size={'small'} title={<div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>Zmiany do wprowadzenia</div>
          <div style={{fontSize: 'smaller', justifyContent: 'space-between', display: 'flex'}}><div style={{marginTop: 5}}>Ilość nr w jednym oknie:</div> <InputNumber value={serialRow} onChange={(value) => setSerialRow(value as number)} bordered={false} style={{width: '50px', backgroundColor: 'transparent', fontSize: 'smaller'}}/></div>
        </div>} bordered={false} style={{margin: 20, textAlign: 'left'}}>
        <>
          <CheckDeliveryAlert deliveryNr={linkParams.delivery!} tripNr={linkParams.trip!}/>
          { truckNotLoaded && <Alert style={{margin: 20, fontWeight: 'bold'}} type={'warning'} message={'Uwaga! Załadunek jeszcze nie został zakończony. Jest możliwość wprowadzania zmian za pomocą skanera.'}/> }

          {

            serialsData.map((data) => {

              if(data.serials.length === 0) {
                return <Alert type={'info'} message={<>
                  <Tag color="default">{data.position}</Tag> Usuń pozycję
                </>} />
              }

              if(data.serials.length < data.count) {
                return <Alert type={'info'} style={{marginTop: 10}} message={<>
                  <Tag color="default">{data.position}</Tag> Zmień ilość z <span>{data.count}</span> na <span>{data.serials.length}</span>
                </>} />
              }

              return null;

            })


          }


        </>
        </Card>


      {contextHolder}
      {
          serialsData.map((data) => {


            return (
              <Badge.Ribbon text={data.code} color={'red'} key={data.position} style={{margin: 10}}>

              <Card size={'small'} title={<div style={{display: 'flex', justifyContent: 'space-between'}} >
                <Tag color="default">{data.position}</Tag>
              </div>} bordered={false} style={{margin: 20, textAlign: 'left'}}>

                {createTextArea(data.serials,data.position)}


              </Card>
            </Badge.Ribbon>
            );
          })
      }
    </div>


  </>

};
