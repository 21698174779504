import { createContext } from 'react';
import { UsersInterface } from '../types/users.interface';

interface LoggedInContextType {
  loggedIn: boolean;
  user: UsersInterface | null;
  setLoggedIn: (value: boolean) => void;
}

export const LoggedInContext = createContext<LoggedInContextType | null>(null);
