import React, { useEffect, useState } from 'react';
import { Alert } from 'antd';
import { Link } from 'react-router-dom';
import { fetcher } from '../../../../extras/fetcher';


interface Props {
  deliveryNr: string;
  tripNr: string;
}
export const CheckDeliveryAlert = (props: Props) => {

  const [notExistCode, setNotExistCode] = useState<string[]>([]);

  useEffect(() => {
    const checkDelivery = fetcher(`ikea/trip/${props.tripNr}/${props.deliveryNr}/check`).then((check) => {
      if (check.result.notExistCode.length > 0) {
        setNotExistCode(check.result.notExistCode);
      }
    });
  },[]);


    return <>
      {
        notExistCode.map((code,index) =><Alert key={index} style={{fontWeight: 'bold', margin: 10,}} type={'error'} message={<>KOD <Link to={`/trip/${props.tripNr}/${props.deliveryNr}/collected/${code}`}>{code}</Link> JEST POBRANY, ALE NIE ISTNIEJE W POZYCJACH SAP!</>}/>)
      }
    </>



}
