import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectedMenuContext } from '../../../contexts/selectedMenu.context';
import { PermError } from '../NotFoundPage/PermError';
import { Button, Card, Form, Input, message } from 'antd';

import { SaveOutlined } from '@ant-design/icons';
import { NavCodeInterface } from '../../../types/navCode.interface';
import { fetcher } from '../../../extras/fetcher';
import { useForm } from 'antd/es/form/Form';
import { myDateFormat } from '../../../extras/myDateFormat';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}

export const InboundOneCode = (props: Props) => {

  const [permError, setPermError] = useState<boolean>(false);
  const linkParams = useParams();
  const selectedMenuContext = useContext(SelectedMenuContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = useForm<NavCodeInterface>();
  const [apiData, setApiData] = useState<NavCodeInterface>();


  const getData = async () => {
    const data = await fetcher(`inbound/code/one-code/${linkParams.code}`);

    if(data.status === 403) {
      setPermError(true);
      return;
    }


    setApiData(data.result);



  }

  useEffect(() => {
    apiData?.id && form.setFieldsValue(apiData);
  },[apiData]);

  useEffect(() => {

    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Inbound',
      label: 'Przyjęcie',
    },

      {
        href: '/inbound/code/',
        key: 'inbound-code',
        label: 'Baza Kodów',
      },

      {
        href: `/inbound/code/${linkParams.code}`,
        key: 'inbound-code1',
        label: linkParams.code,
      },

    ];


    props.handleSetMainTitle( `Inbound - ${linkParams.code}`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['inbound-code']);

    getData();
  }, [linkParams.code]);

  if(permError) {
    return <PermError/>
  }

  const submit = async () => {

    const {code, ...data} = form.getFieldsValue();

    const sendData = await fetcher(`inbound/code/one-code/${linkParams.code}`,'PATCH', {...data, ean: Number(data.ean)});

    if(sendData.status === 403) {
      messageApi.error('Brak wymaganych uprawnień!');
      return;
    }


    if(sendData.result.success) {
      messageApi.success('Zmiany zostały wprowadzone!');
      getData();
    } else if(sendData.result.error) {
      messageApi.error(sendData.result.error);
    } else {
      messageApi.error('Nieznany błąd!');
    }

  }


  return <Card style={{justifyContent: 'flex-start', display: 'flex', width: '100%', flexDirection: 'column'}} size={'small'} headStyle={{ justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', width: '100%', textAlign: 'left'}} title={`${linkParams.code} - Informacje o kodzie`}>

    <Form
      form={form}
      onSubmitCapture={submit}
      labelCol={{span: 8}}
      wrapperCol={{span: 20}}
      layout="horizontal"
      initialValues={{size: 'small'}}
      size={'small'}
      style={{maxWidth: 650, width: 600}}
    >

      <Form.Item name={'id'} style={{display: 'none'}}>
      <Input type={'hidden'} />
      </Form.Item>
      <Form.Item label="Kod" name="code">
        <Input readOnly/>
      </Form.Item>

      <Form.Item name="desc" label="Opis">
        <Input/>
      </Form.Item>

      <Form.Item name="ean" label="EAN">
        <Input pattern = '^[0-9]+$'/>
      </Form.Item>

      <Form.Item label="Utworzony przez">
        {apiData?.createdByUser?.firstname} {apiData?.createdByUser?.lastname}
      </Form.Item>

      <Form.Item label={'Data utworzenia'}>
        {myDateFormat(apiData?.createdAt)}
      </Form.Item>

      <Form.Item label={'Ostatnia modyfikacja'}>
        {myDateFormat(apiData?.updatedAt)}
      </Form.Item>

      <Form.Item label="" style={{justifyContent: 'flex-end', display: 'flex'}}>
        <Button htmlType={'submit'} icon={<SaveOutlined />}>Zapisz</Button>
      </Form.Item>
    </Form>
    {contextHolder}
  </Card>;

}
