import React, { useContext, useEffect, useState } from 'react';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { Button, Checkbox, Table, Tag } from 'antd';
import { TableHeader } from '../Stan/TableHeader';
import { TableFooter } from '../Stan/TableFooter';
import { ColumnsType, TableProps } from 'antd/es/table';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { Link } from 'react-router-dom';
import { myDateSorter, myNumberSorter } from '../../../../extras/sorters';
import { fetcher } from '../../../../extras/fetcher';
import { myDateFormat } from '../../../../extras/myDateFormat';
import { PlusOutlined } from '@ant-design/icons';
import { ModalTripStatus } from './ModalTripStatus';
import { ModalAddTrip } from './ModalAddTrip';
import { exportToExcel } from '../../../../extras/exportToExcel';
import { DeleteTrip } from './DeleteTrip';
import { PermError } from '../../NotFoundPage/PermError';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';
import { checkRule } from '../../../../extras/checkRule';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const Trip = (props: Props) => {
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [notOld, setNotOld] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);

  const [modalStatusOpened, setModalStatusOpened] = useState<boolean>(false);
  const [currentTripNr, setCurrentTripNr] = useState<number | null>(null);


  const [modalAddTripOpened, setModalAddTripOpened] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const { windowHeight } = useWindowDimensions();

  const [permError, setPermError] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);

  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[]=[{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Wysylka',
        label: 'Wysyłka',
      },

      {
        href: '/trip/',
        key: 'Tripy',
        label: 'Tripy',
      },
    ];


    props.handleSetMainTitle('Lista Tripów');
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['tripy']);

  },[]);


  interface DataType {
    id: number;
    nr: number;
    createdAt: Date;
    createdAt2: Date;
    sentAt: Date;
    sentAt2: Date;
    readyAt2: Date;
    readyAt: Date;
    sapAt2: Date;
    sapAt: Date;
    loadedAt2: Date;
    loadedAt: Date;
    status: number;
    ikeaDelivery: [];
    percent: number;
    collectedPlt: number;
    totalPlt: number;
  }


  const columns: ColumnsType<DataType> = [
    {
      title: 'Trip',
      width: 120,
      dataIndex: 'nr',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(tableData.map((arr) => arr.nr))).map((trip) => {
          return {
            text: trip,
            value: trip,
          }
        }),
      ],

      onFilter: (value, record) => record.nr.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => Number(a.nr) - Number(b.nr),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record, index) => <Link to={`${value}/`}>{value}</Link>,

    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.status,b.status),

      render: (value, record) => {
        const img = record.sentAt
          ? <Tag color="success">Wysłany</Tag>
          : record.sapAt ? <Tag color="gold">Pobrany w SAP</Tag>
            : record.loadedAt ? <Tag color="purple">Załadowany</Tag>
              : record.readyAt ? <Tag color="cyan">Przygotowany</Tag>
                : record.collectedPlt > 0 && record.collectedPlt !== record.totalPlt ? <Tag color="cyan">Przygotowany w {record.percent}%</Tag> : record.collectedPlt === 0 ? <Tag color="red">Zaplanowany</Tag> : <Tag color="cyan">Przygotowany</Tag>

        return <Button onClick={() => {setCurrentTripNr(record.nr); setModalStatusOpened(true) }} type={'text'}>{img}</Button>
      }

    },

    {
      width: 180,
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myDateSorter(a.createdAt2, b.createdAt2),

    },

    {
      width: 180,
      title: 'Data załadunku',
      dataIndex: 'loadedAt',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myDateSorter(a.loadedAt2,b.loadedAt2),

    },

    {
      width: 180,
      title: 'Pobrany w sap',
      dataIndex: 'sapAt',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myDateSorter(a.sapAt2,b.sapAt2),

    },

    {
      width: 180,
      title: 'Data wysłania',
      dataIndex: 'sentAt',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myDateSorter(a.sentAt2,b.sentAt2),

    },

    {
      width: 50,
      title: '',
      align: 'center',
      filterMode: 'tree',
      render: (value, record) =>  <DeleteTrip disabled={!checkRule('a2',loggedIn?.user?.rule!)} tripNr={Number(record.nr)} getData={getData} />

    },

  ];


  const getData = async () => {
    await setLoading(true);

    const data = notOld ? await fetcher('ikea/trip') : await fetcher('ikea/trip/all');

    if(data.status === 403) {
      setPermError(true);
      return;
    }

    const myData = await data.result.map((record: any) => {

      return {
        ...record,
        createdAt: myDateFormat(record.createdAt),
        createdAt2: record.createdAt,
        sentAt2: record.sentAt,
        sentAt: myDateFormat(record.sentAt),
        readyAt: myDateFormat(record.readyAt),
        readyAt2: record.readyAt,
        loadedAt: myDateFormat(record.loadedAt),
        loadedAt2: record.loadedAt,
        sapAt: myDateFormat(record.sapAt),
        sapAt2: record.sapAt,
      }
    });



    setTableData(myData);
    setTableSelected([]);
    setSelectedRowKeys([]);

    await setLoading(false);

  }

  useEffect(() => {
    getData();
  },[notOld]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  const handleExcelClick = (data: DataType[]) => {



    const dataToExport = data.map((data: DataType,index) => {

      return {
        id: index+1,
        Trip: data.nr,
        Status: data.sentAt
          ? 'Wysłany'
          : data.sapAt ? 'Pobrany w SAP'
            : data.loadedAt ? 'Załadowany'
              : data.readyAt ? 'Przygotowany'
                : data.collectedPlt > 0 && data.collectedPlt !== data.totalPlt ? `Przygotowany w ${data.percent}%` : 'Zaplanowany',
        'Data utworzenia': data.createdAt,
        'Data załadunku': data.loadedAt,
        'Pobrany w sap': data.sapAt,
        'Data wysłania': data.sentAt,
      }
    })
    exportToExcel(dataToExport);

  }


  const handleSetModalStatusOpened = (open: boolean) => {
    setModalStatusOpened(open);
  }

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    /// console.log('params', pagination, filters, sorter, extra);
  };

  const CheckBoxNotOld: JSX.Element = <Checkbox checked={notOld} onChange={(e) => setNotOld(e.target.checked)}>Ukryj nieaktualne</Checkbox>

  const ButtonAddTrip: JSX.Element =  <Button disabled={!checkRule('a2',loggedIn?.user?.rule!)} onClick={() => setModalAddTripOpened(true)} size={'small'} icon={<PlusOutlined />}>Dodaj</Button>//<Button type={'text'}><IoAddCircleOutline  style={{width: '16px', height: '16px', opacity: 1, color: '#52c41a'}} /></Button>

  if(permError) {
    return <PermError/>
  }

  return  <>
    <Table
    rowKey={'id'}
    rowSelection={{
      selectedRowKeys,
      type: 'checkbox',
      ...rowSelection,
    }}

    title={(records) => <TableHeader getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} additional={<>{CheckBoxNotOld} {ButtonAddTrip}</>} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} onChange={onChange} style={{opacity: 0.9}} size={'small'} />

    { currentTripNr && <ModalTripStatus open={modalStatusOpened} tripNr={currentTripNr} handleSetModalOpened={handleSetModalStatusOpened} handleGetData={getData} /> }

    <ModalAddTrip handleGetData={getData} handleSetModalOpened={setModalAddTripOpened} open={modalAddTripOpened}/>

  </>
}
