import React, { useState } from 'react';
import { Input, message, Modal } from 'antd';
import { fetcher } from '../../../extras/fetcher';
import { AddCodeInboundInterface } from '../../../types/addCodeInbound.interface';

interface Props {
  open : boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}

export const ModalAddCode = (props: Props) => {


  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [code, setCode] = useState<AddCodeInboundInterface>();


  const handleCancel = () => {
    setCode(undefined);
    props.handleSetModalOpened(false);
  }


  const handleOk = async () => {


    setMoveLoading(true);


    if (!code) {
      messageApi.error('Nie wpisano danych!');
      setMoveLoading(false);
      return;
    }

    if(code.code === '') {
      messageApi.error('Nie wpisano Kodu!');
      setMoveLoading(false);
      return;
    }

    if(code.desc === '') {
      messageApi.error('Nie wpisano opisu!');
      setMoveLoading(false);
      return;
    }

    if(code.ean === 0) {
      messageApi.error('Wpisz EAN!');
      setMoveLoading(false);
      return;
    }

    const reg = new RegExp('[0-9]+');

    if (!reg.test(String(code.ean))) {
      messageApi.error('Wpisany EAN nie jest liczbą!');
      setMoveLoading(false);
      return;
    }


    const addCode = await fetcher(`inbound/code/`,'POST', {...code, code: code.code.toUpperCase(), ean: Number(code.ean)});

    if(addCode.result.error) {
      messageApi.error(addCode.result.error);
      setMoveLoading(false);
      return;
    } else if(addCode.result.success) {

      messageApi.success('Gratulacje! Kod został pomyślnie dodany do systemu.');
      props.handleSetModalOpened(false);
      props.handleGetData();
    } else {
      messageApi.error('Wystąpił nieznany błąd!');
    }

    setMoveLoading(false);

  }

  const handleSetCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode((x: any) => {

      const incl = x ? x : {
        code: '',
        desc: '',
        ean: '',
      };

      return {
        ...incl,
        [e.target.id]: e.target.value,
      }
    })
  }

  return (

    <Modal destroyOnClose={true} bodyStyle={{padding: 10,}} title={'Dodawanie Kodu'} open={props.open} onOk={handleOk} onCancel={handleCancel} confirmLoading={moveLoading}>
      <>
        <h4>Kod</h4>
        <Input id={'code'} value={code ? code.code : ''} onPressEnter={handleOk} onChange={handleSetCode} style={{width: '100%',}} placeholder={'Kod'}/>
        <br />
        <h4>Opis</h4>
        <Input value={code ? code.desc : ''} id={'desc'} onPressEnter={handleOk} onChange={handleSetCode} style={{width: '100%',}} placeholder={'Opis'}/>
        <br />
        <h4>EAN</h4>
        <Input id={'ean'} datatype={'number'} data-number={'1'} value={code ? code.ean : ''} onPressEnter={handleOk} onChange={handleSetCode} placeholder={'EAN'}/>
        <br />
        <br />


        {contextHolder}
      </>
    </Modal>
  );

}
