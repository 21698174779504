import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';
import React, { useContext, useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { SelectedMenuContext } from '../../../contexts/selectedMenu.context';
import { LoggedInContext } from '../../../contexts/loggedIn.context';
import { ColumnsType } from 'antd/es/table';
import { myDateSorter, myNumberSorter } from '../../../extras/sorters';
import { PermError } from '../NotFoundPage/PermError';
import { Image, Input, Table } from 'antd';
import { TableHeader } from '../Ikea/Stan/TableHeader';
import { PhotosDatePicker } from './PhotosDatePicker';
import { fetcher } from '../../../extras/fetcher';
import { PhotoInterface } from '../../../types/photo.interface';
import { PhotoTripInterface } from '../../../types/photoTrip.interface';
import { myDateFormat } from '../../../extras/myDateFormat';
import { API_URL } from '../../../config';
import { FileZipOutlined } from '@ant-design/icons/';
import { FilterDropdownProps } from 'antd/es/table/interface';


interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const Photos = (props: Props) => {
  const [tableData, setTableData] = useState<PhotoTripInterface[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<string>('');

  const selectedMenuContext = useContext(SelectedMenuContext);
  const { windowHeight } = useWindowDimensions();

  const [permError, setPermError] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);

  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[]=[
      {
        href: 'nav/list/',
        key: 'photos_1',
        label: 'Zdjęcia',
      },
    ];


    props.handleSetMainTitle('Zdjęcia');
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['photos_1']);

  },[]);

  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm} : FilterDropdownProps) => {

    return <div><Input
      allowClear
      value={selectedKeys[0]}
      onChange={(e) => {setSelectedKeys(e.target.value ? [e.target.value] : []);

        confirm({
          closeDropdown: false,
        });

      }}
      onPressEnter={() => confirm()}
    ></Input></div>

  }


  const columns: ColumnsType<PhotoTripInterface> = [
    {
      title: 'Podgląd',
      width: 470,
      dataIndex: 'thumbnails',
      filterMode: 'tree',
      align: 'left',

      render: (value, record, index) =>  <Image.PreviewGroup>

        {record.photos.map((photo,ix) =>  <Image
          style={{padding: 5, display: ix < 3 ? 'block' : 'none'}}
          width={ix < 3 ? 150 : 0}
          src={`${API_URL}photo/file/${photo.id}`}
        />)}

      </Image.PreviewGroup>

    },
    {
      title: 'Trip',
      dataIndex: 'trip',
      filterMode: 'tree',
      width: 150,
      onFilter: (value, record) => String(record.trip).includes(String(value)),
      filterDropdown,
      sorter: (a,b) => myNumberSorter(a.trip,b.trip),

    },

    {
      title: 'Ilość Zdjęć',
      dataIndex: 'count',
      filterMode: 'tree',
      width: 125,
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.photos.length,b.photos.length),
      render: (value, record, index) => record.photos.length,
    },

    {
      width: 250,
      title: 'Data',
      dataIndex: 'createdAt',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myDateSorter(a.date, b.date),

      render: (value, record) => myDateFormat(record.date),

    },

    {
      title: 'Pobierz',
      dataIndex: 'download',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => {

       return <>{record.photos.map((photo, index) => {

          return <span style={{letterSpacing: 15}}><a href={`${API_URL}photo/file/download/${photo.id}`} download={`${index+1}.jpg`}>{index+1}</a></span>;
        })}

         <a href={`${API_URL}photo/file/download-zip/${record.trip}/`}><FileZipOutlined /></a>
       </>

      }

    },

  ];


  const getData = async (dateString?: string) => {
    await setLoading(true);

    const data = await fetcher(`photo/list/${dateString ? dateString : ''}`);

    if(data.status === 403) {
      setPermError(true);
      return;
    }


    if(data.result.length === 0) {
      setTableData([]);
    } else {
      console.log(data.result);
      const uniqueTrip: number[] = Array.from(new Set(data.result.map((record: PhotoInterface) => record.trip)));


      const myData: PhotoTripInterface[] = uniqueTrip.map((trip: number): PhotoTripInterface => {

        const filtered = data.result.filter((record: PhotoInterface) => record.trip === trip);

        return {
          trip,
          date: filtered[0].createdAt,
          photos: filtered,
        }

      });
      setTableData(myData);
    }


  setLoading(false);

  }

  useEffect(() => {
    getData();
  },[]);


  if(permError) {
    return <PermError/>
  }

  return  <>
    <Table
      rowKey={'id'}
      title={(records) => <TableHeader getData={() => getData(currentDate)} additional={<><PhotosDatePicker handleSetDate={setCurrentDate} getData={getData} /></>} selectedCount={0}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />

{/*    { currentTripNr && <ModalTripStatus open={modalStatusOpened} tripNr={currentTripNr} handleSetModalOpened={handleSetModalStatusOpened} handleGetData={getData} /> }*/}


  </>
}
