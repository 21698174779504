import React, { useEffect, useState } from 'react';
import { Button, DatePicker, message, Modal } from 'antd';
import status2 from './imgStatus/2.gif';
import dayjs, { Dayjs } from 'dayjs';
import { fetcher } from '../../../../extras/fetcher';
import { IkeaTripInterface } from '../../../../types/ikeaTrip.interface';

interface Props {
  open : boolean;
  tripNr: number;
  deliveryNr: number;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}
export const ModalDeliveryStatus = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(false);

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [sapValue, setSapValue] = useState<Dayjs | null>(null);

  const [messageApi, contextHolder] = message.useMessage();


  const handleOk = async () => {
    setMoveLoading(true);

    ///update
    const updateData = await fetcher(`ikea/trip/${props.tripNr}/${props.deliveryNr}/status`,'PATCH',{
      sapAt: sapValue ? sapValue.toDate() : null,
    });

   // console.log(updateData);

    if(updateData.status === 403) {

      messageApi.open({
        type: 'error',
        content: `Nie posiadasz wymaganych uprawnień!`,
      });
      setMoveLoading(false);
      return;
    }


    if(updateData.status === 200) {
      messageApi.open({
        type: 'success',
        content: `Status dla Delivery ${props.deliveryNr} zaktualizowany.`,
      });

      props.handleSetModalOpened(false);

    } else {
      messageApi.open({
        type: 'error',
        content: `Nieznany błąd..`,
      });
    }



      setMoveLoading(false);


    props.handleGetData();
  }

  const handleCancel = () => {
    props.handleSetModalOpened(false);
  }

  const handleClickSap = () => {
    setSapValue(dayjs());
  }


  useEffect(() => {

    (async () => {
      setLoading(true);
      setSapValue(null);
      const deliveryData = await fetcher(`ikea/trip/${props.tripNr}/${props.deliveryNr}`);

      if(deliveryData.status === 200) {
        const delivery: IkeaTripInterface = deliveryData.result;
        setSapValue(delivery.sapAt && dayjs(delivery.sapAt));
      } else {
        // nieznany błąd
      }

        setLoading(false);
    })();

  },[props.deliveryNr]);




  return     <Modal bodyStyle={{padding: 10,}} title={`${props.tripNr} - Zmiana Statusu`} open={props.open} onOk={handleOk} onCancel={handleCancel} confirmLoading={moveLoading}>
    <>

      <div style={{ display: 'flex', alignItems: 'center', alignContent: 'flex-end', justifyContent: 'space-evenly', padding: 5 }}>
        <div style={{width: 150, display: 'block'}}>Pobrany w SAP:</div>
        <Button onClick={handleClickSap} type={'text'}><img alt={'Pobrany w sap'} src={status2} /></Button>
        <div style={{width: 200 , display: 'flex'}}><DatePicker value={sapValue} onChange={(value) => setSapValue(value) } allowClear showTime={true} /></div>
      </div>

      {contextHolder}
    </>
  </Modal>

}
