import React, { useContext, useEffect, useState } from 'react';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { Link, useParams } from 'react-router-dom';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { ColumnsType } from 'antd/es/table';
import { myStringSorter } from '../../../../extras/sorters';
import { Table } from 'antd';
import { TableFooter } from '../../Ikea/Stan/TableFooter';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { fetcher } from '../../../../extras/fetcher';
import { TableHeader } from './TableHeader';
import { ModalAddUserTerm } from './ModalAddUserTerm';
import { PermError } from '../../NotFoundPage/PermError';


interface Props {
  handleSetMainTitle: (title: string) => void;
  setBreadcrumbHandler: (items: MainBreadcrumbItem[] | null) => void;
}
export const UsersTerm = (props: Props) => {

  const [tableData, setTableData] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [permError, setPermError] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const linkParams = useParams();



  const {windowHeight} = useWindowDimensions();

  interface DataType {
    id: number;
    name: string;
    rule: string;

  }

  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Admin',
      label: 'Administracja',
    },
      {
        href: '',
        key: 'users',
        label: 'Użytkownicy',
      },

      {
        href: `/admin/users-term/`,
        key: 'usersTerm',
        label: 'Terminal',
      },
    ];


    props.handleSetMainTitle(`Użytkownicy - Terminal`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['users2']);

  }, []);


  const columns: ColumnsType<DataType> = [
    {
      title: 'Użytkownik',
      width: 500,
      dataIndex: 'name',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(tableData.map((arr) => arr.name))).map((name) => {
          return {
            text: name,
            value: name,
          }
        }),
      ],

      onFilter: (value, record) => record.name.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myStringSorter(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record, index) => <Link to={`/admin/users-term/${record.id}`}>{record.name}</Link>,

    },
    {
      title: 'Organizacja',
      dataIndex: 'organization',
      filterMode: 'tree',
      width: 250,

    },

  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  const getData = async () => {
    await setLoading(true);

    const data = await fetcher(`admin/users-term/`);


    if(data.status === 403) {
      setPermError(true);
      return;
    }

    const myData = data.result;

    setTableData(myData);
    setTableSelected([]);
    setSelectedRowKeys([]);

    await setLoading(false);

  };

  useEffect(() => {
    getData();
  },[]);

  if(permError) {
    return <PermError/>
  }

  return     <><Table
    rowKey={'id'}
    rowSelection={{
      selectedRowKeys,
      type: 'checkbox',
      ...rowSelection,
    }}

    title={(records) => <TableHeader handleAddUserClick={() => setShowAddUserModal(true)} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />

    <ModalAddUserTerm open={showAddUserModal} handleSetModalOpened={setShowAddUserModal} handleGetData={getData}/>

    </>
}
