import React, { useEffect, useState } from 'react';
import { fetcher } from '../../../../extras/fetcher';
import { Spin } from 'antd';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface Props {
  tripNr: number;
  deliveryNr: number;
}

export const DeliveryPrinted = ({deliveryNr, tripNr}: Props) => {

  const [loading, setLoading] = useState(true);
  const [printed, setPrinted] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetcher(`ikea/trip/${tripNr}/${deliveryNr}/print-status`);

      if (response.status === 200) {
        setPrinted(response.result.printed);

        if(response.result.printed === false) {
          setTimeout(() => getData(), 15000);
        }

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
      getData();
  },[deliveryNr, tripNr]);

  if(loading) {
    return <Spin indicator={<LoadingOutlined spin style={{color: '#FF000633'}} />} />
  }


  return printed ? <CheckOutlined style={{color: '#52c41a'}} /> : <CloseOutlined style={{color: '#f5222d'}}  />


}
