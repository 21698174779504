import React from 'react';
import { Breadcrumb, Dropdown } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';


interface Props {
  breadcrumbItems?: MainBreadcrumbItem[];
}


export type MainBreadcrumbItem = {
  label: string | any,
  key: string,
  href: string,
  menu?: {href: string, label: string}[]
}

export const MainBreadcrumb = (props: Props) => {

  const navigate = useNavigate();


  const navi = (e: any, href: string) => {
    e.preventDefault();
    if(href !=='') {
      navigate(href);
    }

  }

  if(!props.breadcrumbItems) {
    return null;
  }



  return (
    <Breadcrumb style={{marginLeft: 25 }} className={'no-printMe'}>
      <Breadcrumb.Item href={'#'} onClick={(e) => navi(e,'/')}>
        <HomeOutlined />
      </Breadcrumb.Item>
      {
        props.breadcrumbItems.map((item) =>{
           return  <Breadcrumb.Item  key={item.key} children={item.menu ?

             <Dropdown menu={{items: [
               ...item.menu.map((m,index) => {
                 return {
                   key: item.key+index,
                   label: <a onClick={(e) => navi(e,m.href)}>{m.label}</a>,
                 }
               }),
               ]}}>
               <a>{item.label}</a>
             </Dropdown>


             : item.label} onClick={!item.menu ? ((e) => navi(e,item.href)) : undefined}/>
        }
          )
      }
    </Breadcrumb>
  )
}


