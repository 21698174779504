import React from 'react';
import { Descriptions } from 'antd';
import { myDateFormat } from '../../../../extras/myDateFormat';
import { Link } from 'react-router-dom';
import { IkeaDaneInterface } from '../../../../types/ikeaDane.interface';


interface Props {
  ikeaDane : IkeaDaneInterface;
  delivery: any | null;
}

export const AdditionalDescription = (props: Props) => {

  return <Descriptions title="" bordered layout={'vertical'} column={2} size={'small'}>
    <Descriptions.Item label="Kod">{props.ikeaDane.ikeaCode.kod_elica}</Descriptions.Item>
    <Descriptions.Item label="Kod Klienta">{props.ikeaDane.ikeaCode.kod_ikea}</Descriptions.Item>
    <Descriptions.Item label="UDC">{props.ikeaDane.udc}</Descriptions.Item>
    <Descriptions.Item label="Numer Tygodnia">{props.ikeaDane.date_stamp}</Descriptions.Item>
    <Descriptions.Item label="Data Rejestracji">{myDateFormat(props.ikeaDane.date)}</Descriptions.Item>
    <Descriptions.Item label="Data Magazynowania">{myDateFormat(props.ikeaDane.mag_date)}</Descriptions.Item>
    <Descriptions.Item label="Aktualna Lokalizacja"><span style={{textDecoration: props.delivery ? 'line-through' : ''}}>{props.ikeaDane.ikeaMag.name}</span></Descriptions.Item>
    <Descriptions.Item label="Wysyłka">{props.delivery ? <><Link to={`/trip/${props.delivery.ikeaTrip.nr}/${props.delivery.nr}`}>{props.delivery.nr}</Link> <Link to={`/trip/${props.delivery.ikeaTrip.nr}`}>({props.delivery.ikeaTrip.nr})</Link></> : ''} </Descriptions.Item>
    <Descriptions.Item label="Nr seryjne">{props.ikeaDane.serial.split(':').map((s,ix) => ix > 0 ?<>{s}<br/></> : null)}</Descriptions.Item>
  </Descriptions>
}
