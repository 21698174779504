import { Alert } from 'antd';
import { NavListInterface } from '../../../../types/navList.interface';

interface Props {
  status: number;
  creditedByUser: NavListInterface['creditedByUser'] | null;
}
export const NavStatusInfo = (props: Props) => {

  if (!props.creditedByUser) {
    return null;
  }

  if (props.status !== 2) {
    return null;
  }

  return <div style={{display: 'flex', justifyContent: 'center', padding: 20}}>
    <Alert showIcon type={'warning'} style={{width: '65%'}} description={`Wysyłka jest w trakcie księgowania przez: ${props.creditedByUser.firstname} ${props.creditedByUser.lastname}`} message={`W trakcie księgowania...`}/>
  </div>

}
