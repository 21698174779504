import React, { useEffect, useState } from 'react';
import { message, Modal, Radio, RadioChangeEvent, Space, Spin } from 'antd';
import { PermError } from '../NotFoundPage/PermError';
import { fetcher } from '../../../extras/fetcher';
import { PersonEmployeeInterface } from '../../../types/personEmployee.interface';
import { PersonAgreementInterface } from '../../../types/personAgreement.interface';


interface Props {
  open: boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;

  employeeId: string;
}

export const ModalEditEmployeeAgreement = (props: Props) => {

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [permError, setPermError] = useState<boolean>(false);
  const [allAgreements, setAllAgreements] = useState<PersonAgreementInterface[]>([])
  const [agreementSelected, setAgreementSelected] = useState<string>();


  const getData = async () => {

    const agreement = await fetcher(`person/all-agreements`);

    setAllAgreements(agreement.result);

    await fetcher(`person/employees/${props.employeeId}`)
      .then(res => {
        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }


        if (res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }


        setAgreementSelected((res.result as PersonEmployeeInterface).personAgreement.id);

      })

  }

  useEffect(() => {
    setLoading(true);
    getData().then(r => setLoading(false));
  }, [props.employeeId]);


  const handleCancel = async () => {
    props.handleSetModalOpened(false);
  };


  const handleOk = async () => {
    const data = await fetcher(`person/employees/${props.employeeId}/agreement`, 'PUT', {employeeId: props.employeeId, agreementId: agreementSelected});

    if (data.status === 403) {
      messageApi.error('Brak wymaganych uprawnień!');
    }

    if(data.result.error) {
      messageApi.error(data.result.error);
      return;
    }

    if(data.result.success) {
      messageApi.success('Umowa została zmieniona!');
      props.handleSetModalOpened(false);
      props.handleGetData();
    } else {
      messageApi.error('Nieznany błąd!');
    }

  }

  if(permError) {
    return <PermError/>
  }

  const onChange = (e: RadioChangeEvent) => {
    setAgreementSelected(e.target.value);
  };

  return (
    <Modal bodyStyle={{padding: 10}} title={`Zmiana Umowy`} open={props.open} onOk={handleOk}
           onCancel={handleCancel} confirmLoading={moveLoading}>
      {
        loading ? <div style={{textAlign: 'center'}}>
          <Spin size="large" />

      </div> : <>
      {permError ? <PermError/> : <>
        <h4>Oddział</h4>
        <Radio.Group onChange={onChange} value={agreementSelected}>
          <Space direction="vertical">
            {
              allAgreements.map((agreement) => <Radio key={agreement.id} value={agreement.id}>{agreement.name}</Radio>)
            }
          </Space>
        </Radio.Group>
        {contextHolder}
      </>}

</>
     }
    </Modal>

  );

};
