import React, { useState } from 'react';
import { Input, message } from 'antd';
import { fetcher } from '../../extras/fetcher';
import { useNavigate } from 'react-router-dom';

interface Props {

}

export const SearchBox = (props: Props) => {

  const [value, setValue] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();

  const navi = useNavigate();


  const findTrip = async (tripNr: string): Promise<void> => {

    const trip = await fetcher(`ikea/trip/${tripNr}/exists`);

    if(trip.result.success) {
      navi(`/trip/${tripNr}`);

    } else {
      messageApi.open({
        type: 'error',
        content: `Trip ${tripNr} nie istnieje!`,
      });
    }

  }


  const findDelivery = async (deliveryNr: string) : Promise<void> => {

    const delivery = await fetcher(`ikea/trip/delivery/${deliveryNr}`);

    if (delivery.result.error) {
      messageApi.open({
        type: 'error',
        content: delivery.result.error,
      });
    } else {
      navi(`/trip/${delivery.result.ikeaTrip.nr}/${deliveryNr}`);
    }

  }

  const findIkeaCode = async (ikeaCode: string) : Promise<void> => {

    const res = await fetcher(`ikea/code/i/ikea/${ikeaCode}`);

    if (res.result.error) {
      messageApi.open({
        type: 'error',
        content: res.result.error,
      });
    } else {
      navi(`/stock/material/${res.result.kod_elica}`);
    }

  }

  const findCode = async (code: string) : Promise<void> => {

    const res = await fetcher(`ikea/code/i/${code}`);

    if (res.result.error) {
      messageApi.open({
        type: 'error',
        content: res.result.error,
      });
    } else {
      navi(`/stock/material/${res.result.kod_elica}`);
    }

  }


  const findUDC = async (udc: string) => {
    const res = await fetcher(`ikea/history/udc-exists/${udc}`);

    if (res.result.error) {
      messageApi.open({
        type: 'error',
        content: res.result.error,
      });
    } else {

      if(res.result.success) {
        navi(`/history/udc/${udc}`);
      } else {
        messageApi.warning(`UDC ${udc} nie zostało znalezione w bazie.`);
      }


    }
  }


  const findRack = async (rack: string) : Promise<void> => {

      const res = await fetcher(`ikea/rack/name/${rack}`);

    if (res.result.error) {
      messageApi.open({
        type: 'error',
        content: res.result.error,
      });
    } else {
      navi(`/stock/rack/${rack}`);
    }

  }



  const onSearch = async () => {

    const val = value.toUpperCase();

    setValue('');
    const tripReg = new RegExp("^7[0-9]{5}$");
    // Sprawdzanie tripa
    if(tripReg.test(val)) {
      return findTrip(val);
    }

    const deliveryReg = new RegExp("^3[0-9]{9}$");

    if (deliveryReg.test(val)) {
      return findDelivery(val);
    }

    const ikeaCodeReg = new RegExp("^[0-9]{3}.[0-9]{3}.[0-9]{2}$");

    if (ikeaCodeReg.test(val)) {
      return findIkeaCode(val);
    }

    const codeReg = new RegExp("^PRF[0-9]{2,8}[A-Z]{0,2}?");

    if (codeReg.test(val)) {
      return findCode(val);
    }


    const regReg = new RegExp("^0100[A-Z][0-9]{4}[1-4]$");
    const regReg1 = new RegExp("[A-Z][1-9][0-9]?");

    if(regReg.test(val) || regReg1.test(val)) {
      return findRack(val);
    }

    const udcReg = new RegExp("^347[0-9]{6}$");

    if(udcReg.test(val)) {
      return findUDC(val);
    }


    return messageApi.open({
      type: 'error',
      content: `Wartość "${val}" nie jest kodem, udc, tripem, delivery ani lokalizacją.`,
    });



  };

  return <>
    {contextHolder}
    <Input placeholder={'Szukaj'} style={{background: 'transparent', color: 'black'}} bordered={false} value={value} onChange={(e) => setValue(e.target.value)} onPressEnter={onSearch} />
    </>
}
