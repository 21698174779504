import React, { useContext, useEffect, useRef, useState } from 'react';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { useParams } from 'react-router-dom';
import { Button, Card, Checkbox, Col, Input, message, Popconfirm, Row, Select } from 'antd';
import { TerminalUsersPermInterface } from '../../../../types/terminalUsersPerm.interface';
import { fetcher } from '../../../../extras/fetcher';
import { TfiSave } from 'react-icons/tfi';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { UsersInterface } from '../../../../types/users.interface';
import { MailOutlined } from '@ant-design/icons';
import { PermError } from '../../NotFoundPage/PermError';

interface Props {
  handleSetMainTitle: (title: string) => void;
  setBreadcrumbHandler: (items: MainBreadcrumbItem[] | null) => void;
}
export const OneUser = (props: Props) => {

  const selectedMenuContext = useContext(SelectedMenuContext);
  const [loading, setLoading] = useState<boolean>(false);
  const linkParams = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [listPerm, setListPerm] = useState<TerminalUsersPermInterface[] | null>(null);

  const [tempFirstname, setTempFirstname] = useState<string | null>(null);
  const [tempLastname, setTempLastname] = useState<string | null>(null);
  const [permError,setPermError] = useState<boolean>(false);


  let componentRef = useRef();

  const [userData, setUserData] = useState<UsersInterface>();

  const getData = async () => {
    const getPerm = await fetcher('admin/users/list-perm');
    setListPerm(getPerm.result);
    const userF = await fetcher(`admin/users/${linkParams.userId}`);

    if(userF.status === 403) {
      setPermError(true);
    }

    if(userF.result.error) {
      return messageApi.error(userF.result.error)
    }

    setUserData(userF.result);

  }


  useEffect(() => {
    getData();
  },[]);

  useEffect(() => {

    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Admin',
      label: 'Administracja',
    },
      {
        href: '',
        key: 'users',
        label: 'Użytkownicy',
      },

      {
        href: `/admin/users/`,
        key: 'usersPanel',
        label: 'Panel',
      },
      {
        href: ``,
        key: 'usersName',
        label: userData ? `${userData.firstname} ${userData.lastname}` : '',
      },
    ];


    props.handleSetMainTitle(`Użytkownicy - Panel`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['users1']);

  }, [userData]);

  const onChangeUserBlocked = (x : CheckboxChangeEvent) => {

    setUserData((u) => {

      if(!u) {
        return;
      }

      return {
        ...u,
        blocked: x.target.checked,
      }

    });
  }

  const onFirstnameChange = (e: any) => {
    setTempFirstname(e.target.value);
  }

  const onLastnameChange = (e: any) => {
    setTempLastname(e.target.value);
  }

  const saveUser = async () => {

    const dataBody = {
      ...userData,
      firstname: tempFirstname ? tempFirstname : userData?.firstname,
      lastname: tempLastname ? tempLastname : userData?.lastname,
    };
    setTempFirstname(null);
    setTempLastname(null);

    const newUserData = await fetcher(`admin/users/${linkParams.userId}`, 'PATCH', dataBody);

    if(newUserData.result.error) {
      messageApi.error(newUserData.result.error);
    } else {
      setUserData(newUserData.result);
      messageApi.success('Dane zostały prawidłowo zapisane!');
    }

  }

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {


    if(!e.target.checked) {
      setUserData((o) => {
        if(!o) {
          return;
        }
        return {
          ...o,
          rule: o.rule!.replace(e.target.value,''),
        }
      });
    } else {

      setUserData((o) => {

        if(!o) {
          return;
        }

        return {
          ...o,
          rule: `${o.rule}${e.target.value}`,
        }
      });
    }
  };

  const confirmWelcomeMessage = async () => {
      const createMail = await fetcher(`admin/users/${linkParams.userId}/send-welcome`,'PATCH');
    if(createMail.result.success) {
     messageApi.success('Wiadomość powitalna została wysłana!');
     return;
    }

    if(createMail.result.error) {
      messageApi.error(createMail.result.error);
      return;
    }

  };

  const confirmResetPasswordMessage = async () => {
    const createMail = await fetcher(`admin/users/${linkParams.userId}/send-reset-password`,'PATCH');
    if(createMail.result.success) {
      messageApi.success('Wiadomość z instrukcjami dotyczącymi resetowania hasła została wysłana!');
      return;
    }

    if(createMail.result.error) {
      messageApi.error(createMail.result.error);
      return;
    }
  };

  if(permError) {
    return <PermError/>
  }


  return <><Card size="small" title={userData?.username} style={{textAlign: 'left'}} className={'no-printMe'}>

    <Row gutter={16} style={{flex: 1}}>
      <Col xs={24} xl={12}>
        <Card title="Dane Podstawowe" bordered={false} style={{height: 500, margin: 12}}>
          <p><span style={{fontWeight: 'bold'}}>Imię:</span> <Input value={tempFirstname ? tempFirstname : userData?.firstname} onChange={onFirstnameChange} /></p>
          <p><span style={{fontWeight: 'bold'}}>Nazwisko:</span> <Input value={tempLastname ? tempLastname : userData?.lastname} onChange={onLastnameChange} /></p>
          <p><span style={{fontWeight: 'bold'}}>Email:</span> <Input value={userData?.email} onChange={(e) => setUserData((u) => {if(!u) return; return {...u, email: e.target.value}})} /></p>
          <p><span style={{fontWeight: 'bold'}}>Organizacja:</span>

            <Select
              aria-required
              style={{width: '100%',}}
              placeholder="Wybierz Organizację"
              optionFilterProp="children"
              value={userData?.organization}
              onChange={(value) => {
                setUserData((obj) => {
                  if(!obj) {
                    return undefined;
                  }
                  return {...obj, organization: value}
                })
              }
              }
              options={[
                {
                  value: 'Elica Group Polska',
                  label: 'Elica Group Polska',
                },
                {
                  value: 'SLC Jelcz',
                  label: 'SLC Jelcz',
                },
                {
                  value: 'SLC Panattoni',
                  label: 'SLC Panattoni',
                },
              ]}
            />

          </p>
          <Checkbox onChange={onChangeUserBlocked} checked={userData?.blocked} value={userData?.blocked}>Użytkownik Zablokowany</Checkbox>
          <Popconfirm
            title="Wysyłanie Powitalnego E-Maila"
            description="Czy na pewno chcesz wysłać wiadomość powitalną? Użytkownik otrzyma link, pozwalający na ustawienie nowego hasła."
            onConfirm={confirmWelcomeMessage}
            okText="Tak"
            cancelText="Nie"
          >
            <Button style={{display: 'block', marginTop: 10}} icon={<MailOutlined />}>Wyślij Powitalny E-mail</Button>
          </Popconfirm>

          <Popconfirm
            title="Resetowanie Hasła przez E-mail"
            description="Czy na pewno chcesz wysłać wiadomość e-mail z linkiem resetującym hasło?"
            onConfirm={confirmResetPasswordMessage}
            okText="Tak"
            cancelText="Nie"
          >
            <Button style={{display: 'block', marginTop: 10}} icon={<MailOutlined />}>Resetowanie Hasła przez E-mail</Button>
          </Popconfirm>

        </Card>
      </Col>
      <Col xl={12} xs={24}>
        <Card title="Uprawnienia" bordered={false} style={{height: 500, margin: 12}}>
          {listPerm?.map((perm) =>
            <>
              <Checkbox key={perm.perm} onChange={onCheckBoxChange} checked={userData?.rule?.includes(String(perm.perm))} value={perm.perm}>{perm.name}</Checkbox>
              <br />
            </>
          )}
        </Card>
      </Col>
      <Col xs={24} style={{display: 'flex', justifyContent: 'right'}}><Button onClick={saveUser} style={{margin: 10}} icon={<TfiSave/>}><span style={{marginLeft: 5}}>Zapisz</span></Button></Col>
    </Row>
  </Card>
    {contextHolder}
  </>

}
