import { Spin, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';


interface Props {
  status: number;
  username?: string;
}

export const NavStatus = (props: Props) => {

  const [status, setStatus] = useState<{color: string; text: string; processing: boolean} | null>(null);
  const loggedIn = useContext(LoggedInContext);

  /*
* 0 - utworzony
* 1 - wydrukowany (jelcz)
* 2 - w trakcie księgowania przez danego usera
* 3 - zaksięgowany z błędami
* 4 - zaksięgowany (do sprawdzenia)
* 5 - w trakcie sprawdzania
* 6 - sprawdzony z błędami
* 7 - zakończony
* */

  useEffect(() => {

    type ColorsTag = 'magenta' | 'red' | 'volcano' | 'orange' | 'gold' | 'lime' | 'green' | 'cyan' | 'blue' | 'geekblue' | 'purple' | 'default';


    let color: ColorsTag = 'orange';
    let text = '';
    let processing = false;


    switch (props.status) {
      case 7:
        color = 'green';
        text = 'Zakończony';
        break;

      case 6:
        color = 'red';
        text = 'Błędy (Panattoni)';
        break;

      case 5:
        text = 'Kontrola rozładunku...';
        color = 'purple';
        break;
      case 4:
        text = 'Zaksięgowany';
        color = 'blue';
        break;
      case 3:
        text = 'Błędy księgowania!';
        color = 'red';
        break;
      case 2:
        text = `Księgowanie... (${props.username})`;
        color = 'cyan';
        processing = true;
        break;
      case 1:
        text = 'Załadowany';
        color = 'orange';
        break;

      case 0:
        text = 'Utworzony';
        color = 'default';

    }


    setStatus({
      color,
      text,
      processing,
    })

  },[props.status]);


  if(!status) {
    return <Spin spinning={true}/>
  }

  return <Tag icon={status.processing ? <SyncOutlined spin /> : undefined} color={status.color}>{status.text}</Tag>
}
