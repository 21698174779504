import React, { useEffect, useState } from 'react';
import { AutoComplete, Modal } from 'antd';
import { fetcher } from '../../../../extras/fetcher';
import Title from 'antd/es/typography/Title';
import { IkeaDaneInterface } from '../../../../types/ikeaDane.interface';
import { PermError } from '../../NotFoundPage/PermError';

interface Props {
  open : boolean;
  palletId: number
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}
export const ModalMoveOnePallet = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [dataRack, setDataRack] = useState([]);
  const [newRackName, setNewRackName] = useState<string>('');
  const [pallet, setPallet] = useState<IkeaDaneInterface | null>(null);

  const [moveLoading, setMoveLoading] = useState<boolean>(false);

  const [permError, setPermError] = useState<boolean>(false);

  const showSuccess = () => {
    let secondsToGo = 2;

    const modal = Modal.success({
      title: 'Paleta przeniesiona!',
      content: <div style={{textAlign: 'center'}}>Paleta została przeniesiona<br/><br/>
      </div>,
      okButtonProps: {style: {display: 'none'}}
    });

    const timer = setInterval(() => {
      secondsToGo -= 3;
      modal.update({
        content: <div style={{textAlign: 'center'}}>Paleta została przeniesiona<br/><br/>
          </div>,
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  };

  const showError = (error: string) => {

    const modal = Modal.error({
      title: 'Błąd podczas przenoszenia!',
      content: <div style={{textAlign: 'center'}}>{error}<br/><br/>
      </div>,
      /*okButtonProps: {style: {display: 'none'}}*/
    });
  };

  useEffect(() => {

    (async () => {
      setLoading(true);
      const onePallet = await fetcher(`ikea/pallet/id/${props.palletId}`);

      setPallet(onePallet.result);

      const dataRack = await fetcher('ikea/rack');

      if (dataRack.status === 403) {
        setLoading(false);
        setPermError(true);
        return;
      }

      setDataRack(dataRack.result);
      setLoading(false);
    })();

  },[])

  const handleOk = async () => {
    setMoveLoading(true);

    const movePallet = await fetcher('ikea/pallet/move','PATCH', {ikea2: pallet?.ikea2, newRackName});

    if(movePallet.status === 403) {
      setPermError(true);
      setMoveLoading(false);
      return;
    }

    if(movePallet.result.error) {
      showError(movePallet.result.error);
    } else if(movePallet.result.success) {

      showSuccess();
      props.handleSetModalOpened(false);
      props.handleGetData();

    }
    setMoveLoading(false);
  }

  const handleCancel = () => {
      props.handleSetModalOpened(false);
  }

  const onChange = (value: string) => {

    setNewRackName(value.toUpperCase());
  };

  const onSearch = (value: string) => {

  };

 return     <Modal bodyStyle={{padding: 10,}} title="Przenieś Paletę" open={props.open} onOk={handleOk} onCancel={handleCancel} confirmLoading={moveLoading}>
   { permError ? <PermError/> : <>
     <Title level={5}>Kod</Title>
     <p>{pallet?.ikeaCode.kod_elica}</p>
     <Title level={5}>Kod Klienta</Title>
     <p>{pallet?.ikeaCode.kod_ikea}</p>
     <Title level={5}>SSCC</Title>
     <p>{pallet?.ikea2}</p>
     <Title level={5}>Wybierz lokalizację docelową</Title>
     <AutoComplete
       style={{width: 250}}
       showSearch
       placeholder="Wybierz lokalizacje docelową"
       optionFilterProp="children"
       showArrow={false}

       onChange={onChange}
       value={newRackName}
       onSearch={onSearch}
       filterOption={(input, option) =>
         (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
       }
       options={[
         ...dataRack.map((data: any) => {
           return {
             label: data.name,
             value: data.name,
           };
         }),
       ]}
     />
     <Title level={5}
            style={{padding: 5}}>{`${pallet?.ikeaMag.name} -> ${newRackName ? newRackName : 'Nie wybrano lokalizacji'}`}</Title>
   </>}
 </Modal>

}
