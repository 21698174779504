import React, { useState } from 'react';
import { Badge, Modal, Table } from 'antd';
import { EcisDataDetailsInterface, EcisDataInterface } from '../../../../types/ecisData.interface';
import { ColumnsType, TableProps } from 'antd/es/table';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { myDateSorter, myNumberSorter, myStringSorter } from '../../../../extras/sorters';


interface Props {
  open : boolean;
  handleSetModalOpened: (opened: boolean) => void;
  ecisData : EcisDataInterface;
  }

export const ModalTableEcis = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [tableSelected, setTableSelected] = useState<EcisDataDetailsInterface[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const {windowHeight} = useWindowDimensions();


  const columns: ColumnsType<EcisDataDetailsInterface> = [
    {
      title: 'Kod',
      width: 150,
      filterMode: 'tree',

      sorter: (a, b) => myStringSorter(a.ikeaCode.kod_elica, b.ikeaCode.kod_elica),
      sortDirections: ['descend', 'ascend'],

      render: (value, record) => record.ikeaCode.kod_elica,
    },
    {
      title: 'Kod Klienta',
      filterMode: 'tree',
      width: 150,
      align: 'center',
      sorter: (a,b) => myDateSorter(a.ikeaCode.kod_ikea, b.ikeaCode.kod_ikea),
      render: (value, record) => record.ikeaCode.kod_ikea,
    },

    {
      title: 'Typ',
      dataIndex: 'ikeaCodeTypeName',
      filterMode: 'tree',
      width: 150,
      align: 'center',
      sorter: (a,b) => myStringSorter(a.ikeaCode.ikeaCodeType.name,b.ikeaCode.ikeaCodeType.name),
      render: (value, record) => record.ikeaCode.ikeaCodeType.name,
    },

    {
      title: 'Ilość SAP',
      width: 120,
      align: 'center',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.pcsSap, b.pcsSap),

      render : (value, record) => record.pcsEcis !== record.pcsSap ? <Badge overflowCount={9999} showZero color={'#f5222d'} count={record.pcsSap}/> : record.pcsSap,

    },

    {
      width: 120,
      title: 'Palet SAP',
      align: 'center',
      filterMode: 'tree',
      sorter: (a,b) => myNumberSorter(a.pltSap,b.pltSap),

      render : (value, record) => record.pltEcis !== record.pltSap ? <Badge overflowCount={9999} showZero color={'#f5222d'} count={record.pltSap}/> : record.pltSap,

    },

    {
      title: 'Ilość ECIS',
      width: 120,
      align: 'center',
      filterMode: 'tree',
      sorter: (a,b) => myNumberSorter(a.pcsEcis, b.pcsEcis),

      render : (value, record) => record.pcsEcis !== record.pcsSap ? <Badge overflowCount={9999} showZero color={'#f5222d'} count={record.pcsEcis}/> : record.pcsEcis,

    },

    {
      width: 120,
      title: 'Palet ECIS',
      align: 'center',
      filterMode: 'tree',
      sorter: (a,b) => myNumberSorter(a.pltEcis,b.pltEcis),

      render : (value, record) => record.pltEcis !== record.pltSap ? <Badge overflowCount={9999} showZero color={'#f5222d'} count={record.pltEcis}/> : record.pltEcis,

    },

  ];

  const handleOk = () => {
    props.handleSetModalOpened(false);
  }


  const handleExcelClick = (ecisData : EcisDataDetailsInterface[]) => {
    return null;
  }


  const onChange: TableProps<EcisDataDetailsInterface>['onChange'] = (pagination, filters, sorter, extra) => {
    /// console.log('params', pagination, filters, sorter, extra);
  };


  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EcisDataDetailsInterface[]) => {
   //   console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };


  return   <Modal width={'90%'} bodyStyle={{padding: 10,}} title={`${props.ecisData.ECIS} - Podgląd`} open={props.open} onOk={handleOk} onCancel={handleOk}>
    <Table
      rowKey={'id'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      scroll={{ y: windowHeight *.6}}

      columns={columns}   pagination={false} loading={loading} showHeader={true} bordered={true} dataSource={props.ecisData?.details?.map((x,index) =>{ return {...x, id: index}})} onChange={onChange} style={{opacity: 0.9}} size={'small'} />

  </Modal>

}
