import { useIdleTimeout } from '../../hooks/useIdleTimeout';
import React, { useContext, useEffect, useState } from 'react';
import { Modal, Progress } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { fetcher } from '../../extras/fetcher';
import { LoggedInContext } from '../../contexts/loggedIn.context';
import Cookies from 'universal-cookie';
import { WarningOutlined } from '@ant-design/icons';

interface Props {

}

export const AutoLogout = (props: Props) => {

  const [modalOpened, setModalOpened] = useState(false);

  const [stopLogout, setStopLogout] =useState(false);
  const loggedInContext = useContext(LoggedInContext);
  const cookies = new Cookies();
  const navigation = useNavigate();

  const [timeToLogout, setTimeToLogout] = useState<number>(60);
  const linkParams = useParams();


  const onIdle = () => {
    console.log('onIdle');
    setModalOpened(true);
  }


  const logout = async () => {
    await fetcher('auth/logout');



    setTimeout(() => {
      cookies.remove('token');
      loggedInContext?.setLoggedIn(false);
    },0);
  }


  const idle = useIdleTimeout({onIdle, idleTime: 900, promptTimeout: 60});


  useEffect(() => {

    if(idle.isIdle) {

      if(!stopLogout) {
        /// wyloguj
        (async () => {
          await logout();
        })();

      }

      idle.setIdle(false);
    }
    setStopLogout(false);
  },[idle.isIdle, linkParams]);


  const handleOk = () => {
    setStopLogout(true);
    idle.setIdle(false);
    setModalOpened(false);
    idle.idleTimer.reset();
    setStopLogout(false);
  }


  useEffect(() => {

    setInterval(() => {
      setTimeToLogout(Number((idle.idleTimer.getRemainingTime()/1000).toFixed(0)));
    },1000);

  },[]);


  return  <><Modal
    title=""
    centered
    width={500}
    open={modalOpened}
    closable={false}
    maskClosable={true}
    mask={true}

    style={{
      textAlign: 'center',
    }}

    okButtonProps={{
      style: {
        display: 'none'
      }
    }}

    cancelButtonProps={{
      style: {
        display: 'none',
      }
    }}

    onOk={() => handleOk()}
    onCancel={() => handleOk()}
  >
    <div onClick={handleOk}>
    <Progress type="circle" percent={((-1*timeToLogout)*1.66)*-1} format={(per) => timeToLogout} status="exception" />

    <p style={{fontWeight: 'bold', marginTop: 15, color: 'red', textTransform: 'uppercase', fontSize: 16}}><WarningOutlined style={{color: 'red', fontSize: 24}}/> Automatyczne wylogowanie za chwilę... <WarningOutlined style={{color: 'red', fontSize: 24}}/></p>
    <p style={{fontSize: 'smaller', fontWeight: 'bold'}}>
      W celu zapewnienia bezpieczeństwa, system automatycznie wylogowuje użytkownika po 15 minutach braku aktywności.</p>
    </div>
  </Modal>

    </>;

}
