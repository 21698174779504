import React, { useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CopyOutlined } from '@ant-design/icons';

interface Props {
  open : boolean;
  udcList: number[];
  handleSetModalOpened: (opened: boolean) => void;
}
export const ModalShowUdc = (props: Props) => {

  const [udcList, setUdcList] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const handleOk = async () => {
    props.handleSetModalOpened(false);
  }

  const handleCancel = async () => {
    props.handleSetModalOpened(false);
  }

  useEffect(() => {
    setUdcList(props.udcList.map(udc => String(udc)).join('\n'));
  },[props.udcList]);


  const copyToClip = () => {
    navigator.clipboard.writeText(udcList);


    messageApi.open({
      type: 'success',
      content: 'Skopiowano do schowka!',
    });

  }

  return <Modal destroyOnClose={true}  bodyStyle={{padding: 10,}} title={`Lista UDC (${props.udcList.length})`} open={props.open} onCancel={handleCancel} onOk={handleOk}>
    <>
      <Button type={'text'} onClick={() => copyToClip()} icon={<CopyOutlined />}></Button>
      <div style={{ display: 'flex', alignItems: 'center', alignContent: 'flex-end', justifyContent: 'space-evenly', padding: 5 }}>
        <TextArea rows={15} size={'small'} value={udcList} />
      </div>

      {contextHolder}
    </>
  </Modal>
}
