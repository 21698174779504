import React, { useEffect, useState } from 'react';
import { Card, InputNumber, Modal, Spin, Switch } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Cookies from 'universal-cookie';
import { fetcher } from '../../../../extras/fetcher';
import { PermError } from '../../NotFoundPage/PermError';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { OneDayChart } from './OneDayChart';

interface Props {
  type: 'reg' | 'storage',
}
export const LongTermChart = (props: Props) => {

  const cookies = new Cookies();

  const [dataChart1, setDataChart1] = useState<any[]>([]);
  const [dataChart1User, setDataChart1User] = useState<string[]>([]);
  const [dataChart1days, setDataChart1days] = useState<number>(cookies.get('dataChart1days') ? cookies.get('dataChart1days') : 14);

  const [chartColors] = useState(['#5499C7', '#AF7AC5', '#52BE80', '#d58b38', '#F5B041']);

  const [dataChart1notUser, setDataChart1notUser] = useState(cookies.get('dataChart1notUser') !== '0');

  const [permError, setPermError] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const [selectedDay, setSelectedDay] = useState<string>();
  const [modalOpened, setModalOpened] = useState(false);


  useEffect(() => {
  },[]);

  useEffect(() => {
    cookies.set('dataChart1days',dataChart1days);
  },[dataChart1days]);

  useEffect(() => {
    getData();
  },[dataChart1days]);


  const getData = async () => {

    setLoading(true);

    await fetcher(`ikea/stats/${props.type}/${dataChart1days}`)
      .then((res) => {

        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }

        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        const dateChartFormat = res.result.reduce((acc: any, curr: any) => {

          const ix = acc.length > 0 ? acc.findIndex((x: any) => x.date === curr.date) : -1;

          if(ix === -1) {
            return [
              ...acc,
              {
                date: curr.date,
                [curr.name]: curr.count,
                'Łącznie': curr.count,
              },
            ];
          } else {

            const arr = [...acc];
            arr[ix] = {
              ...acc[ix],
              [curr.name]: curr.count,
              'Łącznie': acc[ix]['Łącznie'] + curr.count,
            };
            return arr;
          }

        },[]);


        const chart1User = Array.from(new Set(res.result.map((x: {name: string}) => x.name)));

        setDataChart1User([...chart1User as string[], 'Łącznie']);
        console.log(dateChartFormat);
        console.log(chart1User);

        setDataChart1(dateChartFormat);
        setLoading(false);

      });

  }


  const switchHandler = (bool: boolean) => {
    setDataChart1notUser(!bool);
    cookies.set('dataChart1notUser',bool ? '0' : '1');
  };


  const handleClick = ( data: CategoricalChartState) => {
    setSelectedDay(data.activeLabel);
    setModalOpened(true);
  }

  const modalHandleOk = () => {
    setSelectedDay(undefined);
    setModalOpened(false);
  };

  return (
    permError ? <PermError/> : <><Card title={

      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{width: 200}}></div>
        <div style={{width: 200}}>Wykres Długoterminowy</div>
        <div style={{fontSize: 'smaller', justifyContent: 'space-between', display: 'flex', width: 200}}><div style={{marginTop: 5}}>Dni:</div> <InputNumber value={dataChart1days} onChange={(value) => setDataChart1days(value as number)} bordered={false} style={{width: '50px', backgroundColor: 'transparent', fontSize: 'smaller'}}/><div style={{marginTop: 5, marginRight: 10}}>Użytkownicy: <Switch checked={!dataChart1notUser} size={'small'} style={{marginTop: -2, backgroundColor: !dataChart1notUser ? '#ef343a' : undefined}} onChange={switchHandler} /></div></div>
      </div>

    } size={'small'} style={{height: 500}}>
      <Spin spinning={loading}>
      <ResponsiveContainer width={'99%'} height={450}>
        <BarChart
          width={500}
          height={300}
          data={dataChart1}
          style={{
            cursor: 'pointer',
          }}
          onClick={handleClick}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey={"date"} />
          <YAxis/>
          <Tooltip/>
          <Legend/>
          {
            dataChart1User.map((item,index) => (
              !dataChart1notUser ? <Bar key={item} dataKey={item} fill={item !== 'Łącznie' ? chartColors[index] : '#ef343a'} /> :
                item==='Łącznie' ? <Bar key={item} dataKey={item} fill={'#ef343a'} /> : null
            ))
          }


        </BarChart>
      </ResponsiveContainer>
      </Spin>
    </Card>
      {selectedDay && <Modal bodyStyle={{padding: 10}} width={'75%'} okButtonProps={{style: {backgroundColor: '#ef343a'}}}
                             cancelButtonProps={{style: {display: 'none'}}} title={`Wykres Godzinowy: ${selectedDay}`} open={modalOpened}
                             onOk={modalHandleOk} onCancel={modalHandleOk}>
          <OneDayChart type={props.type} day={selectedDay} name={'LongTermModal'}/>
      </Modal>}
    </>
  )
}
