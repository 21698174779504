import React, { useContext, useEffect } from 'react';
import { Result, Spin } from 'antd';

import logoResult from '../../../logoBig.png';
import { LoggedInContext } from '../../../contexts/loggedIn.context';
import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

interface Props {
  handleSetMainTitle: (title: string) => void;
  setBreadcrumbHandler: (items: MainBreadcrumbItem[] | null) => void;
}

export const HomePage = (props: Props) => {
  const loggedInContext = useContext(LoggedInContext);
  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    props.handleSetMainTitle('');
    props.setBreadcrumbHandler(null);
  },[]);

  return (<div className="home-page">
      { windowWidth >= 768 && <img src={logoResult} alt={"Logo firmy SL Concept"} style={{width: '40%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '40px'}} />}
      {
        loggedInContext?.user?.firstname
        ?
          <Result
            icon={null}
            title={`Witamy, ${loggedInContext?.user?.firstname} na stronie głównej!`}
            extra={<>
              <h3 style={{marginTop: '40px'}}>Aby rozpocząć zarządzanie magazynem, prosimy o przejście do menu po lewej stronie.</h3>
              <h3>Uprawnienia dostępu do funkcji są uzależnione od posiadanego poziomu autoryzacji.</h3>
              <h4 style={{marginTop: '40px'}}>Jeśli masz jakiekolwiek pytania lub potrzebujesz pomocy w korzystaniu z panelu, skorzystaj z naszego systemu ticketów.</h4>
            </>}
          />
          :

          <Spin size={'large'}/>

      }

    </div>
  )
}
