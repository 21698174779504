import React, { useEffect, useState } from 'react';
import { IkeaDaneInterface } from '../../../../types/ikeaDane.interface';
import { fetcher } from '../../../../extras/fetcher';
import { Button, Modal, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';
import moment from 'moment/moment';
import { PermError } from '../../NotFoundPage/PermError';
import { HistoryOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface Props {
  open : boolean;
  palletId: number
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}
export const ModalIkeaIdDetails = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [pallet, setPallet] = useState<IkeaDaneInterface | null>(null);

  const [permError, setPermError] = useState<boolean>(false);

  useEffect(() => {

    (async () => {
      setLoading(true);
      const onePallet = await fetcher(`ikea/pallet/id/${props.palletId}`);

      if (onePallet.status === 403) {
        setPermError(true);
        setLoading(false);
        return;
      }

      setPallet(onePallet.result);
      setLoading(false);
    })();

  },[props.palletId]);

  const handleOk = async () => {
    props.handleSetModalOpened(false);
  }



  return     <Modal bodyStyle={{padding: 10,}} okButtonProps={{style: {backgroundColor: '#ef343a'}}} cancelButtonProps={{style: {display: 'none'}}} title="Szczegółowe informacje" open={props.open} onOk={handleOk} onCancel={handleOk}>
    {permError ? <PermError/> : <>
      <Title level={5}>Kod</Title>
      <p>{pallet?.ikeaCode.kod_elica}</p>
      <Title level={5}>Kod Klienta</Title>
      <p>{pallet?.ikeaCode.kod_ikea}</p>
      <Title level={5}>UDC</Title>
      <p>{pallet?.udc}<Tooltip title={'Historia UDC'}><Link to={`/history/udc/${pallet?.udc}`}><Button style={{color: '#ef343a', margin: 0, padding: 2}} type={'link'}><HistoryOutlined /></Button></Link></Tooltip></p>
      <Title level={5}>Lokalizacja</Title>
      <p>{pallet?.ikeaMag.name}</p>
      <Title level={5}>SSCC</Title>
      <p>{pallet?.ikea2}<Tooltip title={'Historia SSCC'}><Link to={`/history/sscc/${pallet?.ikea2}`}><Button style={{color: '#ef343a', margin: 0, padding: 2}} type={'link'}><HistoryOutlined /></Button></Link></Tooltip></p>
      <Title level={5}>Numer Tygodnia</Title>
      <p>{pallet?.date_stamp}</p>
      <Title level={5}>Data Rejestracji</Title>
      <p>{pallet?.date ? moment(pallet.date).format('DD/MM/YYYY HH:mm:ss') : '--'}</p>
      <Title level={5}>Rejestracja Przez</Title>
      <p>{pallet?.terminalUsers?.name}</p>
      <Title level={5}>Data Magazynowania</Title>
      <p>{pallet?.mag_date ? moment(pallet.mag_date).format('DD/MM/YYYY HH:mm:ss') : '--'}</p>

      <Title level={5}>Nr seryjne</Title>
      <p>
        {pallet?.serial.split(':').map((serial) => <>{serial}<br/></>)}
      </p>
    </>}
  </Modal>

}
