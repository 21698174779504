import React, { useEffect, useState } from 'react';
import { fetcher } from '../../../../extras/fetcher';
import { Alert, AutoComplete, Button, message, Modal, Popconfirm, Progress, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import { ColumnsType } from 'antd/es/table';
import { IkeaMagInterface } from '../../../../types/ikeaMag.interface';

interface Props {
  open : boolean;
  racksId:  number[];
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}
export const ModalMoveMultipleLocations = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [dataRack, setDataRack] = useState([]);
  const [rackData,setRackData] = useState<TableData[]>([]);
  const [newRackName, setNewRackName] = useState<string>('');

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [rackMoved, setRackMoved] = useState<number>(0);
  const [rackError, setRackError] = useState<number>(0);

  const [messageApi, contextHolder] = message.useMessage();

  const [permError, setPermError] = useState<boolean>(false);


  interface TableData {
    id: number;
    rackName: string;
    status: any;
  }

  useEffect(() => {

    (async () => {
      setLoading(true);
      const dataRack = await fetcher('ikea/rack');
      setDataRack(dataRack.result);


      const myRacks : Promise<TableData>[] = await (await (props.racksId.map(async (id) => {

        const oneData = await fetcher(`ikea/rack/id/${id}`);

        const myRack :IkeaMagInterface = oneData.result;

        return {
          id: myRack.id,
          rackName: myRack.name,
          status: '',
        }
      })));

      await setRackData(await Promise.all(myRacks));

      await setRackMoved(0);
      await setRackError(0);
      setNewRackName('');

      setLoading(false);
    })();

  },[props.racksId]);

  const onChange = (value: string) => {
    setNewRackName(value.toUpperCase());
  }


  const handleOk = async () => {
    props.handleSetModalOpened(false);
    props.handleGetData();
  }


  const tableColumns: ColumnsType<TableData> = [
    {
      title: 'Lokalizacja',
      dataIndex: 'rackName',
      fixed: true,
      width: 100,
      align: 'center',
    },

    {
      title: 'Nowa lokalizacja',
      fixed: true,
      width: 100,
      align: 'center',
      dataIndex: 'newName',

    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 300
    }

  ];

  const confirmMove = async () => {
    if (newRackName === '') {
      messageApi.open({
        type: 'warning',
        content: 'Nie wybrano regału docelowego!',
      });

      return;
    }

    startMove();
  }


  const startMove = async () => {


    setLoading(true);
    setRackError(0);
    setRackMoved(0);
    const newArr = await ([...rackData.map(async (oneRack) => {
      // move pallet

      const data = await fetcher(`ikea/rack/move/${oneRack.rackName}/${newRackName}`,'PATCH');

      if(data.status === 403) {
        setRackError((count) => count+1);
        return {
          ...oneRack,
          status: <Alert message={'Brak uprawnień'} banner type={'error'} showIcon />,
        }
      } else if(data.result.success) {
        setRackMoved((count) => count+1);
        return {
          ...oneRack,
          status: data.result.error ? <Alert message={data.result.error} banner type={'warning'} showIcon /> : <Alert message={`Przeniesionych pozycji: ${data.result.affected}`} banner type={'success'} showIcon />,
          newName: newRackName,
        }
      } else {

        setRackError((count) => count+1);
        return {
          ...oneRack,
          status: <Alert message={data.result.error} banner type={'error'} showIcon />,
        }

      }


    })]);

    setRackData(await Promise.all(newArr));

    setLoading(false);

  };

  return <Modal bodyStyle={{padding: 10}} width={'70%'} title="Przenoszenie Lokalizacji" open={props.open} cancelButtonProps={{ style: { display: 'none' } }} okText={'Zamknij'} onOk={handleOk} onCancel={handleOk} destroyOnClose={true} confirmLoading={moveLoading}>
    <>
      <Title level={5}>Wybierz nową lokalizację</Title>
      <AutoComplete
        style={{width: 250, margin: 10}}
        showSearch
        placeholder="Wybierz lokalizacje docelową"
        optionFilterProp="children"
        showArrow={false}

        onChange={onChange}
        value={newRackName}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
          ...dataRack.map((data: any) => {
            return {
              label: data.name,
              value: data.name,
            };
          })
        ]}
      />


      {contextHolder}
      <Table
        loading={loading}
        rowKey={'id'}
        columns={tableColumns}
        dataSource={rackData}
        pagination={false}
        scroll={{ y: 250 }}
        size={'small'}
        bordered
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align={'center'}><Progress type="circle" percent={(100/rackData.length)*rackMoved} width={45} /></Table.Summary.Cell>
              <Table.Summary.Cell index={1}>Ilość: {rackData.length}<br/>Przeniesionych: {rackMoved}<br/>Liczba błędów: {rackError}<br/></Table.Summary.Cell>
              <Table.Summary.Cell index={2} align={'center'}>

                <Popconfirm
                  disabled={rackMoved === rackData.length && rackMoved !== 0 }
                  title={`Na pewno chcesz przenieść wszystkie zaznaczone pozycję na lokalizację ${newRackName}?`}
                  onConfirm={confirmMove}
                  okText="Tak"
                  cancelText="Nie"
                >

                <Button disabled={rackMoved === rackData.length && rackMoved !== 0 }>Rozpocznij przenoszenie</Button>

                </Popconfirm>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </>
  </Modal>;





}
