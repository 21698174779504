import React, { useEffect } from 'react';
import { Result } from 'antd';
import { useNavigate } from 'react-router-dom';


export const PermError = () => {

  const navigate = useNavigate();

  useEffect(() => {

  },[]);

  return (
    <Result
      status="403"
      title="403"
      subTitle="Nie posiadasz wymaganych uprawnień."
    />

  )

}
