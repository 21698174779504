import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, message, Spin } from 'antd';
import logo from '../../../logo.png';
import { fetcher } from '../../../extras/fetcher';

interface Props {
  resetToken: string;
}
export const ResetPassword = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();

  const [tokenActive, setTokenActive] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if(props.resetToken === '') {
        return;
      }

      const testToken  = await fetcher(`user/reset-token/${props.resetToken}/active`);

      setTokenActive(testToken.result.active);

      setLoading(false);


    })();
  },[]);


  const onFinishFailed = () => {
    //console.log('Błędne dane logowania');
  }

  const onFinish = async (values: {pass1: string, pass2: string}) => {
    setLoading(true);



    const result = await fetcher(`user/reset-token/${props.resetToken}`,  'PATCH', {
      token: props.resetToken,
      password: values.pass2,
    });

    if(result.result.success) {
      await messageApi.loading('Hasło zostało prawidłowo zaktualizowane!, Za chwilę nastąpi przekierowanie na stronę logowania..');
      window.open('/','_top');
    } else if(result.result.error)
    {
      messageApi.open({
        type: 'error',
        content: result.result.error,
      });
    } else {
      messageApi.open({
        type: 'error',
        content: 'Nieznany błąd podczas zmiany hasła!',
      });
    }

    setLoading(false);
  }

  return (
    <div className="login-page">
      {contextHolder}

      {
        tokenActive
          ?
          <div className="form">
            <p style={{fontWeight: 'bold'}}>Wprowadź nowe hasło logowania do systemu.</p>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label=""
                name="pass1"
                rules={[{ required: true, message: 'Hasło musi zawierać minimum 6 znaków i musi składać się z liter i liczb.', pattern: new RegExp(/^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{6,}$/) }]}
              >
                <Input type={'password'} disabled={loading} required placeholder={'NOWE HASŁO'}/>
              </Form.Item>


              <Form.Item
                label=""
                name="pass2"

                rules={[{ required: true, message: 'Proszę podać prawidłowe hasło!',},
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('pass1') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Hasła, które wprowadzono, nie są identyczne!'));
                    },
                  }),

                ]}
              >
                <Input type="password" disabled={loading} required placeholder="POWTÓRZ HASŁO"/>
              </Form.Item>
              <Button style={{height: 50}} loading={loading} type="primary" htmlType="submit">Zmień Hasło</Button>
            </Form>
          </div>
          :
          <div className="form">
            {
              !loading ?
                <Alert
                  message="Link nieaktywny"
                  description="Aby zmienić hasło, należy wygenerować nowy link. Stary link stracił ważność."
                  type="error"
                />
                : <Spin spinning={true} size="large"/>

            }
          </div>
      }

      <img style={{alignSelf: 'right', position: 'absolute', bottom: 20, right: 20, opacity: 0.8}} src={logo} alt="Logo SL Concept"/>
    </div>
  );
}
