import { Button, Tooltip } from 'antd';
import addUserIcon from '../../Ikea/Stan/icons/add-user.png';
import React from 'react';

interface Props {
  handleAddUserClick?: () => void;
  selectedCount: number;
  additional?: JSX.Element;
}

export const TableHeader = (props: Props) => {


  return <div className={'table-header'}>
    <Tooltip title={'Dodaj Użytkownika'}>
      <Button type={'text'} onClick={props.handleAddUserClick}>
        <img src={addUserIcon} style={{width: '16px', height: '16px'}} alt="Drukuj..."/></Button>
    </Tooltip>
    {props.additional}
  </div>;

};
