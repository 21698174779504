import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { Badge, Button, Card, message, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { Link, useParams } from 'react-router-dom';
import { myNumberSorter, myStringSorter } from '../../../../extras/sorters';
import { fetcher } from '../../../../extras/fetcher';
import { AuditOutlined, EyeOutlined } from '@ant-design/icons';
import { exportToExcel } from '../../../../extras/exportToExcel';
import { PermError } from '../../NotFoundPage/PermError';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';
import { checkRule } from '../../../../extras/checkRule';
import { NavListInterface } from '../../../../types/navList.interface';
import { TableHeader } from '../../Ikea/Stan/TableHeader';
import { TableFooter } from '../../Ikea/Stan/TableFooter';
import { ModalShowUdc } from './ModalShowUdc';
import { DeliveryListTable } from './DeliveryListTable';
import { ModalShowCode } from './ModalShowCode';
import { UploadLX02 } from './UploadLX02';
import { NavCompInterface } from '../../../../types/navComp.interface';
import { NavStatusInfo } from './NavStatusInfo';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const OneNav = (props: Props) => {
  const [tableData, setTableData] = useState<NavCompInterface[]>([]);
  const [navList, setNavList] = useState<NavListInterface>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<NavCompInterface[]>([]);
  const [messageApi, contextHolder] = message.useMessage();


  const [modalShowUdcOpened, setModalShowUdcOpened] = useState<boolean>(false);
  const [modalShowCodeOpened, setModalShowCodeOpened] = useState<boolean>(false);


  const [loading, setLoading] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const { windowHeight } = useWindowDimensions();

  const [permError, setPermError] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);

  const linkParams = useParams();

  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[]=[{
      href: '',
      key: 'Nav',
      label: 'Nav',
    },

      {
        href: '/nav/list/',
        key: 'Nav-List',
        label: 'Lista Wysyłek',
      },

      {
        href: `/nav/list/${linkParams.navId}/`,
        key: 'oneNav',
        label: navList ? `NAV${navList.nav}` : '...',
      },
    ];


    props.handleSetMainTitle(navList ? `NAV${navList.nav}` : '...');
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['nav-list']);

  },[navList]);


  const columns: ColumnsType<NavCompInterface> = [
    {
      title: 'Kod',
      width: 120,
      dataIndex: 'code',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(tableData.map((arr) => arr.code))).map((val) => {
          return {
            text: val,
            value: val,
          }
        }),
      ],

      onFilter: (value, record) => record.code.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myStringSorter(a.code,b.code),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record) => <Link to={`/nav/list/${linkParams.navId}/code/${value}`}>{value}</Link>,

    },
    {
      title: 'Jelcz',
      dataIndex: 'jelcz',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.jelcz,b.jelcz),

    },

    {
      title: 'LX02',
      dataIndex: 'lx02',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.lx02,b.lx02),
      render: (value, record) => <Badge count={value} showZero overflowCount={9999} color={record.jelcz === record.lx02 ? '#52c41a' : '#f5222d'} />
    },

    {
      title: 'SAP',
      dataIndex: 'sap',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.sap,b.sap),
      render: (value, record) => <Badge count={value} showZero overflowCount={9999} color={record.lx02 === record.sap ? '#52c41a' : '#f5222d'} />
    },


    {
      title: 'Panattoni',
      dataIndex: 'panattoni',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.panattoni,b.panattoni),
      render: (value, record) => {
        return <><Badge count={value} overflowCount={9999} showZero
               color={record.sap === record.panattoni ? '#52c41a' : '#f5222d'}/>
          </>
      }
    },


  ];


  const getNavComp = async () => {
      const res = await fetcher(`nav/list/${linkParams.navId}/comp`);
      setTableData(res.result ? res.result : []);
  }


  const getData = async () => {
    await setLoading(true);

    const data =  await fetcher(`nav/list/${linkParams.navId}`);

    if(data.status === 403) {
      setPermError(true);
      return;
    }

    await getNavComp();

    setNavList(data.result as NavListInterface);


    await setLoading(false);

  }

  useEffect(() => {
    getData();
  },[]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: NavCompInterface[]) => {
      //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  const handleExcelClick = (data: NavCompInterface[]) => {



    const dataToExport = data.map((data: NavCompInterface,index) => {

    return {
        id: index+1,
        Kod: data.code,
        Jelcz: data.jelcz,
        LX02: data.lx02,
        SAP: data.sap,
        Panattoni: data.panattoni,
      }
    })
    exportToExcel(dataToExport);

  }


  const startCollect = async () => {
    const res =  await fetcher(`nav/list/${linkParams.navId}/status`, 'PATCH', { navId: Number(linkParams.navId), status: 2});

    if(res.result.success) {
      messageApi.success('Status księgowania zmieniony na "W trakcie księgowania"');
      getData();
    } else if(res.result.error) {
      messageApi.error(res.result.error);
    } else {
      messageApi.error('Nieznany błąd!');
    }

  }

  const onChange: TableProps<NavListInterface>['onChange'] = (pagination, filters, sorter, extra) => {
    /// console.log('params', pagination, filters, sorter, extra);
  };

  const ButtonCollect: ReactElement =  navList && navList?.status === 1 ? <Button disabled={!checkRule('c1',loggedIn?.user?.rule!)} onClick={startCollect} size={'small'} icon={<AuditOutlined />}>Rozpocznij księgowanie</Button> : <></>;
  const ButtonUDC: JSX.Element =   navList && navList?.status > 1 ? <Button disabled={!checkRule('c1',loggedIn?.user?.rule!)} onClick={() => setModalShowUdcOpened(true)} size={'small'} icon={<EyeOutlined />}>Lista UDC</Button>: <></>;
  const ButtonCode: JSX.Element =  navList && navList?.status > 1 ? <Button disabled={!checkRule('c1',loggedIn?.user?.rule!)} onClick={() => setModalShowCodeOpened(true)} size={'small'} icon={<EyeOutlined />}>Lista Kodów</Button> : <></>;
  const ButtonImportLX02: JSX.Element = navList && navList?.status > 1 ? <UploadLX02 navId={Number(linkParams.navId)} /> : <></>;


  if(permError) {
    return <PermError/>
  }

  return  <>
    { navList && <NavStatusInfo status={navList.status} creditedByUser={navList.creditedByUser ? navList.creditedByUser : null} />}
    {contextHolder}
    <Table
      rowKey={'code'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={(records) => <TableHeader getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} additional={<>{ButtonCollect}{ButtonUDC}{ButtonCode}{ButtonImportLX02}</>} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />

{/*    { currentTripNr && <ModalTripStatus open={modalStatusOpened} tripNr={currentTripNr} handleSetModalOpened={handleSetModalStatusOpened} handleGetData={getData} /> }*/}

    { navList && <ModalShowUdc open={modalShowUdcOpened} udcList={navList.navDataJelcz.map((data) => data.udc)} handleSetModalOpened={setModalShowUdcOpened} /> }
    { navList && <ModalShowCode open={modalShowCodeOpened} codeList={navList.navDataJelcz.map((data) => data.navCode.code)} handleSetModalOpened={setModalShowCodeOpened} /> }


    <Card title="Delivery" size={'small'} bordered={false} style={{marginTop: 20,}}>
      {navList && <DeliveryListTable navList={navList} loading={loading} getData={getData} navDelivery={navList.navDelivery}/>}

    </Card>





  </>
}
