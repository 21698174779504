import React, { useContext, useEffect, useRef, useState } from 'react';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { useParams } from 'react-router-dom';
import { fetcher } from '../../../../extras/fetcher';
import { TerminalUsersInterface } from '../../../../types/terminalUsersInterface';
import { Button, Card, Checkbox, Col, Input, message, Popconfirm, QRCode, Row, Select } from 'antd';
import { TerminalUsersPermInterface } from '../../../../types/terminalUsersPerm.interface';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import printerIcon from '../../Ikea/Stan/icons/printer.png';
import { TfiSave } from 'react-icons/tfi';
import { PermError } from '../../NotFoundPage/PermError';


interface Props {
  handleSetMainTitle: (title: string) => void;
  setBreadcrumbHandler: (items: MainBreadcrumbItem[] | null) => void;
}
export const OneUserTerm = (props: Props) => {

  const selectedMenuContext = useContext(SelectedMenuContext);
  const [loading, setLoading] = useState<boolean>(false);
  const linkParams = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [listPerm, setListPerm] = useState<TerminalUsersPermInterface[] | null>(null);
  const [permError, setPermError] = useState<boolean>(false);

  const [tempName, setTempName] = useState<string | null>(null)
  let componentRef = useRef();

  const [userData, setUserData] = useState<TerminalUsersInterface>();

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {


    if(!e.target.checked) {
      setUserData((o) => {
        if(!o) {
          return;
        }
        return {
          ...o,
          rule: o.rule!.replace(e.target.value,''),
        }
      });
    } else {

      setUserData((o) => {

        if(!o) {
          return;
        }

        return {
          ...o,
          rule: `${o.rule}${e.target.value}`,
        }
      });
    }
  };

  const getData = async () => {
    const getPerm = await fetcher('admin/users-term/list-perm');
    setListPerm(getPerm.result);
    const userF = await fetcher(`admin/users-term/${linkParams.userTermId}`);

    if(userF.status === 403) {
      setPermError(true);
    }

    if(userF.result.error) {
      return messageApi.error(userF.result.error)
    }

    setUserData(userF.result);



  }

  const printToken = () =>{
    window.print();
  }

  useEffect(() => {
    getData();
  },[]);

  useEffect(() => {

    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Admin',
      label: 'Administracja',
    },
      {
        href: '',
        key: 'users',
        label: 'Użytkownicy',
      },

      {
        href: `/admin/users-term/`,
        key: 'usersTerm',
        label: 'Terminal',
      },
      {
        href: ``,
        key: 'usersTermName',
        label: userData ? userData.name : '',
      },
    ];


    props.handleSetMainTitle(`Użytkownicy - Terminal`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['users2']);

  }, [userData]);


  const onChangeUserBlocked = (x : CheckboxChangeEvent) => {

    setUserData((u) => {

      if(!u) {
        return;
      }

      return {
        ...u,
        blocked: x.target.checked,
      }

    });
  }

  const saveUser = async () => {

    const dataBody = {
      ...userData,
      name: tempName ? tempName : userData?.name
    };
    setTempName(null);

    const newUserData = await fetcher(`admin/users-term/${linkParams.userTermId}`, 'PATCH', dataBody);

    if(newUserData.result.error) {
      messageApi.error(newUserData.result.error);
    } else {
      setUserData(newUserData.result);
      messageApi.success('Dane zostały prawidłowo zapisane!');
    }

  }

  const generateNewToken = async () => {

    const newTokenF = await fetcher(`admin/users-term/${linkParams.userTermId}/genToken`, 'PATCH');

    if(newTokenF.result.error) {
      messageApi.error(newTokenF.result.error)
    } else {
      setUserData(newTokenF.result);
      messageApi.success('Token został wygenerowany i zapisany poprawnie');
    }

  };

  const onNameChange = (e: any) => {

    setTempName(e.target.value);

  }

  if(permError) {
    return <PermError/>
  }

  return <><Card size="small" title={userData?.name} style={{textAlign: 'left'}} className={'no-printMe'}>

    <Row gutter={16} style={{flex: 1}}>
      <Col xs={16} xl={8}>
        <Card title="Dane Podstawowe" bordered={false} style={{height: 300, margin: 12}}>
          <p><span style={{fontWeight: 'bold'}}>Imię i Nazwisko:</span> <Input value={tempName ? tempName : userData?.name} onChange={onNameChange} /></p>
          <p><span style={{fontWeight: 'bold'}}>Organizacja:</span>

            <Select
              aria-required
              style={{width: '100%',}}
              placeholder="Wybierz Organizację"
              optionFilterProp="children"
              value={userData?.organization}
              onChange={(value) => {
                setUserData((obj) => {
                  if(!obj) {
                    return undefined;
                  }
                  return {...obj, organization: value}
                })
              }
                }
              options={[
                {
                  value: 'Elica Group Polska',
                  label: 'Elica Group Polska',
                },
                {
                  value: 'SLC Jelcz',
                  label: 'SLC Jelcz',
                },
                {
                  value: 'SLC Panattoni',
                  label: 'SLC Panattoni',
                },
              ]}
            />

          </p>
          <Checkbox onChange={onChangeUserBlocked} checked={userData?.blocked} value={userData?.blocked}>Użytkownik Zablokowany</Checkbox>
        </Card>
      </Col>
      <Col xl={10}>
        <Card title="Uprawnienia" bordered={false} style={{height: 300, margin: 12}}>
          {listPerm?.map((perm) =>
            <>
              <Checkbox key={perm.id} onChange={onCheckBoxChange} checked={userData?.rule?.includes(String(perm.id))} value={perm.id}>{perm.name}</Checkbox>
              <br />
            </>
          )}
        </Card>
      </Col>

      <Col xl={6}>
        <Card title={<>Token Logowania<Button onClick={printToken} type={'text'}><img src={printerIcon} style={{width: '16px', height: '16px', opacity: 0.5, textAlign: 'center'}}
                                                            alt="Drukuj..."/></Button></>} bordered={false} style={{height: 300, margin: 12, textAlign: 'left'}}>
          <br />
          <div style={{width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
            <QRCode
            style={{}}
            errorLevel="H"
            value={userData?.token as string}
            icon="/logo512.png"
          />
            <Popconfirm
              placement="left"
              title={'Generowanie Nowego Tokena'}
              description={'Czy chcesz wygenerować nowy token? Poprzedni zostanie unieważniony.?'}
              onConfirm={generateNewToken}
              okText="Tak"
              cancelText="Nie"
            >
              <Button style={{marginTop: 5}} size={'small'}>Wygeneruj Nowy</Button>
            </Popconfirm>


          </div>


        </Card>
        </Col>
      <Col xs={24} style={{display: 'flex', justifyContent: 'right'}}><Button onClick={saveUser} style={{margin: 10}} icon={<TfiSave/>}><span style={{marginLeft: 5}}>Zapisz</span></Button></Col>
    </Row>
  </Card>

    <Card style={{width: '400px'}} className={'printMeHide'} bordered={true}>
      <QRCode
        style={{display: 'block', float: 'left'}}
        errorLevel="H"
        value={userData?.token as string}
        icon="/logo512.png"
      /><div style={{display: 'block', float: 'left', paddingTop: 65, fontWeight: 'bold', marginLeft: 20, letterSpacing: 2}}>{userData?.name}</div></Card>
    {contextHolder}
  </>

}
