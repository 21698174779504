import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Popover, Progress, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { fetcher } from '../../../../../extras/fetcher';
import moment from 'moment';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import { TableCodeLocation } from './TableCodeLocation';
import { Link } from 'react-router-dom';
import { MainBreadcrumbItem } from '../../../../MainBreadcrumb/MainBreadcrumb';
import { TableHeader } from '../TableHeader';
import { TableFooter } from '../TableFooter';
import { ModalMoveMultipleLocations } from '../ModalMoveMultipleLocations';
import { SelectedMenuContext } from '../../../../../contexts/selectedMenu.context';
import { exportToExcel } from '../../../../../extras/exportToExcel';
import { myDateFormat } from '../../../../../extras/myDateFormat';
import { PermError } from '../../../NotFoundPage/PermError';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}



export const StanReg = (props: Props) => {


  const [tableData, setTableData] = useState<DataType[]>([]);
  const [rackType, setRackType] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [notVirtual, setNotVirtual] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);
  const [modalMultipleLocMoveOpened, setModalMultipleLocMoveOpened] = useState<boolean>(false);

  const { windowHeight } = useWindowDimensions();


  const selectedMenuContext = useContext(SelectedMenuContext);

  const [permError, setPermError] = useState<boolean>(false);


  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[]=[{
        href: '',
        key: 'Ikea',
        label: 'Ikea',
      },
    {
      href: '',
      key: 'Stan',
        label: 'Stan',
    },

      {
        href: '/stock/rack/',
        key: 'Lokalizacje',
        label: 'Lokalizacje',
        menu: [
          {
            href: '/stock/rack/',
            label: 'Lokalizacje',
          },
          {
            href: '/stock/rack-total/',
            label: 'Lokalizacje2',
          },
          {
            href: '/stock/material/',
            label: 'Materiał',
          },
        ]

      },
  ];


    props.handleSetMainTitle('Lokalizacje Magazynowe');
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['rack']);

  },[]);


  interface DataType {
    id: number;
    name: string;
    mag_type: string;
    address: string;
    lastInventoryDate: Date;
    procent: number;
    total: [];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Typ',
      dataIndex: 'mag_type',
      width: 80,
      filterMode: 'tree',
      filters: [
      ...(rackType.map((ikeaMagType: any) => {
          return {
            text: ikeaMagType.name,
            value: String(ikeaMagType.id),
          };
        })),
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      //@ts-ignore
      onFilter: (value: string, record) => record.mag_type.indexOf(value) === 0,

      sorter: (a, b) => Number(a.mag_type) - Number(b.mag_type),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record, index) => {
        const currentType: any = rackType.find((ikeaMagType: any) =>ikeaMagType.id === Number(record.mag_type));
        return  <Tooltip key={index} placement="right" title={currentType.desc}>{record.mag_type}</Tooltip>
      },

    },
    {
      title: 'Nazwa',
      width: 200,
      dataIndex: 'name',
      filterMode: 'tree',
      defaultSortOrder: 'descend',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => a.name.localeCompare(b.name, undefined, {
        numeric: true,
      }),

      //@ts-ignore
      onFilter: (value: string, record) => record.name.indexOf(value) === 0,
      filters: [
        {
          text: 'Lokalizacja A',
          value: 'A',
        },
        {
          text: 'Lokalizacja B',
          value: 'B',
        },
        {
          text: 'Regały',
          value: '010',
        },
      ],
      filterSearch: true,

      render: (value) => {
        return (
          <Link to={`${value}`}>{value}</Link>
        )
      },

    },
    {
      title: 'Ilość Kodów',
      width: 150,
      sortDirections: ['descend', 'ascend'],
      //@ts-ignore
      sorter: (a, b) => Number(a.total.length) - Number(b.total.length),
      render: (value, record, index) => {

        return (

            <Popover
              key={`pop${index}`}
              mouseLeaveDelay={0.5}
              placement="leftBottom"
              content={
              <TableCodeLocation key={index} data={record.total} />
              }>
                <Button type={'text'}>{record.total.length}</Button>
            </Popover>
        )
      }
    },

    {
      title: 'Wypełnienie',
      width: 180,
      dataIndex: 'procent',
      sortDirections: ['descend', 'ascend'],
      render: (value, record, index) => <div style={{borderWidth: 1, borderColor: 'red', textAlign: 'center', display: 'flex'}} key={index}><Progress percent={record.procent} showInfo={false} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} /><div style={{display: 'inline', width: '50px'}}>{record.procent}%</div></div>,
      //@ts-ignore
      sorter: (a, b) => Number(a.procent) - Number(b.procent),
    },
    {
      title: 'Ostatnia Inwentaryzacja',
      dataIndex: 'lastInventoryDateFormatted',
      sortDirections: ['descend', 'ascend'],
      width: 200,
      sorter: (a, b) => {
        const dataA = a.lastInventoryDate === null ? 0 : moment(a.lastInventoryDate).unix();
        const dataB = b.lastInventoryDate === null ? 0 : moment(b.lastInventoryDate).unix();
        return dataA - dataB;
      }
    },

  ];


  const getData = async () => {
    await setLoading(true);

    const data = notVirtual ? await fetcher('ikea/all-racks') : await fetcher('ikea/all-racks/v');

    if (data.status === 403) {
      setLoading(false);
      setPermError(true);
      return;
    }


    const dataRackType= await fetcher('ikea/rack-type');

    await setRackType(dataRackType.result);


    const myData = await data.result.map((record: any) => {
      return {
        ...record,
        mag_type: String(record.mag_type),
        procent: Math.round(record.totalSaturation*100),
        lastInventoryDateFormatted: record.lastInventoryDate !== null ? moment(record.lastInventoryDate).format('DD/MM/YYYY HH:mm:ss') : '',
        total: record.total,
      }
    });



    setTableData(myData);
    setTableSelected([]);
    setSelectedRowKeys([]);

    await setLoading(false);

  }


  useEffect(() => {
    getData();
  },[notVirtual]);


/*  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
   /// console.log('params', pagination, filters, sorter, extra);
  };*/

  const handleExcelClick = (data: DataType[]) => {
    const dataToExport = data.map((data: DataType,index) => {

      return {
        id: index+1,
        typ: data.mag_type,
        lokalizacja: data.name,
        ilosc_kodow: data.total.length,
        wypelnienie: data.procent,
        ostatnia_inwentaryzacja: myDateFormat(data.lastInventoryDate),
     }
    })
    exportToExcel(dataToExport);
  };

  const handleSetModalMultiplePalletsOpened = (opened: boolean) => {
    setModalMultipleLocMoveOpened(opened);
  }

  const handleMovePallet = () => {
    handleSetModalMultiplePalletsOpened(true);
  }




  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };


  const CheckBoxNotVirtual: JSX.Element = <Checkbox checked={notVirtual} onChange={(e) => setNotVirtual(e.target.checked)}>Nie wyświetlaj wirtualnych lokalizacji</Checkbox>


  if(permError) {
    return <PermError/>
  }

  return (
    <>
    <Table
      rowKey={'id'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={() => <TableHeader getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} handleMovePalletClick={handleMovePallet} additional={CheckBoxNotVirtual} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight*.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter handleMovePalletClick={handleMovePallet} selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />

      <ModalMoveMultipleLocations open={modalMultipleLocMoveOpened} racksId={tableSelected.map(item => item.id )} handleSetModalOpened={handleSetModalMultiplePalletsOpened} handleGetData={getData} />
    </>
      );
}
