import React, { useContext, useEffect, useState } from 'react';
import { Button, message, Upload, UploadProps } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { API_URL } from '../../../../config';
import { fetcher } from '../../../../extras/fetcher';
import { ModalTableEcis } from './ModalTableEcis';
import { EcisDataInterface } from '../../../../types/ecisData.interface';
import { checkRule } from '../../../../extras/checkRule';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';


interface Props {
  deliveryNr: number;
  tripNr: number;
}

export const Ecis = (props: Props) => {

  const [ecisData, setEcisData] = useState<EcisDataInterface | null>();
  const [ecisNotOk, setEcisNotOk] = useState<boolean>(false);

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);


  const getData = async () => {
    await fetcher(`ikea/trip/${props.tripNr}/${props.deliveryNr}/ecis/check`).then((data) => {
      setEcisNotOk(false);
      setEcisData(data.result);
      data?.result.details?.forEach((res: any) => res.pltEcis !== res.pltSap ? setEcisNotOk(true) : null);
    });

  };

  useEffect(() => {

    (async () => {
        await getData();
    })();

  },[]);

  const uploadProps: UploadProps = {
    name: 'file',
    action: `${API_URL}ikea/trip/${props.tripNr}/${props.deliveryNr}/ecis`,
    withCredentials: true,
    headers: {
      mode: 'cors',
      method: 'POST',
    },
    beforeUpload: (file) => {
      const isXML = file.type === 'text/xml';
      if (!isXML) {
        message.error(`${file.name} nie jest plikiem ECIS!`);
      }

      const sizeOk = file.size <= 380000;

      if (!sizeOk) {
        message.error(`${file.name} jest za duży!`);
      }

      return (isXML && sizeOk) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      //  console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        getData();
        if(info.file.response.error) {
          return message.error(info.file.response.error);
        }

        message.success(`${info.file.name} ECIS został dodany!`);
      } else if (info.file.status === 'error') {
        message.error(`Błąd przy uploadzie pliku: ${info.file.name}.`);
      }
    },
    showUploadList: false,
  };


  return (
  <>
    <Upload {...uploadProps} accept={'text/xml'} disabled={!checkRule('a2',loggedIn?.user?.rule!)}>
      <Button icon={<UploadOutlined />} disabled={!checkRule('a2',loggedIn?.user?.rule!)} type={'text'}></Button>
    </Upload>
    { ecisData?.ECIS && <><a href={`${API_URL}ikea/trip/${props.tripNr}/${props.deliveryNr}/ecis`}><Button icon={<DownloadOutlined />} type={'text'}></Button></a>
    <Button type={'text'} onClick={() => {
      console.log({data: ecisData});
      setModalOpened(true);
    }}><span style={{color: ecisNotOk ? 'red' : 'green'}}>{ecisData?.ECIS}</span></Button></>}

    { ecisData && <ModalTableEcis open={modalOpened} handleSetModalOpened={setModalOpened} ecisData={ecisData}/>}
  </>
  );
}
