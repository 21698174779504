import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PersonLockerInterface } from '../../../types/personLocker.interface';
import { EmptyLockerConfirm } from './EmptyLockerConfirm';
import { EditOutlined } from '@ant-design/icons';
import { ModalEditLockerComment } from './ModalEditLockerComment';

interface Props {
 data : PersonLockerInterface[],
  getData: () => void,
}


export const TableLockers = (props: Props) => {

  const [selectedLocker, setSelectedLocker] = useState<string | null>(null);
  const [editCommentsModalOpened, setEditCommentsModalOpened] = useState<boolean>(false);

  const columns : ColumnsType<PersonLockerInterface>= [

    {
      width: 130,
      title: 'Nazwa',
      dataIndex: 'name',
      key: 'name',

    },

    {
      width: 130,
      title: 'Typ',
      dataIndex: ['personLockerType','name'],
      key: 'personLockerType',
      //filterDropdown,
    },

    {
      width: 180,
      title: 'Komentarz',
      dataIndex: 'comments',
      key: 'comments',
      render: (value, record, index) => <div key={index}>{value}<Button style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'} onClick={() => {
        setSelectedLocker(record.id);
        setEditCommentsModalOpened(true);
      }
      }><EditOutlined style={{fontSize: 12}}/></Button></div>
    },
    {
      width: 50,
      title: '',
      dataIndex: '',
      key: 'functions',
      render: (value, record, index) => <EmptyLockerConfirm getData={props.getData} lockerId={record.id} />,
    },
  ];

  useEffect(() => {

  },[props.data]);


  return (<>
    <Table columns={columns} pagination={{pageSizeOptions: [5, 10], defaultPageSize: 5, hideOnSinglePage: true}}
           showHeader={true} bordered={false} dataSource={props.data} style={{opacity: 0.9}} size={'small'}/>
      { selectedLocker && <ModalEditLockerComment open={editCommentsModalOpened} handleSetModalOpened={setEditCommentsModalOpened}
                               handleGetData={props.getData} lockerId={selectedLocker}/>}
    </>
  )

}

