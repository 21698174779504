import React, { useEffect, useState } from 'react';
import { Card, Modal, Spin, Switch } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Cookies from 'universal-cookie';
import { fetcher } from '../../../../extras/fetcher';
import { PermError } from '../../NotFoundPage/PermError';
import { myNumberSorter } from '../../../../extras/sorters';

interface Props {
  day: string;
  name: string;
  title? : string;
  type: 'reg' | 'storage';
}
export const OneDayChart = (props: Props) => {

  const cookies = new Cookies();

  const [dataChart1, setDataChart1] = useState<any[]>([]);
  const [dataChart1User, setDataChart1User] = useState<string[]>([]);

  const [chartColors] = useState(['#5499C7', '#AF7AC5', '#52BE80', '#d58b38', '#F5B041']);

  const [dataChart1notUser, setDataChart1notUser] = useState(cookies.get(`${props.name}_notUser`) !== '0');
  const [permError, setPermError] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);


  const switchHandler = (bool: boolean) => {
    setDataChart1notUser(!bool);
    cookies.set(`${props.name}_notUser`,bool ? '0' : '1');
  };

  useEffect(() => {
    getData();
  },[props.day]);


  const getData = async () => {

    setLoading(true);

    await fetcher(`ikea/stats/${props.type}/date/${props.day}`)
      .then((res) => {

        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }

        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        function convertHour(hour: number) : string {
          let h = String(hour);
          if (hour < 10) {
            h = '0' + String(hour);
          }
          return h;
        }

        const dateChartFormat = res.result.reduce((acc: any, curr: any) => {

          const ix = acc.length > 0 ? acc.findIndex((x: any) => x.hour === curr.hour) : -1;

          if(ix === -1) {
            return [
              ...acc,
              {
                hour: convertHour(curr.hour),
                [convertHour(curr.hour)]: curr.count,
                [curr.name]: curr.count,
                'Łącznie': curr.count,
              },
            ];
          } else {

            const arr = [...acc];
            arr[ix] = {
              ...acc[ix],
              [curr.name]: curr.count,
              [convertHour(curr.hour)]: curr.count,
              'Łącznie': acc[ix]['Łącznie'] + curr.count,
            };
            return arr;
          }

        },[]);

        const allHours = ['00','01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

        allHours.map((h) => {
          const exist = dateChartFormat.findIndex((x: any) => x.hour === h);

          if(exist === -1) {
            dateChartFormat.push({
              [h]: 0,
              'Łącznie': 0,
              hour: h,
            });
          }

        });


        const chart1User = Array.from(new Set(res.result.map((x: {name: string}) => x.name)));

        setDataChart1User([...chart1User as string[], 'Łącznie']);
        console.log(dateChartFormat.sort((a: any,b : any) => myNumberSorter(a.hour, b.hour)));
        console.log(chart1User);

        setDataChart1(dateChartFormat);
        setLoading(false);

      });

  }


  return (
    permError ? <PermError/> : <Card title={

      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{width: 200}}></div>
        <div style={{width: 200}}>{props.title ? props.title : `Wykres: ${props.day}`}</div>
        <div style={{fontSize: 'smaller', justifyContent: 'flex-end', display: 'flex', width: 200}}><div style={{marginTop: 3, marginRight: 10, float: 'right'}}>Użytkownicy: <Switch size={'small'} style={{marginTop: -2, backgroundColor: !dataChart1notUser ? '#ef343a' : undefined}} checked={!dataChart1notUser} onChange={switchHandler} /></div></div>
      </div>

    } size={'small'} style={{height: 350}}>
      <Spin spinning={loading}>
      <ResponsiveContainer width={'99%'} height={300}>
        <BarChart
          width={500}
          height={350}
          data={dataChart1}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey={"hour"} />
          <YAxis />
          <Tooltip/>

          <Legend/>
          {
            dataChart1User.map((item,index) => (
              !dataChart1notUser ? <Bar key={item} dataKey={item} fill={item !== 'Łącznie' ? chartColors[index] : '#ef343a'} /> :
                item==='Łącznie' ? <Bar key={item} dataKey={item} fill={'#ef343a'} /> : null
            ))
          }


        </BarChart>
      </ResponsiveContainer>
      </Spin>
    </Card>
  )
}
