import React, { useEffect, useState } from 'react';
import { Badge, Button, message, Modal, Popover, Table } from 'antd';
import { PermError } from '../NotFoundPage/PermError';
import { ColumnsType } from 'antd/es/table';
import { PersonEmployeeInterface } from '../../../types/personEmployee.interface';
import { myNumberSorter, myStringSorter } from '../../../extras/sorters';
import { TableLockers } from './TableLockers';
import { fetcher } from '../../../extras/fetcher';

interface Props {
  open: boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;

  lockerId: string;

}

export const ModalAddEmployeeToLocker = (props: Props) => {

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [permError, setPermError] = useState<boolean>(false);

  const [tableSelected, setTableSelected] = useState<PersonEmployeeInterface[]>([]);
  const [tableData, setTableData] = useState<PersonEmployeeInterface[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


  interface PersonEmployeeTableInterface extends PersonEmployeeInterface {
    personLockerCount: number;
  }

  const getData = async () => {
    await fetcher(`person/lockers/${props.lockerId}/find-employee`)
      .then(res => {
        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }


        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        setTableData(res.result.map((x: PersonEmployeeTableInterface) => {
          return {
            ...x,
            personLockerCount: x.personLocker ? x.personLocker.length : 0,
          }
        }));

        setTableSelected([]);
        setSelectedRowKeys([]);

        setLoading(false);
      });
  }
  useEffect(() => {
    setLoading(true);
    getData().then(r => setLoading(false));
  }, [props.lockerId]);

  const columns : ColumnsType<PersonEmployeeInterface>= [

    {
      width: 180,
      title: 'Imię i Nazwisko',
      dataIndex: 'name',
      key: 'name',
      sorter: (a,b) => myStringSorter(a.name, b.name),
      //filterDropdown,
    },
    {
      width: 120,
      title: 'Szafki',
      dataIndex: 'personLockerCount',
      key: 'personLockerCount',
      sorter: (a,b) => myNumberSorter(a.personLocker.length, b.personLocker.length),

      onFilter: (value, record) => (record.personLocker ? record.personLocker.length : '0') === value,
      filters: [
        ...Array.from(new Set(tableData.map(row => row.personLocker ? row.personLocker.length : '0'))).map((value) => {
          return {
            text: String(value),
            value: value,
          }
        }),
      ],

      render: (value, record, index) =>record.personLocker && record.personLocker.length > 0
        ?
        <Popover
          key={`pop${index}`}
          mouseLeaveDelay={0.5}
          placement="leftBottom"
          content={
            <TableLockers getData={getData} key={index} data={record.personLocker}/>
          }>
          <Button type={'text'}>{record.personLocker.length}</Button>
        </Popover> : <Badge key={index} count="BRAK" style={{ backgroundColor: '#e73137' }}/>
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: PersonEmployeeInterface[]) => {
      //  console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      console.log(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  const handleCancel = async () => {
    props.handleSetModalOpened(false);
  };

  const handleOk = async () => {
    setMoveLoading(true);
    if (selectedRowKeys.length === 0) {
      messageApi.warning('Nie wybrano pracownika...');
      return;
    }

    const addEmployees = await fetcher('person/lockers/employee', 'PUT', {
      lockerId: String(props.lockerId),
      employeeId: String(selectedRowKeys[0]),
    });

    if (addEmployees.status === 403) {
      setLoading(false);
      setPermError(true);
      setMoveLoading(false);
      return;
    }

    if (addEmployees.result.error) {
      messageApi.error(addEmployees.result.error);
      setLoading(false);
      setMoveLoading(false);
      return;
    }

    if (addEmployees.result.success) {
      message.success(`Szafka została przydzielona do pracownika!`);
      props.handleSetModalOpened(false);
    } else {
      message.error('Nieznany błąd...');
    }


    props.handleGetData();

    setLoading(false);
    setMoveLoading(false);



  }

  return (

    <Modal bodyStyle={{padding: 10}} title={'Dodawanie Pracownika do szafki'} open={props.open} onOk={handleOk}
           onCancel={handleCancel} confirmLoading={moveLoading}>
      {permError ? <PermError/> : <>
        <Table columns={columns}
               pagination={{pageSize: 10}}
               loading={loading}
               showHeader={true}
               bordered={true}
               rowKey={'id'}
               rowSelection={{
                 selectedRowKeys,
                 type: 'radio',
                 ...rowSelection,
               }}
               dataSource={tableData}
               style={{opacity: 0.9}}
               size={'small'}

        />
        {contextHolder}
      </>}
    </Modal>

  )

}
