import React, { useState } from 'react';
import { message, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { fetcher } from '../../../../extras/fetcher';


interface Props {
  open : boolean;
  navId: number;
  deliveryId: number;
  handleSetModalOpened: (opened: boolean) => void;
  getData: () => void;
}
export const ModalAddSapPositions = (props: Props) => {

  const [messageApi, contextHolder] = message.useMessage();
  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');



  const handleOk = async () => {
    setMoveLoading(true);
    let invalidFormat = false;

    const data = inputValue.split('\n').map(x => {
      const splitData = x.split('\t');

      if(!splitData[2])
      {
        invalidFormat = true;
        setMoveLoading(false);
        return;
      }


      return {
        position: splitData[0],
        code: splitData[1],
        count: Number(splitData[2].split(',')[0]),
      };
    });

    if(invalidFormat) {
      return messageApi.open({
        type: 'error',
        content: 'Niewłaściwy format!',
      });
    }


    const res = await fetcher(`nav/list/${props.navId}/delivery/${props.deliveryId}`,'PUT', data);

    if(res.status === 403) {
      messageApi.open({
        type: 'error',
        content: 'Nie posiadasz wymaganych uprawnień!',
      });
      setMoveLoading(false);
      return;
    }

    if (res.result.success) {
      messageApi.open({
        type: 'success',
        content: `Pozycje zostały dodane`,
      });

      props.handleSetModalOpened(false);
      await props.getData();
    } else {
      messageApi.open({
        type: 'error',
        content: res.result.error ? res.result.error : `Nieznany błąd..`,
      });
    }

    setMoveLoading(false);

  }

  const handleCancel = async () => {
    props.handleSetModalOpened(false);
  }

  return <Modal bodyStyle={{padding: 10,}} title={`Dodaj Pozycję SAP`} open={props.open} onOk={handleOk} onCancel={handleCancel} confirmLoading={moveLoading}>
    <>

      <div style={{ display: 'flex', alignItems: 'center', alignContent: 'flex-end', justifyContent: 'space-evenly', padding: 5 }}>
        <TextArea rows={15} value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
      </div>

      {contextHolder}
    </>
  </Modal>
}
