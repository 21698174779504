import React, { useState } from 'react';
import { Button, message, Popconfirm, PopconfirmProps } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { fetcher } from '../../../extras/fetcher';

interface Props {
  getData : () => void;
  lockerId: string;
}
export const EmptyLockerConfirm = ({getData, lockerId}: Props) => {

  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState<boolean>(false);
  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const confirm = async () => {
    setMoveLoading(true);
    const remove = await fetcher('person/lockers/employee','DELETE', { lockerId });

    if (remove.status === 403) {
      messageApi.error('Brak wymaganych uprawnień!');
    }

    if (remove.result.error) {
      messageApi.error(remove.result.error);
    }

    if (remove.result.success) {
      messageApi.success('Opróżniono szafkę!');
      getData();
    } else {
      messageApi.error('Nieznany błąd!');
    }

    setOpen(false);
    setMoveLoading(false);
  }

  const cancel = () => {
    setOpen(false);
  }

  const popConfirmProps: PopconfirmProps = {
    placement: 'bottom',
    title: 'Usuwanie',
    description: 'Czy na pewno chcesz usunąć pracownika z szafki?',
    onConfirm: (e) => confirm(),
    okText:'Tak',
    cancelText:'Nie',
    open,
    onCancel: () => cancel(),
    okButtonProps: {
      loading: moveLoading
    }
  };

  return (<>
      <Popconfirm {...popConfirmProps}>
        <Button onClick={() =>setOpen(true)} style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'}><UserDeleteOutlined style={{fontSize: 12}} color={'green'} /></Button>
      </Popconfirm>
    {contextHolder}
  </>

  );
};
