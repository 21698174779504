import React, { useEffect, useState } from 'react';
import { message, Modal, Table } from 'antd';
import { PermError } from '../NotFoundPage/PermError';
import { ColumnsType } from 'antd/es/table';
import { myStringSorter } from '../../../extras/sorters';
import { fetcher } from '../../../extras/fetcher';
import { PersonLockerInterface } from '../../../types/personLocker.interface';

interface Props {
  open: boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;

  employeeId: string;

}

export const ModalAddLockerToEmployee = (props: Props) => {

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [permError, setPermError] = useState<boolean>(false);

  const [tableSelected, setTableSelected] = useState<PersonLockerInterface[]>([]);
  const [tableData, setTableData] = useState<PersonLockerInterface[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


  const getData = async () => {
    await fetcher(`person/employees/${props.employeeId}/find-empty-lockers`)
      .then(res => {
        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }


        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        setTableData(res.result);

        setTableSelected([]);
        setSelectedRowKeys([]);

        setLoading(false);
      });
  }
  useEffect(() => {
    setLoading(true);
    getData().then(r => setLoading(false));
  }, [props.employeeId]);

  const columns : ColumnsType<PersonLockerInterface>= [

    {
      width: 150,
      title: 'Nazwa',
      dataIndex: 'name',
      key: 'name',
      sorter: (a,b) => myStringSorter(a.name, b.name),
      //filterDropdown,
    },
    {
      width: 120,
      title: 'Typ',
      dataIndex: ['personLockerType', 'name'],
      key: 'typ',
      sorter: (a,b) => myStringSorter(a.personLockerType.name, b.personLockerType.name),

      onFilter: (value, record) => (record.personLockerType ? record.personLockerType.name : '') === value,
      filters: [
        ...Array.from(new Set(tableData.map(row => row.personLockerType ? row.personLockerType.name : ''))).map((value) => {
          return {
            text: String(value),
            value: value,
          }
        }),
      ],
    },
    {
      width: 300,
      title: 'Komentarz',
      dataIndex: 'comments',
      key: 'comments',
      sorter: (a,b) => myStringSorter(a.comments, b.comments),
      //filterDropdown,
    }
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: PersonLockerInterface[]) => {
      //  console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      console.log(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  const handleCancel = async () => {
    props.handleSetModalOpened(false);
  };

  const handleOk = async () => {
    setMoveLoading(true);
    if (selectedRowKeys.length === 0) {
      messageApi.warning('Nie wybrano pracownika...');
      return;
    }

    const addEmployees = await fetcher('person/lockers/employee', 'PUT', {
      lockerId: String(selectedRowKeys[0]),
      employeeId: String(props.employeeId),
    });

    if (addEmployees.status === 403) {
      setLoading(false);
      setPermError(true);
      setMoveLoading(false);
      return;
    }

    if (addEmployees.result.error) {
      messageApi.error(addEmployees.result.error);
      setLoading(false);
      setMoveLoading(false);
      return;
    }

    if (addEmployees.result.success) {
      message.success(`Szafka została przydzielona do pracownika!`);
      props.handleSetModalOpened(false);
    } else {
      message.error('Nieznany błąd...');
    }


    props.handleGetData();

    setLoading(false);
    setMoveLoading(false);



  }

  return (

    <Modal bodyStyle={{padding: 10}} title={'Dodawanie szafki do pracownika'} open={props.open} onOk={handleOk}
           onCancel={handleCancel} confirmLoading={moveLoading} width={'45%'}>
      {permError ? <PermError/> : <>
        <Table columns={columns}
               pagination={{pageSize: 10}}
               loading={loading}
               showHeader={true}
               bordered={true}
               rowKey={'id'}
               rowSelection={{
                 selectedRowKeys,
                 type: 'radio',
                 ...rowSelection,
               }}
               dataSource={tableData}
               style={{opacity: 0.9}}
               size={'small'}

        />
        {contextHolder}
      </>}
    </Modal>

  )

}
