import { TbTransform } from 'react-icons/tb';
import { Button, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { fetcher } from '../../../../extras/fetcher';
import { NavGetTransferUdcInterface } from '../../../../types/navGetTransferUdc.interface';

interface Props {
  navId: number;
  udc: number;
  getData: () => void;
}

export const ButtonTransferNav = (props: Props) => {

  const [data, setData] = useState<NavGetTransferUdcInterface>();
  const [showPop, setShowPop] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();


  const getData = async () => {
    const res = await fetcher(`nav/list/${props.navId}/transfer-udc/${props.udc}`);
    setData(res.result);

  }


  useEffect(() => {

    (async () => {
      await getData();
    })();

  },[props.udc, props.navId]);


  const handleOk = async () => {
  setConfirmLoading(true);

  const transfer = await fetcher(`nav/list/${props.navId}/transfer-udc/${props.udc}`, 'PATCH');

  if (transfer.result.success) {
    messageApi.success('Paleta została przeniesiona!');
  } else if(transfer.result.error) {
    messageApi.error(transfer.result.error);
  } else {
    messageApi.error('Nieznany błąd!');
  }
  setConfirmLoading(false);
  props.getData();
  }



  return     <><Popconfirm
    title="Transfer Palety"
    description={`Czy na pewno chcesz transferować paletę z ${data?.from} do ${data?.to}`}
    open={showPop}
    okText={'TAK'}
    cancelText={'NIE'}
    onConfirm={handleOk}
    okButtonProps={{ loading: confirmLoading }}
    onCancel={() => setShowPop(false)}
  >
    <Button onClick={() => setShowPop(true)} icon={<TbTransform style={{fontSize: '12px'}}/>} style={{display: 'block', textAlign: 'center', marginTop: '5px'}} size={'small'}>{data?.title}</Button>
  </Popconfirm>
    {contextHolder}
    </>
}
