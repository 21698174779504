import React, { useEffect, useState } from 'react';
import { Input, message, Modal, Radio, RadioChangeEvent, Space } from 'antd';
import { fetcher } from '../../../extras/fetcher';
import { PersonBranchInterface } from '../../../types/personBranch.interface';
import { PermError } from '../NotFoundPage/PermError';
import TextArea from 'antd/es/input/TextArea';
import { PersonAgreementInterface } from '../../../types/personAgreement.interface';


interface Props {
  open: boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}

export const ModalAddEmployee = (props: Props) => {
  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [allBranches, setAllBranches] = useState<PersonBranchInterface[]>([]);
  const [allAgreements, setAllAgreements] = useState<PersonAgreementInterface[]>([]);

  const [permError, setPermError] = useState<boolean>(false);

  const [obj, setObj] = useState<{
    id?: string;
    name: string;
    comments: string;
    personBranchId: string | null,
    personAgreementId: string | null,
  }>({
    name: '',
    comments: '',
    personBranchId: null,
    personAgreementId: null,
  });

  const getData = async () => {
    const getBranches = await fetcher('person/all-branches');
    const getAgreements = await fetcher('person/all-agreements');
    setAllBranches(getBranches.result);
    setAllAgreements(getAgreements.result);
  };

  useEffect(() => {
    getData().then(r => setLoading(false));
  }, []);

  const handleOk = async () => {

    if(obj.name === '') {
      messageApi.warning('Nie podano nazwy...');
      return;
    }

    if (!obj.personBranchId) {
      messageApi.warning('Nie wybrano oddziału...');
      return;
    }

    setMoveLoading(true);
    const sendToApi = await fetcher('person/employees', 'POST', obj);

    if (sendToApi.status === 403) {
      setLoading(false);
      setPermError(true);
      setMoveLoading(false);
      return;
    }

    if (sendToApi.result.error) {
      messageApi.error(sendToApi.result.error);
      setLoading(false);
      setMoveLoading(false);
      return;
    }

    if (sendToApi.result.success) {
      message.success(`Pracownik ${obj.name} został dodany!`);
      props.handleSetModalOpened(false);
      setObj({
        name: '',
        comments: '',
        personBranchId: null,
        personAgreementId: null,
      });

    } else {
      message.error('Nieznany błąd...');
    }


    props.handleGetData();

    setLoading(false);
    setMoveLoading(false);
  };

  const handleCancel = async () => {
    props.handleSetModalOpened(false);
    setObj({
      name: '',
      comments: '',
      personBranchId: null,
      personAgreementId: null,
    });

  };

  const onChangeTypeRadio = (e: RadioChangeEvent) => {
    setObj((obj) => {
      return {
        ...obj,
        personAgreementId: e.target.value,
      };
    });
  };

  const onChangeBranchRadio = (e: RadioChangeEvent) => {
    setObj((obj) => {
      return {
        ...obj,
        personBranchId: e.target.value,
      };
    });
  };

  const onChangeTextArea = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setObj((obj) => {
      return {
        ...obj,
        comments: e.target.value,
      };
    });
  }

  return (
    <Modal bodyStyle={{padding: 10}} title={'Dodawanie Pracownika'} open={props.open} onOk={handleOk}
           onCancel={handleCancel} confirmLoading={moveLoading}>
      {permError ? <PermError/> : <>
        <h4>Imię i Nazwisko</h4>
        <Input value={obj.name} onPressEnter={handleOk} onChange={(e) => setObj((obj) => {
          return {...obj, name: e.target.value};
        })} style={{width: '100%'}} placeholder={'Imię i Nazwisko Pracownika'}/>
        <br/>
        <br/>
        <h4>Rodzaj Umowy</h4>

        <Radio.Group onChange={onChangeTypeRadio} value={obj.personAgreementId}>
          <Space direction="vertical">
            {
              allAgreements.map(agr => <Radio value={agr.id}>{agr.name}</Radio>)
            }
          </Space>
        </Radio.Group>
        <br/>
        <br/>

        <h4>Oddział</h4>

        <Radio.Group onChange={onChangeBranchRadio} value={obj.personBranchId}>
          <Space direction="vertical">
            {
              allBranches.map(branch => <Radio value={branch.id}>{branch.name}</Radio>)
            }
          </Space>
        </Radio.Group>
        <br/>
        <br/>

        <h4>Komentarz</h4>
        <TextArea value={obj.comments} rows={4} onChange={onChangeTextArea}/>

        {contextHolder}
      </>}
    </Modal>
  );
};
