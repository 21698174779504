import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';
import React, { useContext, useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Link, useParams } from 'react-router-dom';
import { SelectedMenuContext } from '../../../contexts/selectedMenu.context';
import { ColumnsType } from 'antd/es/table';
import { myNumberSorter, myStringSorter } from '../../../extras/sorters';
import { fetcher } from '../../../extras/fetcher';
import { Badge, Button, Modal, Popover, Table, Tooltip } from 'antd';
import { TableHeader } from '../Ikea/Stan/TableHeader';
import { TableFooter } from '../Ikea/Stan/TableFooter';
import { PermError } from '../NotFoundPage/PermError';
import { exportToExcel } from '../../../extras/exportToExcel';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { PersonEmployeeInterface } from '../../../types/personEmployee.interface';
import { ModalAddEmployee } from './ModalAddEmployee';
import { TableLockers } from './TableLockers';
import { ModalAddLockerToEmployee } from './ModalAddLockerToEmployee';
import { ModalEditEmployeeComments } from './ModalEditEmployeeComments';
import { DeleteEmployeeConfirm } from './DeleteEmployeeConfirm';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}


export const Employees = (props: Props) => {


  const [loading, setLoading] = useState(false);
  const { windowHeight } = useWindowDimensions();
  const [tableSelected, setTableSelected] = useState<PersonEmployeeInterface[]>([]);
  const [tableData, setTableData] = useState<PersonEmployeeInterface[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [addModalOpened, setAddModalOpened] = useState<boolean>(false);

  const [permError, setPermError] = useState<boolean>(false);

  const [selectedEmployee, setSelectedEmployee] = useState<string>();
  const [addLockerOpened, setAddLockerOpened] = useState<boolean>(false);
  const [editCommentsModalOpened, setEditCommentsModalOpened] = useState<boolean>(false);

  const linkParams =  useParams();

  const selectedMenuContext = useContext(SelectedMenuContext);


  interface PersonEmployeeTableInterface extends PersonEmployeeInterface {
    personLockerCount: number;
  }

  const getData = async () => {
    await fetcher(`person/employees/`)
      .then(res => {
        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }


        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        setTableData(res.result.map((x: PersonEmployeeTableInterface) => {
          return {
            ...x,
            personLockerCount: x.personLocker ? x.personLocker.length : 0,
          }
        }));

        setTableSelected([]);
        setSelectedRowKeys([]);

        setLoading(false);
      });
  }

  useEffect(() => {

    setLoading(true);
    getData();
    props.handleSetMainTitle(`Pracownicy`);
    selectedMenuContext?.setSelectedKey(['hr_employees']);

    const breadcrumbItems: MainBreadcrumbItem[] =[{
      href: '',
      key: 'hr',
      label: 'Zarządzanie HR',
    },
      {
        href: '/person/employees/',
        key: 'hr_employees',
        label: 'Pracownicy',
      },

    ];

    props.setBreadcrumbHandler(breadcrumbItems);

  },[]);



  const columns : ColumnsType<PersonEmployeeInterface>= [

    {
      width: 180,
      title: 'Imię i Nazwisko',
      dataIndex: 'name',
      key: 'name',
      sorter: (a,b) => myStringSorter(a.name, b.name),

      render: (value, record, index) => <Link key={index} to={`/person/employees/${record.id}`}>{value}</Link>
      //filterDropdown,
    },
    {
      width: 130,
      title: 'Oddział',
      dataIndex: ['personBranch','name'],
      key: 'personBranch',
      sorter: (a,b) => myStringSorter(a.personBranch.name, b.personBranch.name),

      onFilter: (value, record) => (record.personBranch ? record.personBranch.name : '').toLowerCase().includes(String(value).toLowerCase()),
      filters: [
        ...Array.from(new Set(tableData.map(row => row.personBranch ? row.personBranch.name : ''))).map((value) => {
          return {
            text: value,
            value: value,
          }
        }),
      ],
      filterSearch: true,

    },
    {
      width: 200,
      title: 'Umowa',
      dataIndex: ['personAgreement','name'],
      key: 'personAgreement',
      sorter: (a,b) => myStringSorter(a.personAgreement.name, b.personAgreement.name),

      onFilter: (value, record) => (record.personAgreement ? record.personAgreement.name : '').toLowerCase().includes(String(value).toLowerCase()),
      filters: [
        ...Array.from(new Set(tableData.map(row => row.personAgreement ? row.personAgreement.name : ''))).map((value) => {
          return {
            text: value,
            value: value,
          }
        }),
      ],
      filterSearch: true,
      //filterDropdown,
    },
    {
      width: 120,
      title: 'Szafki',
      dataIndex: 'personLockerCount',
      key: 'personLockerCount',
      sorter: (a,b) => myNumberSorter(a.personLocker.length, b.personLocker.length),

      onFilter: (value, record) => (record.personLocker ? record.personLocker.length : '0') === value,
      filters: [
        ...Array.from(new Set(tableData.map(row => row.personLocker ? row.personLocker.length : '0'))).map((value) => {
          return {
            text: String(value),
            value: value,
          }
        }),
      ],

      render: (value, record, index) => {
        return <> {record.personLocker && record.personLocker.length > 0
          ?
          <Popover
            key={`pop${index}`}
            mouseLeaveDelay={0.5}
            placement="leftBottom"
            content={
              <TableLockers getData={getData} key={index} data={record.personLocker}/>
            }>
            <Button type={'text'}>{record.personLocker.length}</Button>
          </Popover> : <Badge key={index} count="BRAK" style={{backgroundColor: '#e73137'}}/>}

          <Button onClick={() => {
            setSelectedEmployee(record.id);
            setAddLockerOpened(true);

          }} style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'}><PlusOutlined style={{fontSize: 12}} /></Button>

          </>

      }
    },

    {
      width: 250,
      title: 'Komentarz',
      dataIndex: 'comments',
      key: 'comments',

      sorter: (a, b) => myStringSorter(a.comments, b.comments),

      render: (value, record, index) => <div key={index}>{value}<Button style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'} onClick={() => {
        setSelectedEmployee(record.id);
        setEditCommentsModalOpened(true);
      }
      }><EditOutlined style={{fontSize: 12}}/></Button></div>,
    },

    {
      width: 50,
      title: '',
      key: 'functions',

      render: (value, record, index) => <DeleteEmployeeConfirm key={index} employeeId={record.id} getData={getData} />,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: PersonEmployeeInterface[]) => {
      //  console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  if(permError) {
    return <PermError/>
  }

  const handleExcelClick = (data: PersonEmployeeInterface[]) => {
    const dataToExport = (data as PersonEmployeeTableInterface[]).map((data: PersonEmployeeTableInterface,index) => {

      return {
        id: index+1,
        'Imię i Nazwisko': data.name,
        'Oddział': data.personBranch.name,
        'Umowa': data.personAgreement.name,
        'Szafki': data.personLockerCount,
        'Komentarz': data.comments,
      }
    })
    exportToExcel(dataToExport);
  };

  const AddEmployeeButton: JSX.Element = <Tooltip title={'Dodaj...'}>
    <Button type={'text'} onClick={() => setAddModalOpened(true)}><PlusOutlined style={{color: '#57b3ec'}} /></Button>
  </Tooltip>


  return <>

  <Table columns={columns}
  scroll={{ y: windowHeight*.6}}
  pagination={false}
  loading={loading}
  showHeader={true}
  bordered={true}
  rowKey={'id'}



  title={() => <TableHeader prefixAdditional={AddEmployeeButton} tableDataToPrint={{data: tableSelected.length === 0 ? tableData : tableSelected, allColumns: columns.map((col) => {
    return {
      ...col,
    }
    }), selectedColumns: ['Imię i Nazwisko','Oddział','Umowa', 'Szafki', 'Komentarz']}} getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} selectedCount={tableSelected.length}/>}

  footer={() => <TableFooter selectedCount={tableSelected.length} totalCount={tableData.length}/> }
  rowSelection={{
    selectedRowKeys,
      type: 'checkbox',
  ...rowSelection,
  }}
  dataSource={tableData}
  style={{opacity: 0.9}}
  size={'small'}

  />

    <ModalAddEmployee open={addModalOpened} handleSetModalOpened={setAddModalOpened} handleGetData={getData} />
    {selectedEmployee && <>
            <ModalAddLockerToEmployee open={addLockerOpened} handleSetModalOpened={setAddLockerOpened} handleGetData={getData}
                               employeeId={selectedEmployee}/>
            <ModalEditEmployeeComments open={editCommentsModalOpened} handleSetModalOpened={setEditCommentsModalOpened} handleGetData={getData} employeeId={selectedEmployee} />
    </>
    }

  </>

}
