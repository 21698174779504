import React, { useContext, useEffect } from 'react';
import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';
import { SelectedMenuContext } from '../../../contexts/selectedMenu.context';
import { Button, Card, Col, Input, message, Popconfirm, Row, Select, Skeleton } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { LoggedInContext } from '../../../contexts/loggedIn.context';
import { fetcher } from '../../../extras/fetcher';


interface Props {
  handleSetMainTitle: (title: string) => void;
  setBreadcrumbHandler: (items: MainBreadcrumbItem[] | null) => void;
}
export const Profile = (props: Props) => {

  const selectedMenuContext = useContext(SelectedMenuContext);
  const loggedInContext = useContext(LoggedInContext);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {

    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Profile',
      label: 'Profil',
    },
      {
        href: '/profile/settings',
        key: 'profile1',
        label: 'Ustawienia',
      },
    ];


    props.handleSetMainTitle(`Ustawienia Użytkownika`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['']);

  }, []);

  const confirmResetPasswordMessage = async () => {
    const createMail = await fetcher(`profile/send-reset-password`,'PATCH');
    if(createMail.result.success) {
      messageApi.success('Wiadomość z instrukcjami dotyczącymi resetowania hasła została wysłana!');
      return;
    }

    if(createMail.result.error) {
      messageApi.error(createMail.result.error);
      return;
    }
  };

  return <><Card size="small" title={loggedInContext?.user?.username} style={{textAlign: 'left'}} className={'no-printMe'}>

    <Row gutter={16} style={{flex: 1}}>
      <Col xs={24} xl={12}>
        <Card title="Dane Podstawowe" bordered={false} style={{height: 500, margin: 12}}>
          <p><span style={{fontWeight: 'bold'}}>Imię:</span> <Input disabled value={loggedInContext?.user?.firstname} /></p>
          <p><span style={{fontWeight: 'bold'}}>Nazwisko:</span> <Input disabled value={loggedInContext?.user?.lastname} /></p>
          <p><span style={{fontWeight: 'bold'}}>Email:</span> <Input disabled value={loggedInContext?.user?.email} /></p>
          <p><span style={{fontWeight: 'bold'}}>Organizacja:</span>

            <Select
              disabled
              aria-required
              style={{width: '100%',}}
              placeholder="Wybierz Organizację"
              optionFilterProp="children"
              value={loggedInContext?.user?.organization}

              options={[
                {
                  value: 'Elica Group Polska',
                  label: 'Elica Group Polska',
                },
                {
                  value: 'SLC Jelcz',
                  label: 'SLC Jelcz',
                },
                {
                  value: 'SLC Panattoni',
                  label: 'SLC Panattoni',
                },
              ]}
            />

          </p>

          <Popconfirm
            title="Resetowanie Hasła przez E-mail"
            description="Czy na pewno chcesz wysłać wiadomość e-mail z linkiem resetującym hasło?"
            onConfirm={confirmResetPasswordMessage}
            okText="Tak"
            cancelText="Nie"
          >
            <Button style={{display: 'block', marginTop: 10}} icon={<MailOutlined />}>Resetowanie Hasła przez E-mail</Button>
          </Popconfirm>

        </Card>
      </Col>

      <Col xs={24} xl={12}>
        <Card title="Inne Ustawienia" bordered={false} style={{height: 500, margin: 12}}>
          <Skeleton avatar active paragraph={{ rows: 6 }} />
        </Card>
      </Col>
    </Row>
  </Card>
    {contextHolder}
  </>
}
