import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Progress, Table } from 'antd';
import { Link } from 'react-router-dom';
import { fetcher } from '../../../../../extras/fetcher';


interface DataType {
  magName: string;
  magSize: number;
  plt: number;
  pcs: number;
  pcsMax: number;
  pltMax: number;
  percent: number;
};


interface Props {
  data? : DataType[],
  code?: string;
}
export const TableWithLocationOfCode = (props: Props) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {

    if(props.data) {
      setData(props.data);
    } else {

      (async () => {
        const data = await fetcher(`ikea/onecode/${props.code}`);

        const myData = await data.result.regData;
        setData(myData);
      })();

    }

  },[]);


  const columns: ColumnsType<DataType> = [
    {
      title: 'Lokalizacja',
      dataIndex: 'magName',
      key: 'magName',
      render: (value) => {
        return (
          <Link to={`/stock/rack/${value}`}>{value}</Link>
        )
      },
    },

    {
      title: 'Ilość',
      dataIndex: 'pcs',
      key: 'pcs',
    },

    {
      title: 'Sztuk Maksymalnie',
      dataIndex: 'pcsMax',
      key: 'pcsMax',
    },

    {
      title: 'Ilość Palet',
      dataIndex: 'plt',
      key: 'plt',
    },

    {
      title: 'Palet Maksymalnie',
      dataIndex: 'pltMax',
      key: 'pltMax',
    },

    {
      title: 'Wypełnienie',
      dataIndex: 'percent',
      key: 'percent',
      render: (value, record, index) => {
        const saturationPercentage = Math.round(value);
        return <div key={index}><Progress style={{width: '90px',}} percent={saturationPercentage} showInfo={false}
                                          status="active" strokeColor={{from: '#108ee9', to: '#87d068'}}/>
          <div style={{display: 'inline', width: '25px'}}>{saturationPercentage}%</div>
        </div>
      },
    },

  ];


  return (
    <Table rowKey={'magName'} columns={columns} pagination={{pageSizeOptions: [5, 10], defaultPageSize: 5, hideOnSinglePage: true}}
           showHeader={true} bordered={false} dataSource={data} style={{opacity: 0.9}} size={'small'}/>
  );

}
