import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { Input, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { fetcher } from '../../../../extras/fetcher';
import { IkeaDaneInterface } from '../../../../types/ikeaDane.interface';
import { PermError } from '../../NotFoundPage/PermError';
import { exportToExcel } from '../../../../extras/exportToExcel';
import { TableHeader } from '../Stan/TableHeader';
import { TableFooter } from '../Stan/TableFooter';
import { myDateSorter, myNumberSorter, myStringSorter } from '../../../../extras/sorters';
import { myDateFormat } from '../../../../extras/myDateFormat';
import { ModalIkeaIdDetails } from '../Stan/ModalIkeaIdDetails';


interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const HistoriaUDC = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const { windowHeight } = useWindowDimensions();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<IkeaDaneInterface[]>([]);
  const [tableData, setTableData] = useState<IkeaDaneInterface[]>([]);
  const [currentPalletId, setCurrentPalletId] = useState<number | null>(null);
  const [modalIkeaDetailsOpened, setModalIkeaDetailsOpened] = useState<boolean>(false);

  const linkParams =  useParams();

  const selectedMenuContext = useContext(SelectedMenuContext);

  const [permError, setPermError] = useState<boolean>(false);

  const handleSetModalIkeaDetailsOpened = (opened: boolean) => {
    setModalIkeaDetailsOpened(opened);
  }


  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm} : FilterDropdownProps) : JSX.Element => {

    return <div><Input
      allowClear
      value={selectedKeys[0]}
      onChange={(e) => {setSelectedKeys(e.target.value ? [e.target.value] : []);

        confirm({
          closeDropdown: false,
        });

      }}
      onPressEnter={() => confirm()}
    ></Input></div>

  }


  const getData = async () => {

    setLoading(true);


    await fetcher(`ikea/history/list/udc/${linkParams.udc}`)
      .then((res) => {

        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }

        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        const data : IkeaDaneInterface[] = res.result;

        setTableSelected([]);
        setSelectedRowKeys([]);
        setTableData(data);
        setLoading(false);

      });


  }


  useEffect(() => {

    if(!linkParams.udc) {
      return;
    }

    getData();

    props.handleSetMainTitle(`UDC: ${linkParams.udc}`);

    selectedMenuContext?.setSelectedKey(['historia-udc']);

    const breadcrumbItems: MainBreadcrumbItem[] =[{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Historia',
        label: 'Historia',
      },

      {
        href: '',
        key: 'historia-udc',
        label: 'UDC',
      },

      {
        href: `/history/udc/${linkParams.udc}`,
        key: linkParams.udc!,
        label: linkParams.udc,
      },

    ];

    props.setBreadcrumbHandler(breadcrumbItems);

  },[]);



  const columns : ColumnsType<IkeaDaneInterface>= [
    {
      width: 200,
      title: 'SSCC',
      dataIndex: 'ikea2',
      key: 'ikea2',
      sorter: (a,b) => myNumberSorter(a.ikea2, b.ikea2),
      render: (value, record) => <a onClick={() => {setCurrentPalletId(record.id); handleSetModalIkeaDetailsOpened(true)}}>{value}</a>
    },

    {
      width: 180,
      title: 'Kod',
      dataIndex: ['ikeaCode', 'kod_elica'],
      key: 'ikeaCode1',
      sorter: (a,b) => myStringSorter(a.ikeaCode.kod_elica, b.ikeaCode.kod_elica),
      filters: [
        ...Array.from(new Set(tableData.map(ikea => ikea.ikeaCode.kod_elica)))
          .map((val) => {
            return {
              text: val,
              value: val,
            }
          }),
      ],
      onFilter: (value, record) => record.ikeaCode.kod_elica.includes((value) as string),


      render: (value, record) => <Link to={`/stock/material/${record.ikeaCode.kod_elica}`}>{record.ikeaCode.kod_elica}</Link>,

    },

    {
      width: 180,
      title: 'Kod Klienta',
      dataIndex: ['ikeaCode', 'kod_ikea'],
      key: 'ikeaCode2',
      sorter: (a,b) => myStringSorter(a.ikeaCode.kod_ikea, b.ikeaCode.kod_ikea),
      filters: [
        ...Array.from(new Set(tableData.map(ikea => ikea.ikeaCode.kod_ikea)))
          .map((val) => {
            return {
              text: val,
              value: val,
            }
          }),
      ],

      onFilter: (value, record) => record.ikeaCode.kod_ikea.includes((value) as string),

      render: (value, record) => <Link to={`/stock/material/${record.ikeaCode.kod_elica}`}>{record.ikeaCode.kod_ikea}</Link>,
    },

    {
      width: 120,
      title: 'Lokalizacja',
      dataIndex: ['ikeaMag', 'name'],
      key: 'ikeaMag',
      sorter: (a,b) => myStringSorter(a.ikeaMag.name, b.ikeaMag.name),
      filters: [
        ...Array.from(new Set(tableData.map(ikea => ikea.ikeaMag.name)))
          .map((val) => {
            return {
              text: val,
              value: val,
            }
          }),
      ],

      onFilter: (value, record) => record.ikeaMag.name.includes((value) as string),
      render: (value, record) => record.ikeaMag ? <Link style={{textDecoration: record.ikea_delivery ? 'line-through' : ''}} to={`/stock/rack/${record.ikeaMag.name}`}>{record.ikeaMag.name}</Link> : '',

    },
    {
      width: 150,
      title: 'Data Rejestracji',
      dataIndex: 'date',
      key: 'date',
      sorter: (a,b) => myDateSorter(a.date, b.date),

      render: (value) => {
        return myDateFormat(value);
      }

    },

    {
      width: 180,
      title: 'Data Magazynowania',
      key: 'mag_date',
      dataIndex: 'mag_date',
      sorter: (a,b) => myDateSorter(a.mag_date, b.mag_date),

      render: (value) => value === null ? '' : myDateFormat(value),


    },


    {
      width: 80,
      title: 'Wysyłka',
      dataIndex: 'ikea_delivery',
      key: 'ikea_delivery',

      render: (value) => value ? 'TAK' : 'NIE',

    },

    {
      width: 180,
      title: 'Zarejestrowana przez',
      dataIndex: ['terminalUsers', 'name'],
      key: 'terminalUsers',

      sorter: (a,b) => myStringSorter(a.terminalUsers ? a.terminalUsers.name : '', b.terminalUsers ? b.terminalUsers.name : ''),
      filters: [
        ...Array.from(new Set(tableData.map(ikea => ikea.terminalUsers ? ikea.terminalUsers.name : '')))
          .map((val) => {
            return {
              text: val,
              value: val,
            }
          }),
      ],

      onFilter: (value, record) => record.terminalUsers ? record.terminalUsers.name.includes((value) as string) : false ,

      render: (value, record) => record.terminalUsers?.name,

    },

  ];


  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IkeaDaneInterface[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };


  const handleExcelClick = (data: IkeaDaneInterface[]) => {
    const dataToExport = data.map((data: IkeaDaneInterface,index) => {
      return {
        id: index+1,
        SSCC: data.ikea2,
        Kod: data.ikeaCode.kod_elica,
        'Kod Klienta': data.ikeaCode.kod_ikea,
        Lokalizacja: data.ikeaMag.name,
        'Data Rejestracji': myDateFormat(data.date),
        'Data Magazynowania': data.mag_date ? myDateFormat(data.mag_date) : '',
        'Wysyłka': data.ikea_delivery ? 'TAK' : 'NIE',
        'Zarejestrowana przez': data.terminalUsers ? data.terminalUsers.name : '',
      }
    });
    exportToExcel(dataToExport);
  };


  if(permError) {
    return <PermError/>
  }

  return (<>
           <Table columns={columns}
             scroll={{ y: windowHeight*.6}}
             pagination={false}
             loading={loading}
             showHeader={true}
             bordered={true}
             rowKey={'id'}

             title={() => <TableHeader getData={getData} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} handleExcelExportAllClick={() => handleExcelClick(tableData)}  selectedCount={tableSelected.length}/>}

             footer={() => <TableFooter selectedCount={tableSelected.length} totalCount={tableData.length}/> }
             rowSelection={{
               selectedRowKeys,
               type: 'checkbox',
               ...rowSelection,
             }}
             dataSource={tableData}
             style={{opacity: 0.9}}
             size={'small'}

      />

      {currentPalletId && <ModalIkeaIdDetails handleGetData={getData} handleSetModalOpened={handleSetModalIkeaDetailsOpened} open={modalIkeaDetailsOpened} palletId={currentPalletId} /> }
    </>
  );
}
