import React from 'react';
import { Button, message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { fetcher } from '../../../../extras/fetcher';


interface Props {
  disabled?: boolean;
  navId: number;
  deliveryId: number;
  deliveryNr: number;
  getData: () => void;
}
export const DeleteDelivery = (props: Props) => {

  const [messageApi, contextHolder] = message.useMessage();

  const onConfirm = async (e?: React.MouseEvent) => {
    const {result} = await fetcher(`nav/list/${props.navId}/delivery/${props.deliveryId}`, 'DELETE');

    if(result.success) {

      messageApi.open({
        type: 'success',
        content: `Delivery ${props.deliveryNr} została usunięta!`,
      });

      props.getData();

      return;
    }

    messageApi.open({
      type: 'error',
      content: result.error ? result.error : 'Nieznany błąd!',
    });

  };

  return (<>
      {contextHolder}
    <Popconfirm  placement="leftTop" disabled={props.disabled} title={`Na pewno chcesz usunąć delivery ${props.deliveryNr}?`} okText={'Tak'} cancelText={'Nie'}
  onConfirm={onConfirm}>
  <Button icon={<DeleteOutlined />} disabled={props.disabled} />

  </Popconfirm>
  </>
)
}
