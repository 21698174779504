import React, { useContext, useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';
import { Button, Input, message, Table } from 'antd';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { LoggedInContext } from '../../../contexts/loggedIn.context';
import { SelectedMenuContext } from '../../../contexts/selectedMenu.context';
import { ColumnsType } from 'antd/es/table';
import { PermError } from '../NotFoundPage/PermError';
import { exportToExcel } from '../../../extras/exportToExcel';
import { NavCodeInterface } from '../../../types/navCode.interface';
import { TableHeader } from '../Ikea/Stan/TableHeader';
import { TableFooter } from '../Ikea/Stan/TableFooter';
import { myDateSorter, myNumberSorter, myStringSorter } from '../../../extras/sorters';
import { myDateFormat } from '../../../extras/myDateFormat';
import { fetcher } from '../../../extras/fetcher';
import { AiOutlineSearch } from 'react-icons/ai';
import useDebounce from 'antd/es/form/hooks/useDebounce';
import { checkRule } from '../../../extras/checkRule';
import { PlusOutlined } from '@ant-design/icons';
import { ModalAddCode } from './ModalAddCode';


interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const InboundCodes = (props: Props) => {

  interface NavCodeAddedUsername extends NavCodeInterface {
    username: string;
  }

  const [tableData, setTableData] = useState<NavCodeAddedUsername[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<NavCodeInterface[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const {windowHeight} = useWindowDimensions();

  const [permError, setPermError] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);

  const linkParams = useParams();


  const [codeInput, setCodeInput] = useState('');
  const [limitInput, setLimitInput] = useState(150);

  const debounce = useDebounce([codeInput, limitInput]);

  const [modalAddCodeOpened,setModalAddCodeOpened] = useState(false);


  useEffect(() => {

    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Inbound',
      label: 'Przyjęcie',
    },

      {
        href: '/inbound/code/',
        key: 'inbound-code',
        label: 'Baza Kodów',
      },

    ];


    props.handleSetMainTitle( 'Inbound - Baza Kodów');
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['inbound-code']);

  }, []);

  const columns: ColumnsType<NavCodeAddedUsername> = [
    {
      title: 'Kod',
      width: 120,
      dataIndex: 'code',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(tableData.map((arr) => arr.code))).map((val) => {
          return {
            text: val,
            value: val,
          }
        }),
      ],

      onFilter: (value, record) => record.code.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myStringSorter(a.code,b.code),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record) => <Link to={`/inbound/code/${value}`}>{value}</Link>,

    },

    {
      title: 'Opis',
      dataIndex: 'desc',
      filterMode: 'tree',
      width: 250,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myStringSorter(a.desc,b.desc),

    },

    {
      title: 'EAN',
      dataIndex: 'ean',
      filterMode: 'tree',
      width: 200,
      sorter: (a,b) => myNumberSorter(a.ean,b.ean),

    },

    {
      title: 'Utworzony przez',
      dataIndex: 'username',
      filterMode: 'tree',
      width: 180,
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myStringSorter(a.username,b.username),
    },

    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      filterMode: 'tree',
      width: 180,
      sorter: (a,b) => myDateSorter(a.createdAt,b.createdAt),
      render: (value, record) => myDateFormat(value),
    },


  ];

  const getData = async () => {
    await setLoading(true);

    const data =  await fetcher(`inbound/code/${limitInput}/${codeInput}`);

    if(data.status === 403) {
      setPermError(true);
      return;
    }

    setTableData(data.result.map((x: NavCodeInterface) =>  {
      return {
        ...x,
        username: x.createdByUser ? `${x.createdByUser.firstname} ${x.createdByUser.lastname}` : '',
      };

    }));


    await setLoading(false);

  }

  useEffect(() => {
    getData();
  },[debounce[0], debounce[1]]);

  const handleExcelClick = (data: NavCodeInterface[]) => {



    const dataToExport = data.map((data: NavCodeInterface,index) => {

      return {
        id: index+1,
        Kod: data.code,
        Opis: data.desc,
        EAN: data.ean,
        'Utworzony Przez': data.createdByUser ? `${data.createdByUser.firstname} ${data.createdByUser.lastname}` : '',
        'Data Utworzenia': myDateFormat(data.createdAt),
      }
    })
    exportToExcel(dataToExport);

  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: NavCodeInterface[]) => {
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  if(permError) {
    return <PermError/>
  }

  const handleLimitInput = (txt: string) => {

    if(txt === '') {
      setLimitInput(Number(0));
      return;
    }

    const reg = new RegExp('[0-9]+');

    if(reg.test(txt)) {
      setLimitInput(Number(txt));
    }

  }

  const ButtonAddCode: JSX.Element =  <Button disabled={!checkRule('d2',loggedIn?.user?.rule!)} onClick={() => setModalAddCodeOpened(true)} size={'small'} icon={<PlusOutlined />}>Dodaj Kod</Button>
  const CodeInput: JSX.Element = <Input style={{flex: 0, width: 200,}} placeholder={'Wyszukaj Kod'} suffix={<AiOutlineSearch/>} value={codeInput} onChange={(x) => setCodeInput(x.target.value)}/>
  const LimitInput: JSX.Element = <Input style={{flex: 0, width: 100,}}  prefix={'Limit:'} value={limitInput} onChange={(x) => handleLimitInput(x.target.value)}/>

  return <>
    {contextHolder}

    <Table
      rowKey={'code'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={(records) => <TableHeader getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} additional={<>{ButtonAddCode} {CodeInput} {LimitInput}</>} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />


    <ModalAddCode open={modalAddCodeOpened} handleSetModalOpened={setModalAddCodeOpened} handleGetData={getData} />

  </>

}
