import ReCAPTCHA from 'react-google-recaptcha';
import React, { createRef, useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Spin } from 'antd';
import logo from '../../../logo.png';
import './LoginPage.css';
import { fetcher } from '../../../extras/fetcher';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { RECAPTCHA_KEY } from '../../../config';

interface Props {
  handleSetLoggedIn: (value:boolean) => void;
}


export const LoginPage = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { windowWidth } = useWindowDimensions();
  const [reCaptchaActive, setReCaptchaActive] = useState<boolean>(true);


  const recaptchaRef = createRef<ReCAPTCHA>();

  const countDown = () => {
    let secondsToGo = 2;

    const modal = Modal.success({
      title: 'Logowanie Udane!',
      content: <div style={{textAlign: 'center'}}>Za chwilę nastąpi przekierowanie...<br/><br/>
        <Spin size="large" /></div>,
      okButtonProps: {style: {display: 'none'}}
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: <div style={{textAlign: 'center'}}>Za chwilę nastąpi przekierowanie...<br/><br/>
          <Spin size="large" /></div>,
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
      props.handleSetLoggedIn(true);
    }, secondsToGo * 1000);
  };


  const onFinish = async (values: {username: string, password: string}) => {
    setLoading(true);

    const reCaptchaToken = reCaptchaActive ? await recaptchaRef.current?.executeAsync() : null;


    const result = await fetcher('auth/login',  'POST', {...values, reCaptchaToken});

    // @ts-ignore
    reCaptchaActive && window.grecaptcha.reset();


    if(result.status === 200) {
      // logowanie udane

      countDown();

    }

    if(result.status === 403) {
      // logowanie nieudane
      messageApi.open({
        type: 'error',
        content: result.result.error,
      });

      props.handleSetLoggedIn(false);

    }

    if(result.status === 400) {

      messageApi.open({
        type: 'error',
        content: 'Nieznany Błąd logowania...!',
      });

      props.handleSetLoggedIn(false);
    }




   setLoading(false);
  }

  const onFinishFailed = () => {
  }


  useEffect(() => {
    (async () => {
      const result = await fetcher('auth/recaptcha/');
      if(result.result.recaptcha === false) {
        setReCaptchaActive(false);
        console.log('re-deactivated');
      } else {
        setReCaptchaActive(true);
        console.log('re-active');
      }
    })();
  },[]);



   return (
     <div className="login-page">
       {contextHolder}
       <div className="form">
         <Form
           name="basic"
           initialValues={{ remember: true }}
           onFinish={onFinish}
           onFinishFailed={onFinishFailed}
           autoComplete="off"
         >
           <Form.Item
             label=""
             name="username"
             rules={[{ required: true, message: 'Proszę podać nazwę użytkownika!' }]}
           >
           <Input disabled={loading} required placeholder={'UŻYTKOWNIK'}/>
           </Form.Item>

           <Form.Item
             label=""
             name="password"
             rules={[{ required: true, message: 'Proszę podać prawidłowe hasło!' }]}
           >
           <Input type="password" disabled={loading} required placeholder="HASŁO"/>
           </Form.Item>

           {
             reCaptchaActive && <ReCAPTCHA onExpired={() => recaptchaRef.current?.reset()} sitekey={RECAPTCHA_KEY} ref={recaptchaRef} size={'invisible'} badge={'bottomleft'}/>
           }
           <Button style={{height: 50}} loading={loading} type="primary" htmlType="submit">Zaloguj</Button>
         </Form>
       </div>
{/*       <div style={{fontSize: 'smaller', padding: 0, margin: 0, textDecoration: 'none'}}>
         This site is protected by reCAPTCHA and the Google<br/>
         <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
       </div>*/}
       <img width={windowWidth < 768 ? '90%' : undefined} style={{alignSelf: 'right', position: 'absolute', bottom: 20, right: 20, opacity: 0.8}} src={logo} alt="Logo SL Concept"/>
     </div>
  );
}
