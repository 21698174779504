import React, { CSSProperties, useEffect, useState } from 'react';
import { Progress, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { fetcher } from '../../../../extras/fetcher';


interface Props {
  tripNr: number;
}

export const TripFillingLevel = (props: Props) => {


  const [tableData, setTableData] = useState<DataType[]>();

  interface DataType {
    id: number;
    typeName: string;
    pcsOnPlt: number;
    stacking: number;
    collectedPlt: number;
    totalPlt: number;
    columnCount: number;
    columnCountTotal: number;
    pltSize: number;
    freeSlotTotal: number;
    freeSlot: number;
    totalSizeTotal: number;
    totalSize: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Typ',
      dataIndex: 'typeName',
      key: 'typeName',
    },
    {
      title: 'Piętrowanie',
      dataIndex: 'stacking',
      key: 'stacking',
    },
    {
      title: 'Palet',
      dataIndex: 'collectedPlt',
      key: 'collectedPlt',
    },


    {
      title: 'Ilość słupków',
      dataIndex: 'columnCount',
      key: 'columnCount',
    },

    {
      title: 'Rozmiar Palety',
      dataIndex: 'pltSize',
      key: 'pltSize',
    },

    {
      title: 'Wolne miejsce na ostatnim słupku',
      dataIndex: 'freeSlot',
      key: 'freeSlot',
    },

    {
      title: 'Całkowity rozmiar',
      dataIndex: 'totalSize',
      key: 'totalSize',
    },

  ];

  const columnsTotal: ColumnsType<DataType> = [
    {
      title: 'Typ',
      dataIndex: 'typeName',
      key: 'typeName',
    },
    {
      title: 'Piętrowanie',
      dataIndex: 'stacking',
      key: 'stacking',
    },
    {
      title: 'Palet',
      dataIndex: 'totalPlt',
      key: 'totalPlt',
    },


    {
      title: 'Ilość słupków',
      dataIndex: 'columnCountTotal',
      key: 'columnCountTotal',
    },

    {
      title: 'Rozmiar Palety',
      dataIndex: 'pltSize',
      key: 'pltSize',
    },

    {
      title: 'Wolne miejsce na ostatnim słupku',
      dataIndex: 'freeSlotTotal',
      key: 'freeSlotTotal',
    },

    {
      title: 'Całkowity rozmiar',
      dataIndex: 'totalSizeTotal',
      key: 'totalSizeTotal',
    },

  ];

  useEffect(() => {
    getData();
  },[props.tripNr]);


  const getData = async () => {
    const res = await fetcher(`ikea/trip/${props.tripNr}/filling-level`);

  //  console.log(res.result);

    setTableData(res.result);

  }

  const cellSummaryStyles: CSSProperties = {
      fontWeight: 'bold',
  }


  const summary = (record:  readonly DataType[]) => {

    return <div style={{textAlign: 'left', marginTop: -5, marginLeft: 15}}>
      Podsumowanie: {record.reduce((prev,curr) => prev+curr.collectedPlt,0)}/{record.reduce((prev,curr) => prev+curr.totalPlt,0)} Palet

    </div>
  }

  return (
    <div className="site-card-wrapper" style={{marginTop: 24}}>

      <Tabs
        size={'small'}
        defaultActiveKey="1"
        items={[
          {
            label: `Aktualne Wypełnienie`,
            key: '1',
            children: <Table footer={summary} columns={columns} dataSource={tableData} size={'small'} pagination={false}
                             summary={(data) => {

                               return <>
                                 <Table.Summary.Row>
                                   <Table.Summary.Cell index={0} rowSpan={2}><span style={cellSummaryStyles}>Łącznie</span></Table.Summary.Cell>
                                   <Table.Summary.Cell index={1}>
                                     <span style={cellSummaryStyles}>Sztuk</span>
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={2}>
                                     <span style={cellSummaryStyles}>Palet</span>
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={2}>
                                     <span style={cellSummaryStyles}>Słupków</span>
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={3} colSpan={2} align={'center'}>
                                     <span style={cellSummaryStyles}>Wypełnienie</span>
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={4}>
                                     <span style={cellSummaryStyles}>Całkowity rozmiar</span>
                                   </Table.Summary.Cell>
                                 </Table.Summary.Row>
                                 <Table.Summary.Row>
                                   <Table.Summary.Cell index={0}>{data.reduce((prev, curr) => prev+curr.collectedPlt*curr.pcsOnPlt,0)}</Table.Summary.Cell>
                                   <Table.Summary.Cell index={1}>
                                     {data.reduce((prev, curr) => prev+curr.collectedPlt,0)}
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={2}>
                                     {data.reduce((prev, curr) => prev+curr.columnCount,0)}
                                   </Table.Summary.Cell>

                                   <Table.Summary.Cell index={3} colSpan={2} align={'center'}>
                                     <Progress percent={Math.round(data.reduce((prev, curr) => prev+curr.totalSize,0)/2600*100)} style={{width: '70%'}} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />
                                   </Table.Summary.Cell>

                                   <Table.Summary.Cell index={4}>
                                     {data.reduce((prev, curr) => prev+curr.totalSize,0)}/2600
                                   </Table.Summary.Cell>

                                 </Table.Summary.Row>
                               </>
                             }}

            />,
          },
          {
            label: `Planowane Wypełnienie`,
            key: '2',
            children: <Table

              footer={summary}
              summary={(data) => {

                return <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} rowSpan={2}><span style={cellSummaryStyles}>Łącznie</span></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <span style={cellSummaryStyles}>Sztuk</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <span style={cellSummaryStyles}>Palet</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <span style={cellSummaryStyles}>Słupków</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} colSpan={2} align={'center'}>
                      <span style={cellSummaryStyles}>Wypełnienie</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <span style={cellSummaryStyles}>Całkowity rozmiar</span>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>{data.reduce((prev, curr) => prev+curr.totalPlt*curr.pcsOnPlt,0)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      {data.reduce((prev, curr) => prev+curr.totalPlt,0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      {data.reduce((prev, curr) => prev+curr.columnCountTotal,0)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={3} colSpan={2} align={'center'}>
                      <Progress percent={Math.round(data.reduce((prev, curr) => prev+curr.totalSizeTotal,0)/2600*100)} style={{width: '70%'}} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={4}>
                      {data.reduce((prev, curr) => prev+curr.totalSizeTotal,0)}/2600
                    </Table.Summary.Cell>

                  </Table.Summary.Row>
                </>
              }}

              columns={columnsTotal} dataSource={tableData} size={'small'} pagination={false} />,
          },
        ]}
      />


{/*      <Row gutter={16}>
        <Col span={16}>
          <Card title="Aktualne Wypełnienie" bordered={false}>
            <Table columns={columns} dataSource={tableData} size={'small'} pagination={false}/>
          </Card>
        </Col>
        <Col span={16} style={{marginTop: 16}}>
          <Card title="Planowane Wypełnienie" bordered={false}>
            <Table columns={columnsTotal} dataSource={tableData} size={'small'} pagination={false} />
          </Card>
        </Col>
      </Row>*/}
    </div>
  );
}
