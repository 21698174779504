import { Modal, Table } from 'antd';
import { NavPositionInterface } from '../../../../types/navPosition.interface';
import { ColumnsType } from 'antd/es/table';
import { myNumberSorter, myStringSorter } from '../../../../extras/sorters';
import React from 'react';
import { TableHeader } from '../../Ikea/Stan/TableHeader';

interface Props {
  deliveryNr: number;
  navPosition: NavPositionInterface[];
  open: boolean;
  handleSetModalOpened: (opened: boolean) => void;
  getData: () => void;
  loading: boolean;
}

export const ModalDeliveryPosition = (props: Props) => {



  const columns: ColumnsType<NavPositionInterface> = [
    {
      title: 'Pozycja',
      width: 50,
      dataIndex: 'pos',
      filterMode: 'tree',

      sorter: (a, b) => myNumberSorter(a.pos,b.pos),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

    },
    {
      title: 'Kod',
      dataIndex: 'code',
      width: 180,
      align: 'center',
      sorter: (a, b) => myStringSorter(a.code,b.code),

      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(props.navPosition.map((arr) => arr.code))).map((val) => {
          return {
            text: val,
            value: val,
          }
        }),
      ],

      onFilter: (value, record) => record.code.toString().indexOf(String(value)) === 0,


    },


    {
      width: 50,
      title: 'Ilość',
      align: 'center',
      filterMode: 'tree',
      dataIndex: 'count',

    },

  ];

  const handleCancel = () => {
    props.handleSetModalOpened(false);
  }


  return <Modal destroyOnClose={true} bodyStyle={{padding: 10,}} title={`${props.deliveryNr} - Lista pozycji`} open={props.open} onOk={handleCancel} onCancel={handleCancel}>
    <Table
      scroll={{ y: 500 }}
      loading={props.loading}
      rowKey={'id'}
      title={(records) => <TableHeader selectedCount={0} getData={props.getData} />} columns={columns} pagination={false} showHeader={true} bordered={true} dataSource={props.navPosition} style={{opacity: 0.9}} size={'small'} />


    </Modal>

}
