import React, { useEffect, useState } from 'react';
import { Button, DatePicker, message, Modal } from 'antd';
import status2 from './imgStatus/2.gif';
import status1 from './imgStatus/1.gif';
import dayjs, { Dayjs } from 'dayjs';
import { fetcher } from '../../../../extras/fetcher';
import { IkeaTripInterface } from '../../../../types/ikeaTrip.interface';

interface Props {
  open : boolean;
  tripNr: number;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}
export const ModalTripStatus = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(false);

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [endValue, setEndValue] = useState<Dayjs | null>(null);
  const [sentValue, setSentValue] = useState<Dayjs | null>(null);
  const [sapValue, setSapValue] = useState<Dayjs | null>(null);

  const [messageApi, contextHolder] = message.useMessage();


  const handleOk = async () => {
    setMoveLoading(true);

    ///update
    const updateData = await fetcher(`ikea/trip/${props.tripNr}/status`,'PATCH',{
      sapAt: sapValue ? sapValue.toDate() : null,
      sentAt: sentValue ?  sentValue.toDate() : null,
      loadedAt: endValue ?  endValue.toDate() : null,
    });

    if(updateData.status === 403) {

      messageApi.open({
        type: 'error',
        content: `Nie posiadasz wymaganych uprawnień!`,
      });
      setMoveLoading(false);
      return;
    }

    if(updateData.status === 200) {
      messageApi.open({
        type: 'success',
        content: `Status dla Tripa ${props.tripNr} został zaktualizowany.`,
      });

      props.handleSetModalOpened(false);

    } else {
      messageApi.open({
        type: 'error',
        content: `Nieznany błąd..`,
      });
    }



      setMoveLoading(false);


    props.handleGetData();
  }

  const handleCancel = () => {
    props.handleSetModalOpened(false);
  }

  const handleClickEnd = () => {
    setEndValue(dayjs());
  }
  const handleClickSent = () => {
    setSentValue(dayjs());
  }

  const handleClickSap = () => {
    setSapValue(dayjs());
  }


  useEffect(() => {
    (async () => {
      setLoading(true);
      const tripData = await fetcher(`ikea/trip/${props.tripNr}`);
      if(tripData.status === 200) {
        const trip: IkeaTripInterface = tripData.result;
        setEndValue(trip.loadedAt && dayjs(trip.loadedAt));
        setSentValue(trip.sentAt && dayjs(trip.sentAt));
        setSapValue(trip.sapAt && dayjs(trip.sapAt));
      } else {
        // nieznany błąd
      }

        setLoading(false);
    })();

  },[props.handleSetModalOpened]);




  return     <Modal bodyStyle={{padding: 10,}} title={`${props.tripNr} - Zmiana Statusu`} open={props.open} onOk={handleOk} onCancel={handleCancel} confirmLoading={moveLoading}>
    <>

        <div style={{display: 'flex', alignItems: 'center', alignContent: 'flex-end', justifyContent: 'space-evenly', padding: 5 }}>
          <div style={{width: 150, display: 'block'}}>Załadunek Zakończony:</div>
          <Button onClick={handleClickEnd} type={'text'}><img alt={'Wysłany'} src={status1} /></Button>
          <div style={{width: 200 , display: 'flex'}}><DatePicker value={endValue} onChange={(value) => setEndValue(value)} showTime={true} /></div>
        </div>

      <div style={{ display: 'flex', alignItems: 'center', alignContent: 'flex-end', justifyContent: 'space-evenly', padding: 5 }}>
        <div style={{width: 150, display: 'block'}}>Pobrany w SAP:</div>
        <Button onClick={handleClickSap} type={'text'}><img alt={'Pobrany w sap'} src={status2} /></Button>
        <div style={{width: 200 , display: 'flex'}}><DatePicker value={sapValue} onChange={(value) => setSapValue(value) } allowClear showTime={true} /></div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', alignContent: 'flex-end', justifyContent: 'space-evenly', padding: 5 }}>
        <div style={{width: 150, display: 'block'}}>Wysłany:</div>
        <Button onClick={handleClickSent} type={'text'}><img alt={'Wysłany'} src={status2} /></Button>
        <div style={{width: 200 , display: 'flex'}}><DatePicker value={sentValue} onChange={(value) => setSentValue(value) } allowClear showTime={true} /></div>
      </div>

      {contextHolder}
    </>
  </Modal>

}
