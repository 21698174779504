import React, { useContext, useEffect, useState } from 'react';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetcher } from '../../../../extras/fetcher';
import { Badge, Button, Popover, Table } from 'antd';
import { TableHeader } from '../Stan/TableHeader';
import { TableFooter } from '../Stan/TableFooter';
import { ColumnsType, TableProps } from 'antd/es/table';
import { myNumberSorter, myStringSorter } from '../../../../extras/sorters';
import { PlusOutlined } from '@ant-design/icons';
import { ModalDeliveryStatus } from './ModalDeliveryStatus';
import { ModalAddSapPositions } from './ModalAddSapPositions';
import { exportToExcel } from '../../../../extras/exportToExcel';
import { CheckDeliveryAlert } from './CheckDeliveryAlert';
import { PermError } from '../../NotFoundPage/PermError';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';
import { checkRule } from '../../../../extras/checkRule';
import { TableWithLocationOfCode } from '../Stan/StanKod/TableWithLocationOfCode';


interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}

export const OneDelivery = (props: Props) => {

  const [tableData, setTableData] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);
  const [currentDeliveryNr, setCurrentDeliveryNr] = useState<number | null>(null);
  const [modalSapAtOpened, setModalSapAtOpened] = useState<boolean>(false);
  const [notExistCode, setNotExistCode] = useState<string[]>([]);


  const [modalAddSapPositionsOpened,setModalAddSapPositionsOpened] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const {windowHeight} = useWindowDimensions();

  const [permError, setPermError] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);

  const linkParams = useParams();
  const navi = useNavigate();

  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Wysylka',
        label: 'Wysyłka',
      },

      {
        href: '/trip/',
        key: 'Tripy',
        label: 'Tripy',
      },

      {
        href: `/trip/${linkParams.trip}`,
        key: 'oneTrip',
        label: linkParams.trip,
      },

      {
        href: `/trip/${linkParams.trip}/${linkParams.delivery}`,
        key: 'oneDelivery',
        label: linkParams.delivery ==='total' ? 'Wszystkie Kody' : linkParams.delivery,
      },
    ];


    props.handleSetMainTitle(linkParams.delivery ==='total' ? `Trip: ${linkParams.trip} - Wszystkie Kody` : `Delivery: ${linkParams.delivery}`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['tripy']);

  }, []);


  interface DataType {
    id: number;
    kod: string;
    kod_klienta: string;
    ikeaCodeTypeName: string;
    pcsPlan: number;
    pcsReady: number;
    pltPlan: number;
    pltReady: number;
    totalInStock: number;
    totalPltInStock: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Kod',
      width: 150,
      dataIndex: 'kod',
      filterMode: 'tree',

      sorter: (a, b) => myStringSorter(a.kod, b.kod),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => <Link to={`collected/${record.kod}`}>{value}</Link>

    },
    {
      title: 'Kod Klienta',
      dataIndex: 'kod_klienta',
      filterMode: 'tree',
      width: 150,
      sorter: (a,b) => myStringSorter(a.kod_klienta, b.kod_klienta),
      render: (value, record) => <Link to={`collected/${record.kod}`}>{value}</Link>

    },

    {
      title: 'Typ',
      dataIndex: 'ikeaCodeTypeName',
      filterMode: 'tree',
      width: 150,
      sorter: (a,b) => myStringSorter(a.ikeaCodeTypeName,b.ikeaCodeTypeName),

    },

    {
      title: 'Dostępna ilość',
      width: 150,
      dataIndex: 'totalInStock',
      align: 'center',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.totalInStock,b.totalInStock),

      render : (value, record, index) => {

        return <Popover
        key={`pop${index}`}
        mouseLeaveDelay={0.5}
        placement="leftBottom"
        content={
        <TableWithLocationOfCode code={record.kod} />
      }>
      <Button type={'text'}>{value >= (record.pcsPlan - record.pcsReady) ? value : <Badge overflowCount={9999} showZero color={'#f5222d'} count={value}/>}</Button>
      </Popover>
      }
      //render : (value, record) => value === 0 || value < record.pcsReady ? <Badge overflowCount={9999} showZero color={'#f5222d'} count={value}/> : value

    },

    {
      title: 'Ilość Planowana',
      width: 150,
      dataIndex: 'pcsPlan',
      align: 'center',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.pcsPlan,b.pcsPlan),

      render : (value, record) => value === 0 || value < record.pcsReady ? <Badge overflowCount={9999} showZero color={'#f5222d'} count={value}/> : value

    },

    {
      width: 150,
      title: 'Ilość Pobrana',
      dataIndex: 'pcsReady',
      align: 'center',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.pcsReady,b.pcsReady),

      render: (value, record) => <Badge overflowCount={9999} showZero color={record.pcsReady === record.pcsPlan && record.pcsReady !== 0 ? '#52c41a' : record.pcsReady > record.pcsPlan ? '#f5222d' : record.pcsReady === 0 ? '#faad14' : '#faad14'} count={record.pcsReady}/>

    },

    {
      width: 150,
      title: 'Pobrana Ilość Palet',
      dataIndex: 'pltReady',
      align: 'center',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.pltReady,b.pltReady),

      render: (value, record) => <Badge overflowCount={9999} showZero color={record.pltReady === record.pltPlan && record.pltReady !== 0 ? '#52c41a' : record.pltReady > record.pltPlan ? '#f5222d' : record.pltReady === 0 ? '#faad14' : '#faad14'} count={record.pltReady}/>

    },

  ];



  const getData = async () => {
    await setLoading(true);

    const data = await fetcher(`ikea/trip/${linkParams.trip}/${linkParams.delivery === 'total' ? '' : String(linkParams.delivery)+'/'}total`);

    if(data.status === 403) {
      setPermError(true);
      return;
    }

    const myData = data.result.codeData.map((record: any): DataType => {

      return {
        id: record.id,
        ikeaCodeTypeName: record.ikeaCodeTypeName,
        kod: record.code,
        kod_klienta: record.ikeaCode,
        pcsPlan: record.total,
        pcsReady: record.collected,
        pltPlan: record.totalPlt,
        pltReady: record.collectedPlt,
        totalInStock: record.totalInStock,
        totalPltInStock: record.totalPltInStock,
      };
    });

    setTableData(myData);
    setTableSelected([]);
    setSelectedRowKeys([]);

    await setLoading(false);

  };

  useEffect(() => {
    getData();
  },[]);

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    /// console.log('params', pagination, filters, sorter, extra);
  };


  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    //  console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  const handleExcelClick = (data: DataType[]) => {



    const dataToExport = data.map((data: DataType,index) => {

      return {
        id: index+1,
        Kod: data.kod,
        'Kod klienta': data.kod_klienta,
        Typ: data.ikeaCodeTypeName,
        'Ilość planowana': data.pcsPlan,
        'Ilość pobrana': data.pcsReady,
        'Planowa ilość Palet': data.pltPlan,
        'Pobrana ilość Palet': data.pltReady,
      }
    })
    exportToExcel(dataToExport);

  }

  const ButtonAddSapPositions: JSX.Element =  <Button disabled={!checkRule('a2',loggedIn?.user?.rule!)} onClick={() => setModalAddSapPositionsOpened(true)} size={'small'} icon={<PlusOutlined />}>Dodaj Pozycję z SAP</Button>
  const ButtonSerials: JSX.Element =  <Button style={{marginLeft: 10,}} onClick={() => navi('serials')} size={'small'}>Nr seryjne</Button>

  if(permError) {
    return <PermError/>
  }


  return  <>
        <CheckDeliveryAlert deliveryNr={linkParams.delivery!} tripNr={linkParams.trip!}/>
    <Table
      rowKey={'id'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={(records) => <TableHeader tableDataToPrint={{data: tableSelected.length === 0 ? tableData : tableSelected, allColumns: columns, selectedColumns: ['Kod','Kod Klienta', 'Typ','Ilość Planowana', 'Ilość Pobrana', 'Pobrana Ilość Palet']}} getData={getData} additional={linkParams.delivery === 'total' ? undefined :<>{ButtonAddSapPositions}{ButtonSerials}</>}  handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)}  selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} onChange={onChange} style={{opacity: 0.9}} size={'small'} />


    <ModalAddSapPositions tripNr={Number(linkParams.trip)} deliveryNr={Number(linkParams.delivery)} open={modalAddSapPositionsOpened} handleSetModalOpened={setModalAddSapPositionsOpened} handleGetData={getData}/>
    { currentDeliveryNr && <ModalDeliveryStatus open={modalSapAtOpened} tripNr={Number(linkParams.trip)} deliveryNr={currentDeliveryNr} handleSetModalOpened={setModalSapAtOpened} handleGetData={getData}/> }


  </>
};
