import React, { useContext, useEffect, useState } from 'react';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetcher } from '../../../../extras/fetcher';
import { Button, Input, Table } from 'antd';
import { TableHeader } from '../Stan/TableHeader';
import { TableFooter } from '../Stan/TableFooter';
import { ColumnsType, TableProps } from 'antd/es/table';
import { myDateSorter, myNumberSorter, myStringSorter } from '../../../../extras/sorters';
import { exportToExcel } from '../../../../extras/exportToExcel';
import { TerminalUsersInterface } from '../../../../types/terminalUsersInterface';
import { IkeaDaneInterface } from '../../../../types/ikeaDane.interface';
import { myDateFormat } from '../../../../extras/myDateFormat';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { ModalIkeaIdDetails } from '../Stan/ModalIkeaIdDetails';
import { ModalMovePalletsFromDelivery } from './ModalMovePalletsFromDelivery';
import { PermError } from '../../NotFoundPage/PermError';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}

export const TableCollected = (props: Props) => {

  const [tableData, setTableData] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);
  const [palletId, setPalletId] = useState<number | null>(null);
  const [modalPalDetailOpen, setModalPalDetailOpen] = useState<boolean>(false);


  const [modalMultipleMoveOpened, setModalMultipleMoveOpened] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const {windowHeight} = useWindowDimensions();

  const linkParams = useParams();
  const navi = useNavigate();

  const [permError, setPermError] = useState<boolean>(false);


  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Wysylka',
        label: 'Wysyłka',
      },

      {
        href: '/trip/',
        key: 'Tripy',
        label: 'Tripy',
      },

      {
        href: `/trip/${linkParams.trip}`,
        key: 'oneTrip',
        label: linkParams.trip,
      },

      {
        href: `/trip/${linkParams.trip}/${linkParams.delivery}`,
        key: 'oneDelivery',
        label: linkParams.delivery === 'total' ? 'Wszystkie Delivery' : linkParams.delivery,
      },

      {
        href: `/trip/${linkParams.trip}/${linkParams.delivery}/collected`,
        key: 'collected',
        label: 'Pobrane pozycje',
      },

      {
        href: `/trip/${linkParams.trip}/${linkParams.delivery}/collected/${linkParams.code}`,
        key: 'oneCode',
        label: linkParams.code,
      },

    ];


    props.handleSetMainTitle(`Delivery: ${linkParams.delivery === 'total' ? 'Wszystkie' : linkParams.delivery}`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['tripy']);

    getData();
  }, [linkParams.code]);


  interface DataType {
    id: number;
    kod: string;
    kod_klienta: string;
    ikeaCodeTypeName: string;
    lokalizacja: string;
    pcs: number;
    SSCC: string;
    udc: number;
    week: number;
    regDate: Date | string;
    magDate: Date | null | string;
    terminalUsers: TerminalUsersInterface | null;
  }

  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close } : FilterDropdownProps) : JSX.Element => {

    return <div><Input
      allowClear
      value={selectedKeys[0]}
      onChange={(e) => {setSelectedKeys(e.target.value ? [e.target.value] : []);

        confirm({
          closeDropdown: false,
        });

      }}
      onPressEnter={() => confirm()}
    ></Input></div>

  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Kod',
      width: 150,
      dataIndex: 'kod',

      filterMode: 'menu',
      filters: [
        ...Array.from(new Set(tableData.map(code => code.kod)))
          .sort((a,b) => myStringSorter(a,b))
          .map((kod) => {
            return {
              text: kod,
              value: kod,
            }
          }),
      ],

      onFilter: (value, record) => record.kod.includes((value) as string),

      sorter: (a, b) => myStringSorter(a.kod, b.kod),
      sortDirections: ['descend', 'ascend'],

      render: (value, record) => linkParams.code ? value : <Link to={`${record.kod}`}>{value}</Link>,

    },
    {
      title: 'Kod Klienta',
      dataIndex: 'kod_klienta',
      width: 150,
      sorter: (a,b) => myDateSorter(a.kod_klienta, b.kod_klienta),

      filterMode: 'menu',
      filters: [
        ...Array.from(new Set(tableData.map(data => data.kod_klienta)))
          .sort((a,b) => myStringSorter(a,b))
          .map((kod) => {
            return {
              text: kod,
              value: kod,
            }
          }),
      ],
      onFilter: (value, record) => record.kod_klienta === value,
      render: (value, record) => linkParams.code ? value : <Link to={`${record.kod}`}>{value}</Link>,

    },

    {
      title: 'Typ',
      dataIndex: 'ikeaCodeTypeName',
      filterMode: 'menu',
      width: 150,
      sorter: (a,b) => myStringSorter(a.ikeaCodeTypeName,b.ikeaCodeTypeName),
      filters: [
        ...Array.from(new Set(tableData.map(data => data.ikeaCodeTypeName)))
          .sort((a,b) => myStringSorter(a,b))
          .map((data) => {
            return {
              text: data,
              value: data,
            }
          }),
      ],
      onFilter: (value, record) => record.ikeaCodeTypeName.includes((value) as string),
    },

    {
      title: 'Lokalizacja',
      dataIndex: 'lokalizacja',
      filterMode: 'menu',
      width: 150,
      filterSearch: true,
      sorter: (a,b) => myStringSorter(a.lokalizacja,b.lokalizacja),

      filters: [
        ...Array.from(new Set(tableData.map(data => data.lokalizacja)))
          .sort((a,b) => myStringSorter(a,b))
          .map((data) => {
            return {
              text: data,
              value: data,
            }
          }),
      ],
      onFilter: (value, record) => record.lokalizacja === value,

    },

    {
      title: 'Ilość',
      width: 100,
      dataIndex: 'pcs',
      filterMode: 'tree',
      sorter: (a,b) => myNumberSorter(a.pcs,b.pcs),

      align: 'center',

    },

    {
      width: 200,
      title: 'SSCC',
      dataIndex: 'SSCC',
      sorter: (a,b) => myStringSorter(a.SSCC,b.SSCC),
      filterMode: 'tree',
      filterDropdown,
      onFilter: (value, record) => record.SSCC.includes((value) as string),

      render: (value, record) => <Button type={'link'} onClick={() => {setPalletId(record.id); setModalPalDetailOpen(true)}}>{value}</Button>

    },

    {
      width: 150,
      title: 'udc',
      dataIndex: 'udc',
      sorter: (a,b) => myNumberSorter(a.udc,b.udc),
      filterMode: 'tree',
      filterDropdown,
      onFilter: (value, record) => record.udc.toString().includes((value) as string),

    },

    {
      width: 150,
      title: 'Tydzień',
      dataIndex: 'week',
      filterMode: 'tree',
      sorter: (a,b) => myNumberSorter(a.week,b.week),

    },

    {
      width: 200,
      title: 'Data rejestracji',
      dataIndex: 'regDate',
      filterMode: 'tree',
      sorter: (a,b) => myDateSorter(a.regDate,b.regDate),

    },

    {
      width: 200,
      title: 'Data magazynowania',
      dataIndex: 'magDate',
      filterMode: 'tree',
      sorter: (a,b) => myDateSorter(a.magDate,b.magDate),

    },

    {
      width: 200,
      title: 'Rejestracja przez',
      filterMode: 'tree',
      sorter: (a,b) => myStringSorter(a.terminalUsers ? a.terminalUsers.name : '',b.terminalUsers ? b.terminalUsers.name : ''),
      render: (value,record) => record.terminalUsers ? record.terminalUsers.name : '',

    },



  ];



  const getData = async () => {
    await setLoading(true);

    const data = linkParams.code ? await fetcher(`ikea/trip/${linkParams.trip}/${linkParams.delivery}/collected/${linkParams.code}`) : await fetcher(`ikea/trip/${linkParams.trip}/${linkParams.delivery}/collected`);

    if(data.status === 403) {
      setPermError(true);
      return;
    }


    const myData = data.result.map((record: IkeaDaneInterface): DataType => {

      return {
        id: record.id,
        kod: record.ikeaCode.kod_elica,
        kod_klienta: record.ikeaCode.kod_ikea,
        ikeaCodeTypeName: record.ikeaCode.ikeaCodeType.name,
        lokalizacja: record.ikeaMag.name,
        pcs: record.ikeaCode.ilosc,
        SSCC: record.ikea2,
        udc: record.udc,
        week: record.date_stamp,
        regDate: myDateFormat(record.date),
        magDate: myDateFormat(record.mag_date),
        terminalUsers: record.terminalUsers,
      };
    });

    setTableData(myData);
    setTableSelected([]);
    setSelectedRowKeys([]);

    await setLoading(false);

  };


  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    /// console.log('params', pagination, filters, sorter, extra);
  };


  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
  //    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleExcelClick = (data: DataType[]) => {



    const dataToExport = data.map((data: DataType,index) => {

      return {
        id: index+1,
        Kod: data.kod,
        'Kod Klienta': data.kod_klienta,
        Typ: data.ikeaCodeTypeName,
        lokalizacja: data.lokalizacja,
        'Ilość': data.pcs,
        SSCC: data.SSCC,
        udc: data.udc,
        week: data.week,
        'Data rejestracji': data.regDate,
        'Data magazynowania': data.magDate,
        'Rejestracja przez': data.terminalUsers ? data.terminalUsers.name : '',
      }
    })
    exportToExcel(dataToExport);

  }



  const ButtonAllCode: JSX.Element =  <Button style={{marginLeft: 10,}} onClick={() => navi(`/trip/${linkParams.trip}/${linkParams.delivery}/collected`)} size={'small'}>Wyświetl wszystkie kody</Button>

  const handleMovePallet = () => {
      setModalMultipleMoveOpened(true);
  };

  if(permError) {
    return <PermError/>
  }

  return  <>
    <h3>Pobrane Pozycje ({linkParams.code ? linkParams.code : 'Wszystkie'})</h3>
    <Table
      rowKey={'id'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={(records) => <TableHeader tableDataToPrint={{data: tableSelected.length === 0 ? tableData : tableSelected, allColumns: columns, selectedColumns: ['Kod','Kod Klienta', 'Typ','Lokalizacja', 'Ilość', 'SSCC', 'udc', 'Tydzień']}} getData={getData} handleMovePalletClick={handleMovePallet} additional={linkParams.code ? ButtonAllCode : undefined} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)}  selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.5}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} onChange={onChange} style={{opacity: 0.9}} size={'small'} />
    {
      <ModalMovePalletsFromDelivery open={modalMultipleMoveOpened} palletsId={tableSelected.map(item => item.id)} handleSetModalOpened={setModalMultipleMoveOpened} handleGetData={getData}/>
    }

    {
      palletId && <ModalIkeaIdDetails open={modalPalDetailOpen} palletId={palletId} handleSetModalOpened={setModalPalDetailOpen} handleGetData={getData} />
    }
  </>
};
