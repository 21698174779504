import React, { useContext } from 'react';

import './TableHeader.css';

import excelIcon from './icons/excel.png';
import movingIcon from './icons/moving.png';
import printerIcon from './icons/printer.png';
import { Button, Dropdown, MenuProps, Tooltip } from 'antd';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';
import { checkRule } from '../../../../extras/checkRule';
import { SyncOutlined } from '@ant-design/icons';
import { PrintTable, TableDataToPrint } from '../../../PrintTable/PrintTable';

interface Props {

  getData: (() => void) | null;
  handleMovePalletClick?: () => void;

  handleExcelExportAllClick?: () => void;
  handleExcelExportSelectedClick?: () => void;
  selectedCount: number;
  tableDataToPrint?: TableDataToPrint;
  additional?: JSX.Element;
  prefixAdditional?: JSX.Element;
}

export const TableHeader = (props: Props) => {

  const loggedIn = useContext(LoggedInContext);

  const items: MenuProps['items'] = [
    {label: <Button type={'link'} size={'small'} onClick={props.handleExcelExportAllClick}>Eksportuj całość</Button>, key: 'export-1', icon: <img src={excelIcon} style={{width: '16px', height: '16px', opacity: 1}} alt="Eksportuj całość"/>},
    {label: <Button disabled={props.selectedCount === 0} size={'small'} type={'link'} onClick={props.handleExcelExportSelectedClick}>Eksportuj zaznaczone</Button>, key: 'export-2', icon: <img src={excelIcon} style={{width: '16px', height: '16px', opacity: 1}} alt="Eksportuj zaznaczone"/>},
  ];


  return <div className={'table-header'}>
    <Tooltip title={'Odśwież...'}><Button type={'text'} style={{borderRadius: 50, width: 40, height: 40, fontSize: 12, display: !props.getData ? 'none' : undefined}} disabled={!props.getData} onClick={props.getData ? props.getData : undefined}><SyncOutlined /></Button></Tooltip>

    {
      props.prefixAdditional && props.prefixAdditional
    }

    { props.tableDataToPrint && <Tooltip title={'Drukuj...'}>
      <PrintTable data={props.tableDataToPrint}>
        <Button type={'text'}><img src={printerIcon} style={{width: '16px', height: '16px', opacity: 1}}
                                             alt="Drukuj..."/></Button>
      </PrintTable>



    </Tooltip>}
    {(props.handleExcelExportAllClick || props.handleExcelExportSelectedClick) && <Tooltip title={'Eksportuj do Excela...'}>

      <Dropdown menu={{items}} placement="bottomLeft">

        <Button type={'text'}>
          <img src={excelIcon} style={{width: '16px', height: '16px', opacity: 1}} alt="Eksportuj do Excela..."/>
        </Button>

      </Dropdown>
    </Tooltip>}
    {props.handleMovePalletClick &&
        <Tooltip title={'Przenieś zaznaczone pozycje'}>
            <Button type={'text'} onClick={props.handleMovePalletClick} disabled={props.selectedCount === 0 || !checkRule('a4', loggedIn?.user?.rule!)}><img
                src={movingIcon} style={{width: '16px', height: '16px', opacity: props.selectedCount === 0 ? 0.5 : 1}}
                alt="Przenieś zaznaczone palety"/></Button>
        </Tooltip>

    }

{/*    <Tooltip title={'Inwentaryzacja'}>
      <Button type={'text'} disabled><img src={inventoryIcon} style={{width: '16px', height: '16px', opacity: 0.5}}
                                           alt="Inwentaryzacja"/></Button>
    </Tooltip>*/}


    {props.additional}
  </div>;

};
