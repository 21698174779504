import React, { useContext, useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import { MainBreadcrumbItem } from '../../../../MainBreadcrumb/MainBreadcrumb';
import { ColumnsType } from 'antd/es/table';
import { Button, Checkbox, Popover, Table } from 'antd';
import { fetcher } from '../../../../../extras/fetcher';
import { TableHeader } from '../TableHeader';
import { TableFooter } from '../TableFooter';
import { TableWithLocationOfCode } from './TableWithLocationOfCode';
import { Link } from 'react-router-dom';
import { SelectedMenuContext } from '../../../../../contexts/selectedMenu.context';
import { exportToExcel } from '../../../../../extras/exportToExcel';
import { myNumberSorter } from '../../../../../extras/sorters';


interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const StanKod = (props: Props) => {

  const [tableData, setTableData] = useState<GetAllCodeInterface[]>([]);
  const [tableSelected, setTableSelected] = useState<GetAllCodeInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [notZero, setNotZero] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const { windowHeight } = useWindowDimensions();

  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[]=[{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Stan',
        label: 'Stan',
      },
      {
        href: '',
        key: 'Material',
        label: 'Materiał',
        menu: [
          {
            href: '/stock/material/',
            label: 'Materiał',
          },
          {
            href: '/stock/rack/',
            label: 'Lokalizacje',
          },
          {
            href: '/stock/rack-total/',
            label: 'Lokalizacje2',
          },
        ]

      },
    ];


    props.handleSetMainTitle('Materiał');
    props.setBreadcrumbHandler(breadcrumbItems);
    selectedMenuContext?.setSelectedKey(['material'])

  },[]);

  interface GetAllCodeInterface {
    id: number;
    kod: string;
    kod_klienta: string;
    type: string;
    totalPlt: number;
    totalPcs: number;
    latestWeek: number;
    oldestWeek: number;
    differenceOfWeeks: number;
    regData: {
      magName: string;
      magSize: number;
      plt: number;
      pcs: number;
      pcsMax: number;
      pltMax: number;
      percent: number;
    }[];
  }


  const getData = async () => {
    await setLoading(true);

    const data = await fetcher('ikea/code');

    const myData = await data.result;



    notZero ? setTableData(await myData.filter((x : GetAllCodeInterface) => x.totalPcs !== 0))  : setTableData(myData);

    await setLoading(false);

  }

  useEffect(() => {
    getData();
  },[notZero]);



  const columns: ColumnsType<GetAllCodeInterface> = [
    {
      title: 'Kod',
      dataIndex: 'kod',
      width: 125,
      filterMode: 'menu',
      filters: [
        ...(tableData.map((code) => {
          return {
            text: code.kod,
            value: code.kod,
          };
        })),
      ],

      onFilter: (value, record) => record.kod.includes((value) as string),

      sorter: (a,b) => a.kod.localeCompare(b.kod, undefined, {
        numeric: true,
      }),

      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record) => <Link to={record.kod}>{value}</Link>

    },

    {
      title: 'Kod Klienta',
      dataIndex: 'kod_klienta',
      width: 130,
      filterMode: 'menu',
      filters: [
        ...(tableData.map((code) => {
          return {
            text: code.kod_klienta,
            value: code.kod_klienta,
          };
        })),
      ],

      onFilter: (value, record) => record.kod_klienta.includes((value) as string),

      sorter: (a,b) => a.kod_klienta.localeCompare(b.kod_klienta, undefined, {
        numeric: true,
      }),

      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record) => <Link to={record.kod}>{value}</Link>

    },

    {
      title: 'Typ',
      dataIndex: 'type',
      width: 125,
      filterMode: 'menu',
      filters: [
        ...Array.from(new Set(tableData.map(code => code.type)))
          .map((type) => {
            return {
              text: type,
              value: type,
            }
          }),
      ],

      onFilter: (value, record) => record.type.includes((value) as string),

      sorter: (a,b) => a.type.localeCompare(b.type, undefined, {
        numeric: true,
      }),

      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

    },
    {
      title: 'Ilość',
      dataIndex: 'totalPcs',
      width: 80,
      sortDirections: ['descend', 'ascend'],
      //@ts-ignore
      sorter: (a, b) => Number(a.totalPcs) - Number(b.totalPcs),
    },

    {
      title: 'Ilość Palet',
      dataIndex: 'totalPlt',
      width: 100,
      sortDirections: ['descend', 'ascend'],

      sorter: (a, b) => Number(a.totalPlt) - Number(b.totalPlt),
    },
    {
      title: 'Lokalizacje',
      width: 110,
      sortDirections: ['descend', 'ascend'],

      sorter: (a, b) => Number(a.regData.length) - Number(b.regData.length),
      render: (value, record, index) => {

        return (

          <Popover
            key={`pop${index}`}
            mouseLeaveDelay={0.5}
            placement="leftBottom"
            content={
              <TableWithLocationOfCode data={record.regData} />
            }>
            <Button type={'text'}>{record.regData.length}</Button>
          </Popover>
        )
      }
    },

    {
      title: 'Niepełne Lokalizacje',
      width: 110,
      sortDirections: ['descend', 'ascend'],

      sorter: (a, b) => Number(a.regData.filter(x => x.plt < x.pltMax).length) - Number(b.regData.filter(x => x.plt < x.pltMax).length),
      render: (value, record, index) => {

        return (

          <Popover
            key={`pop${index}`}
            mouseLeaveDelay={0.5}
            placement="leftBottom"
            content={
              <TableWithLocationOfCode data={record.regData.filter(x => x.pltMax > x.plt)} />
            }>
            <Button type={'text'}>{record.regData.filter(x => x.plt < x.pltMax).length}</Button>
          </Popover>
        )
      }
    },

    {
      title: 'Najnowszy Tydzień',
      dataIndex: 'latestWeek',
      width: 110,
      filterMode: 'tree',

      sorter: (a, b) => myNumberSorter(a.latestWeek, b.latestWeek),

      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Najstarszy Tydzień',
      dataIndex: 'oldestWeek',
      width: 110,
      filterMode: 'tree',

      sorter: (a, b) => Number(a.oldestWeek) - Number(b.oldestWeek),

      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Różnica Tygodni',
      width: 110,
      filterMode: 'tree',
      sorter: (a, b) => myNumberSorter(((Number(String(a.latestWeek).substring(0,2)) - Number(String(a.oldestWeek).substring(0,2)))*52)+(Number(String(a.latestWeek).substring(2)) - Number(String(a.oldestWeek).substring(2))), ((Number(String(b.latestWeek).substring(0,2)) - Number(String(b.oldestWeek).substring(0,2)))*52)+(Number(String(b.latestWeek).substring(2)) - Number(String(b.oldestWeek).substring(2)))),
      render: (value, record) => ((Number(String(record.latestWeek).substring(0,2)) - Number(String(record.oldestWeek).substring(0,2)))*52)+(Number(String(record.latestWeek).substring(2)) - Number(String(record.oldestWeek).substring(2))),
      sortDirections: ['descend', 'ascend'],
    },
  ];




  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: GetAllCodeInterface[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };



  const CheckBoxNotZero: JSX.Element = <Checkbox checked={notZero} onChange={(e) => setNotZero(e.target.checked)}>Nie wyświetlaj materiału z ilością zerową.</Checkbox>


  const handleExcelClick = (data: GetAllCodeInterface[]) => {
    const dataToExport = data.map((data: GetAllCodeInterface,index) => {

      return {
        id: index+1,
        kod: data.kod,
        kod_klienta: data.kod_klienta,
        typ: data.type,
        'ilość': data.totalPcs,
        'ilość_palet': data.totalPlt,
        Lokalizacje: data.regData.length,
        Lokalizacje_niepelne: data.regData.filter(x => x.plt !== x.pltMax),
        Najnowszy_tydzien: data.latestWeek,
        Najstarszy_tydzien: data.oldestWeek,
        Roznica_tygodni: ((Number(data.latestWeek.toString().substring(0,2)) - Number(data.oldestWeek.toString().substring(0,2)))*52)+(Number(data.latestWeek.toString().substring(2)) - Number(data.oldestWeek.toString().substring(2))),
      }
    })
    exportToExcel(dataToExport);
  };

  return (
    <Table
      rowKey={'id'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={() => <TableHeader getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} additional={CheckBoxNotZero} selectedCount={selectedRowKeys.length} />} columns={columns} scroll={{ y: windowHeight*.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />
  );



}
