import React from 'react';
import Sider from 'antd/es/layout/Sider';
import logo from '../../logo.png';
import logoCollapsed from '../../logoX.png';
import { MenuLeft } from '../MenuLeft/MenuLeft';
import { MenuTheme } from 'antd';

import './MainSider.css';
import { Link } from 'react-router-dom';

interface Props {
  collapsed: boolean;
  theme: MenuTheme;
  handleSetCollapsed: (value: boolean) => void;
}

export const MainSider = (props: Props) => {

  return (
  <Sider className="sider no-printMe" collapsedWidth={75} width={240} collapsible collapsed={props.collapsed} onCollapse={value => props.handleSetCollapsed(value)} theme={props.theme}>
    <div className="logo" ><Link to={'/'}><img src={!props.collapsed ? logo : logoCollapsed} alt={'Logo SL Concept'} /></Link> </div>
    <MenuLeft collapsed={props.collapsed} theme={props.theme} />
  </Sider>
    );
}
