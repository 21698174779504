import React, { useState } from 'react';
import { Input, message, Modal } from 'antd';
import { fetcher } from '../../../../extras/fetcher';
import { useParams } from 'react-router-dom';

interface Props {
  open : boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}
export const ModalAddTrip = (props: Props) => {

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [inputValue, setInputValue] = useState<string>('');


  const params = useParams();

  const handleCancel = () => {
    props.handleSetModalOpened(false);
    setInputValue('');
  }

  const handleOk = async () => {
    setMoveLoading(true);


    const regTrip = new RegExp('^[7-8][0-9]{5}$');

    if(!regTrip.test(inputValue)) {
      messageApi.open({
        type: 'error',
        content: 'Podany nr Trip jest nieprawidłowy!',
      });
      setMoveLoading(false);
      return;
    }

    const data = await fetcher(`nav/list/${params.navId}/trip/`,'PATCH', {tripNr: Number(inputValue), navId: Number(params.navId)});

    if(data.status === 403) {
      messageApi.open({
        type: 'error',
        content: 'Nie posiadasz wymaganych uprawnień!',
      });
      setMoveLoading(false);
      return;
    }

    if(data.result.error) {
      messageApi.open({
        type: 'error',
        content: data.result.error,
      });
    } else {
      messageApi.open({
        type: 'success',
        content: `Trip ${inputValue} dodany.`,
      });

      setInputValue('');

      props.handleSetModalOpened(false);

      props.handleGetData();

    }

    setMoveLoading(false);

  }


  const handleInputChange = (value: string) => {


    setInputValue(value);
  }

  return (

   <Modal bodyStyle={{padding: 10,}} title={'Dodawanie Trip'} open={props.open} onOk={handleOk} onCancel={handleCancel} confirmLoading={moveLoading}>
    <>
      <Input value={inputValue} onPressEnter={handleOk} onChange={(e) => handleInputChange(e.target.value)} style={{width: '200px'}} placeholder={'Nr Trip'}/>
      {contextHolder}
    </>
  </Modal>

  )
}
