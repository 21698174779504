import React, { useEffect, useState } from 'react';
import { Checkbox, Input, message, Modal, Select } from 'antd';
import { fetcher } from '../../../../extras/fetcher';
import { TerminalUsersPermInterface } from '../../../../types/terminalUsersPerm.interface';
import { CheckboxChangeEvent } from 'antd/es/checkbox';


interface Props {
  open : boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}
export const ModalAddUserTerm = (props: Props) => {
  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [obj, setObj] = useState({
    name: '',
    organization: '',
    rule: '',
  });
  const [listPerm, setListPerm] = useState<TerminalUsersPermInterface[] | null>(null);

  const getData = async () => {
    const getPerm = await fetcher('admin/users-term/list-perm');
    setListPerm(getPerm.result);
  }

  useEffect(() => {
    getData();
  },[]);

  const handleCancel = () => {
    setObj({
      name: '',
      organization: '',
      rule: '',
    })
    props.handleSetModalOpened(false);
  }


  const handleOk = async () => {
    setMoveLoading(true);

    if(obj.name === '') {
      messageApi.error('Nie wpisano nazwy użytkownika!');
      setMoveLoading(false);
      return;
    }

    if(obj.organization === '') {
      messageApi.error('Organizacja nie została wybrana!');
      setMoveLoading(false);
      return;
    }


    const addUser = await fetcher(`admin/users-term/`,'POST', obj);

    if(addUser.result.error) {
      messageApi.error(addUser.result.error);
      setMoveLoading(false);
      return;
    } else if(addUser.result.success) {

      messageApi.success('Gratulacje! Użytkownik został pomyślnie dodany do systemu.');
      props.handleSetModalOpened(false);
      props.handleGetData();
    } else {
      messageApi.error('Wystąpił nieznany błąd!');
    }

    setMoveLoading(false);

  }

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {

    if(!e.target.checked) {
        setObj((o) => {
          return {
            ...o,
            rule: o.rule.replace(e.target.value,''),
          }
        });
    } else {
      setObj((o) => {
        return {
          ...o,
          rule: `${o.rule}${e.target.value}`,
        }
      });
    }
  };

  return (

    <Modal bodyStyle={{padding: 10,}} title={'Dodawanie Użytkownika'} open={props.open} onOk={handleOk} onCancel={handleCancel} confirmLoading={moveLoading}>
      <>
        <h4>Użytkownik</h4>
        <Input value={obj.name} onPressEnter={handleOk} onChange={(e) => setObj((obj) => { return {...obj, name: e.target.value}})} style={{width: '100%',}} placeholder={'Imię i Nazwisko'}/>
        <br />
        <br />
        <h4>Organizacja</h4>
        <Select
          aria-required
          style={{width: '100%',}}
          placeholder="Wybierz Organizację"
          optionFilterProp="children"
          value={obj.organization}
          onChange={(value) => setObj((obj) => { return {...obj, organization: value}})}
          options={[
            {
              value: 'Elica Group Polska',
              label: 'Elica Group Polska',
            },
            {
              value: 'SLC Jelcz',
              label: 'SLC Jelcz',
            },
            {
              value: 'SLC Panattoni',
              label: 'SLC Panattoni',
            },
          ]}
        />
        <br />
        <br />

        <h4>Uprawnienia</h4>

        {listPerm?.map((perm) =>
          <>
          <Checkbox key={perm.id} onChange={onCheckBoxChange} value={perm.id}>{perm.name}</Checkbox>
          <br />
          </>
        )}

        {contextHolder}
      </>
    </Modal>

  )
}
