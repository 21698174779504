import React, { useState } from 'react';
import { Button, message, Popconfirm, PopconfirmProps } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { fetcher } from '../../../extras/fetcher';

interface Props {
  getData : () => void;
  employeeId: string;
}
export const DeleteEmployeeConfirm = ({getData, employeeId}: Props) => {

  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState<boolean>(false);
  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const confirm = async () => {
    setMoveLoading(true);
    const delLocker = await fetcher('person/employees/','DELETE', { employeeId });

    if (delLocker.status === 403) {
      messageApi.error('Brak wymaganych uprawnień!');
    } else
    if (delLocker.result.error) {
      messageApi.error(delLocker.result.error);
    } else
    if (delLocker.result.success) {
      messageApi.success('Pracownik został usunięty z systemu!');
      setOpen(false);
      getData();
    } else {
      messageApi.error('Nieznany błąd!');
    }


  setMoveLoading(false);
  setOpen(false);

  }

  const cancel = () => {
    setOpen(false);
  }

  const popConfirmProps: PopconfirmProps = {
    placement: 'bottom',
    title: 'Usuwanie Pracownika',
    description: 'Czy na pewno chcesz usunąć pracownika?',
    onConfirm: (e) => confirm(),
    okText:'Tak',
    cancelText:'Nie',
    okButtonProps: {
      loading: moveLoading,
    },
    open,
    onCancel: () => cancel(),
  };

  return (<>
      {contextHolder}
      <Popconfirm {...popConfirmProps}>
        <Button onClick={() =>setOpen(true)} style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'}><DeleteOutlined style={{fontSize: 12}} /></Button>
      </Popconfirm>

  </>

  );
};
