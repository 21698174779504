import React from 'react';
import { MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import movingIcon from './icons/moving.png';


interface Props {
  selectedCount: number;
  totalCount: number;
  handleMovePalletClick?: () => void;
}

export const TableFooter = (props: Props) => {

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if(e.key==='move') {
      props.handleMovePalletClick && props.handleMovePalletClick();
    }
  };

  const items: MenuProps['items'] = [
    ...(props.handleMovePalletClick ? [{
      label: 'Przenieś zaznaczone palety',
      key: 'move',
      icon: <img src={movingIcon} style={{width: '16px', height: '16px'}} alt="Przenieś zaznaczone palety"/>,
    }] : []),
    {
      label: '2nd menu item',
      key: '2',
      icon: <UserOutlined />,
    },
    {
      label: '3rd menu item',
      key: '3',
      icon: <UserOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div style={{marginTop: -6, textAlign: 'left', fontSize: 12}}>
      <>Zaznaczone pozycję: {props.selectedCount} / {props.totalCount}</>
{/*    <Dropdown.Button type={'text'} menu={menuProps}>
        <>Zaznaczone pozycję: {props.selectedCount} / {props.totalCount}</>
    </Dropdown.Button>*/}
    </div>
  );
}
