import React, { useEffect, useState } from 'react';
import { Input, message, Modal, Radio, RadioChangeEvent, Space } from 'antd';
import { fetcher } from '../../../extras/fetcher';
import { PersonLockerTypeInterface } from '../../../types/personLockerType.interface';
import { PersonBranchInterface } from '../../../types/personBranch.interface';
import { PermError } from '../NotFoundPage/PermError';
import TextArea from 'antd/es/input/TextArea';


interface Props {
  open: boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}

export const ModalAddLocker = (props: Props) => {
  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [lockerTypes, setLockerTypes] = useState<PersonLockerTypeInterface[]>([]);
  const [allBranches, setAllBranches] = useState<PersonBranchInterface[]>([]);

  const [permError, setPermError] = useState<boolean>(false);

  const [obj, setObj] = useState<{
    id?: string;
    name: string;
    comments: string;
    personBranchId: string | null,
    personLockerTypeId: string | null,
  }>({
    name: '',
    comments: '',
    personBranchId: null,
    personLockerTypeId: null,
  });

  const getData = async () => {
    const getTypes = await fetcher('person/lockers-types');
    setLockerTypes(getTypes.result);

    const getBranches = await fetcher('person/all-branches');
    setAllBranches(getBranches.result);
  };

  useEffect(() => {
    getData().then(r => setLoading(false));
  }, []);

  const handleOk = async () => {

    if(obj.name === '') {
      messageApi.warning('Nie podano nazwy...');
      return;
    }

    if (!obj.personBranchId) {
      messageApi.warning('Nie wybrano oddziału...');
      return;
    }

    if (!obj.personLockerTypeId) {
      messageApi.warning('Nie wybrano typu...');
      return;
    }

    setMoveLoading(true);
    const sendToApi = await fetcher('person/lockers', 'POST', obj);

    if (sendToApi.status === 403) {
      setLoading(false);
      setPermError(true);
      setMoveLoading(false);
      return;
    }

    if (sendToApi.result.error) {
      messageApi.error(sendToApi.result.error);
      setLoading(false);
      setMoveLoading(false);
      return;
    }

    if (sendToApi.result.success) {
      message.success(`Szafka ${obj.name} została dodana!`);
      props.handleSetModalOpened(false);
      setObj({
        name: '',
        comments: '',
        personBranchId: null,
        personLockerTypeId: null,
      });

    } else {
      message.error('Nieznany błąd...');
    }


    props.handleGetData();

    setLoading(false);
    setMoveLoading(false);
  };

  const handleCancel = async () => {
    props.handleSetModalOpened(false);
    setObj({
      name: '',
      comments: '',
      personBranchId: null,
      personLockerTypeId: null,
    });

  };

  const onChangeTypeRadio = (e: RadioChangeEvent) => {
    setObj((obj) => {
      return {
        ...obj,
        personLockerTypeId: e.target.value,
      };
    });
  };

  const onChangeBranchRadio = (e: RadioChangeEvent) => {
    setObj((obj) => {
      return {
        ...obj,
        personBranchId: e.target.value,
      };
    });
  };

  const onChangeTextArea = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setObj((obj) => {
      return {
        ...obj,
        comments: e.target.value,
      };
    });
  }

  return (
    <Modal bodyStyle={{padding: 10}} title={'Dodawanie Szafki'} open={props.open} onOk={handleOk}
           onCancel={handleCancel} confirmLoading={moveLoading}>
      {permError ? <PermError/> : <>
        <h4>Nazwa</h4>
        <Input value={obj.name} onPressEnter={handleOk} onChange={(e) => setObj((obj) => {
          return {...obj, name: e.target.value};
        })} style={{width: '100%'}} placeholder={'Nazwa / Numer szafki'}/>
        <br/>
        <br/>
        <h4>Typ</h4>

        <Radio.Group onChange={onChangeTypeRadio} value={obj.personLockerTypeId}>
          <Space direction="vertical">
            {
              lockerTypes.map(type => <Radio value={type.id}>{type.name}</Radio>)
            }
          </Space>
        </Radio.Group>
        <br/>
        <br/>

        <h4>Oddział</h4>

        <Radio.Group onChange={onChangeBranchRadio} value={obj.personBranchId}>
          <Space direction="vertical">
            {
              allBranches.map(branch => <Radio value={branch.id}>{branch.name}</Radio>)
            }
          </Space>
        </Radio.Group>
        <br/>
        <br/>

        <h4>Komentarz</h4>
        <TextArea value={obj.comments} rows={4} onChange={onChangeTextArea}/>

        {contextHolder}
      </>}
    </Modal>
  );
};
