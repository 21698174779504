import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */


interface Props {
  onIdle: () => void;
  idleTime? : number;
  promptTimeout? : number;
}

export const useIdleTimeout = ({ onIdle, idleTime = 60, promptTimeout = 5 } : Props) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const handleIdle = () => {
    setIdle(true);

    /// wyloguj tutaj
  }
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: promptTimeout * 1000,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500
  })
  return {
    isIdle,
    setIdle,
    idleTimer,
  }


}
