import React, { useEffect, useState } from 'react';
import { message, Modal, Radio, RadioChangeEvent, Space, Spin } from 'antd';
import { PermError } from '../NotFoundPage/PermError';
import { fetcher } from '../../../extras/fetcher';
import { PersonEmployeeInterface } from '../../../types/personEmployee.interface';
import { PersonBranchInterface } from '../../../types/personBranch.interface';


interface Props {
  open: boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;

  employeeId: string;
}

export const ModalEditEmployeeBranch = (props: Props) => {

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [permError, setPermError] = useState<boolean>(false);
  const [allBranch, setAllBranch] = useState<PersonBranchInterface[]>([])
  const [branchSelected, setBranchSelected] = useState<string>();


  const getData = async () => {

    const branch = await fetcher(`person/all-branches`);

    setAllBranch(branch.result);

    await fetcher(`person/employees/${props.employeeId}`)
      .then(res => {
        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }


        if (res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }


        setBranchSelected((res.result as PersonEmployeeInterface).personBranch.id);

      })

  }

  useEffect(() => {
    setLoading(true);
    getData().then(r => setLoading(false));
  }, [props.employeeId]);


  const handleCancel = async () => {
    props.handleSetModalOpened(false);
  };


  const handleOk = async () => {
    const data = await fetcher(`person/employees/${props.employeeId}/branch`, 'PUT', {employeeId: props.employeeId, branchId: branchSelected});

    if (data.status === 403) {
      messageApi.error('Brak wymaganych uprawnień!');
    }

    if(data.result.error) {
      messageApi.error(data.result.error);
      return;
    }

    if(data.result.success) {
      messageApi.success('Oddział został zmieniony!');
      props.handleSetModalOpened(false);
      props.handleGetData();
    } else {
      messageApi.error('Nieznany błąd!');
    }

  }

  if(permError) {
    return <PermError/>
  }

  const onChange = (e: RadioChangeEvent) => {
    setBranchSelected(e.target.value);
  };

  return (
    <Modal bodyStyle={{padding: 10}} title={`Zmiana oddziału`} open={props.open} onOk={handleOk}
           onCancel={handleCancel} confirmLoading={moveLoading}>
      {
        loading ? <div style={{textAlign: 'center'}}>
          <Spin size="large" />

      </div> : <>
      {permError ? <PermError/> : <>
        <h4>Oddział</h4>
        <Radio.Group onChange={onChange} value={branchSelected}>
          <Space direction="vertical">
            {
              allBranch.map((branch) => <Radio key={branch.id} value={branch.id}>{branch.name}</Radio>)
            }
          </Space>
        </Radio.Group>
        {contextHolder}
      </>}

</>
     }
    </Modal>

  );

};
