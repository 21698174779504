import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';
import React, { useContext, useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useParams } from 'react-router-dom';
import { SelectedMenuContext } from '../../../contexts/selectedMenu.context';
import { ColumnsType } from 'antd/es/table';
import { PersonLockerInterface } from '../../../types/personLocker.interface';
import { myStringSorter } from '../../../extras/sorters';
import { fetcher } from '../../../extras/fetcher';
import { Badge, Button, Modal, Table, Tooltip } from 'antd';
import { TableHeader } from '../Ikea/Stan/TableHeader';
import { TableFooter } from '../Ikea/Stan/TableFooter';
import { PermError } from '../NotFoundPage/PermError';
import { exportToExcel } from '../../../extras/exportToExcel';
import { EditOutlined, PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { ModalAddLocker } from './ModalAddLocker';
import { EmptyLockerConfirm } from './EmptyLockerConfirm';
import { ModalAddEmployeeToLocker } from './ModalAddEmployeeToLocker';
import { ModalEditLockerComment } from './ModalEditLockerComment';
import { DeleteLockerConfirm } from './DeleteLockerConfirm';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}


export const Lockers = (props: Props) => {


  const [loading, setLoading] = useState(false);
  const { windowHeight } = useWindowDimensions();
  const [tableSelected, setTableSelected] = useState<PersonLockerInterface[]>([]);
  const [tableData, setTableData] = useState<PersonLockerInterface[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [addModalOpened, setAddModalOpened] = useState<boolean>(false);
  const [editCommentsModalOpened, setEditCommentsModalOpened] = useState<boolean>(false);

  const [addEmployeeOpened, setAddEmployeeOpened] = useState<boolean>(false);
  const [selectedLocker, setSelectedLocker] = useState<string | null>(null);

  const [permError, setPermError] = useState<boolean>(false);

  const linkParams =  useParams();

  const selectedMenuContext = useContext(SelectedMenuContext);


  const getData = async () => {
    await fetcher(`person/lockers/`)
      .then(res => {
        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }


        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        setTableData(res.result);

        setTableSelected([]);
        setSelectedRowKeys([]);

        setLoading(false);
      });
  }

  useEffect(() => {

    setLoading(true);
    getData();
    props.handleSetMainTitle(`Szafki`);
    selectedMenuContext?.setSelectedKey(['hr_lockers']);

    const breadcrumbItems: MainBreadcrumbItem[] =[{
      href: '',
      key: 'hr',
      label: 'Zarządzanie HR',
    },
      {
        href: '/person/lockers/',
        key: 'hr_lockers',
        label: 'Szafki',
      },

    ];

    props.setBreadcrumbHandler(breadcrumbItems);

  },[]);



  const columns : ColumnsType<PersonLockerInterface>= [

    {
      width: 130,
      title: 'Oddział',
      dataIndex: ['personBranch','name'],
      key: 'personBranch',
      sorter: (a,b) => myStringSorter(a.personBranch.name, b.personBranch.name),

      onFilter: (value, record) => record.personBranch.name.toLowerCase().includes(String(value).toLowerCase()),
      filters: [
        ...Array.from(new Set(tableData.map(row => row.personBranch.name))).map((value) => {
          return {
            text: value,
            value: value,
          }
        }),
      ],
      filterSearch: true,
      //filterDropdown,
    },
    {
      width: 130,
      title: 'Typ',
      dataIndex: ['personLockerType','name'],
      key: 'personLockerType',
      sorter: (a,b) => myStringSorter(a.personLockerType.name, b.personLockerType.name),

      onFilter: (value, record) => record.personLockerType.name.toLowerCase().includes(String(value).toLowerCase()),
      filters: [
        ...Array.from(new Set(tableData.map(row => row.personLockerType.name))).map((value) => {
          return {
            text: value,
            value: value,
          }
        }),
      ],
      filterSearch: true,
      //filterDropdown,
    },
    {
      width: 130,
      title: 'Nazwa',
      dataIndex: 'name',
      key: 'name',
      sorter: (a,b) => myStringSorter(a.name, b.name),
    },
    {
      width: 200,
      align: 'center',
      title: 'Zajęta',
      dataIndex: ['personEmployee','name'],
      key: 'busy',
      sorter: (a,b) => myStringSorter(a.personEmployee ? a.personEmployee.name : '', b.personEmployee ? b.personEmployee.name : ''),

      onFilter: (value, record) => (record.personEmployee ? record.personEmployee.name : 'WOLNA') === value,
      filters: [
        ...Array.from(new Set(tableData.map(row => row.personEmployee ? row.personEmployee.name : 'WOLNA'))).map((value) => {
          return {
            text: String(value),
            value: value,
          }
        }),
      ],

      render: (value, record, index) => record.personEmployee ? <>{record.personEmployee.name}

        <EmptyLockerConfirm getData={getData} lockerId={record.id} />
        </> : <><Badge key={index} count="WOLNA" style={{ backgroundColor: '#52c41a' }}/><Button onClick={() => {
          setSelectedLocker(record.id);
          setAddEmployeeOpened(true);

      }} style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'}><UserAddOutlined style={{fontSize: 12}} /></Button></>



    },
    {
      width: 180,
      title: 'Komentarz',
      dataIndex: 'comments',
      key: 'comments',
      sorter: (a,b) => myStringSorter(a.comments, b.comments),
      render: (value, record, index) => <div key={index}>{value}<Button style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'} onClick={() => {
        setSelectedLocker(record.id);
        setEditCommentsModalOpened(true);
      }
      }><EditOutlined style={{fontSize: 12}}/></Button></div>
    },

    {
      width: 50,
      title: '',
      key: 'delete',
      dataIndex: '',
      render: (value, record, index) => <DeleteLockerConfirm key={index} getData={getData} lockerId={record.id}/>
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: PersonLockerInterface[]) => {
      //  console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  if(permError) {
    return <PermError/>
  }

  const handleExcelClick = (data: PersonLockerInterface[]) => {
    const dataToExport = data.map((data: PersonLockerInterface,index) => {

      return {
        id: index+1,
        'Oddział': data.personBranch.name,
        'Typ': data.personLockerType.name,
        'Nazwa': data.name,
        'Zajęta': data.personEmployee ? data.personEmployee.name : '',
        'Komentarz': data.comments,
      }
    })
    exportToExcel(dataToExport);
  };

  const AddLockerButton: JSX.Element = <Tooltip title={'Dodaj...'}>
    <Button type={'text'} onClick={() => setAddModalOpened(true)}><PlusOutlined style={{color: '#57b3ec'}} /></Button>
  </Tooltip>


  return <>

  <Table columns={columns}
  scroll={{ y: windowHeight*.6}}
  pagination={false}
  loading={loading}
  showHeader={true}
  bordered={true}
  rowKey={'id'}



  title={() => <TableHeader prefixAdditional={AddLockerButton} tableDataToPrint={{data: tableSelected.length === 0 ? tableData : tableSelected, allColumns: columns.map((col) => {
    return {
      ...col,
    }
    }), selectedColumns: ['Oddział','Typ','Nazwa', 'Zajęta', 'Komentarz']}} getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} selectedCount={tableSelected.length}/>}

  footer={() => <TableFooter selectedCount={tableSelected.length} totalCount={tableData.length}/> }
  rowSelection={{
    selectedRowKeys,
      type: 'checkbox',
  ...rowSelection,
  }}
  dataSource={tableData}
  style={{opacity: 0.9}}
  size={'small'}

  />

    <ModalAddLocker open={addModalOpened} handleSetModalOpened={setAddModalOpened} handleGetData={getData}/>
      {selectedLocker &&
          <>
          <ModalEditLockerComment open={editCommentsModalOpened} handleSetModalOpened={setEditCommentsModalOpened} handleGetData={getData} lockerId={selectedLocker} />
          <ModalAddEmployeeToLocker open={addEmployeeOpened} handleSetModalOpened={setAddEmployeeOpened} handleGetData={getData} lockerId={selectedLocker}/>
          </>
      }
  </>

}
