import React from 'react';
import { Button, message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { fetcher } from '../../../../extras/fetcher';


interface Props {
  disabled?: boolean;
  navId: number;
  navNr: number;
  getData: () => void;
}
export const DeleteNav = (props: Props) => {

  const [messageApi, contextHolder] = message.useMessage();

  const onConfirm = async (e?: React.MouseEvent) => {
    const {result} = await fetcher(`nav/list/${props.navId}`, 'DELETE');

    if(result.success) {

      messageApi.open({
        type: 'success',
        content: `NAV został usunięty!`,
      });

      props.getData();

    } else {

      messageApi.open({
        type: 'error',
        content: result.error ? result.error : 'Nieznany błąd!',
      });
    }

  };

  return (<>
      {contextHolder}
    <Popconfirm  placement="leftTop" disabled={props.disabled} title={`Na pewno chcesz usunąć NAV${props.navNr}?`} okText={'Tak'} cancelText={'Nie'}
  onConfirm={onConfirm}>
  <Button icon={<DeleteOutlined />} disabled={props.disabled} />

  </Popconfirm>
  </>
)
}
