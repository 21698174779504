import React from 'react';
import { Modal } from 'antd';
import { Lx02ExcelInterface } from '../../../../types/lx02Excel.interface';
import { TableLx02 } from './TableLx02';

interface Props {
  open : boolean;
  nav: number;
  handleSetModalOpened: (opened: boolean) => void;
  lx02Data: Lx02ExcelInterface[];
}
export const ModalLx02 = (props: Props) => {

  const handleOk = async () => {
    props.handleSetModalOpened(false);
  }

  const handleCancel = async () => {
    props.handleSetModalOpened(false);
  }




  return <Modal destroyOnClose={true}  bodyStyle={{padding: 10,}} title={`NAV${props.nav} - LX02`} width={750} open={props.open} onCancel={handleCancel} onOk={handleOk}>
      <div style={{ display: 'flex', alignItems: 'center', alignContent: 'flex-end', justifyContent: 'space-evenly', padding: 5 }}>
        <TableLx02 lx02Data={props.lx02Data} />
      </div>
  </Modal>
}
