import React from 'react';
import { Button, message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { fetcher } from '../../../../extras/fetcher';


interface Props {
  disabled?: boolean;
  tripNr: number;
  getData: () => void;
}
export const DeleteTrip = (props: Props) => {

  const [messageApi, contextHolder] = message.useMessage();

  const onConfirm = async (e?: React.MouseEvent) => {
    const {result, status} = await fetcher(`ikea/trip/${props.tripNr}`, 'DELETE');

    if(result.success) {

      messageApi.open({
        type: 'success',
        content: `Trip ${props.tripNr} został usunięty!`,
      });

      props.getData();

      return;
    }

      messageApi.open({
        type: 'error',
        content: result.error ? result.error : 'Nieznany błąd!',
      });

  };

  return (<>
    {contextHolder}
    <Popconfirm  placement="leftTop" disabled={props.disabled} title={`Na pewno chcesz usunąć wysyłkę nr ${props.tripNr}`} okText={'Tak'} cancelText={'Nie'}
                onConfirm={onConfirm}>
      <Button icon={<DeleteOutlined />} disabled={props.disabled} />

    </Popconfirm>
    </>
  )
}
