import React, { useContext } from 'react';
import { getMenuItem, MenuItem } from '../../extras/getMenuItem';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, notification, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { LoggedInContext } from '../../contexts/loggedIn.context';
import Cookies from 'universal-cookie';
import { fetcher } from '../../extras/fetcher';

interface Props {}



export const UserMenu = (props: Props) => {

  const [info, notificationHolder] = notification.useNotification();
  const loggedInContext = useContext(LoggedInContext);
  const cookies = new Cookies();



  const logout = async () => {
    info.open({
      message: 'Trwa wylogowywanie...',
      description:
        <><p>Trwa usuwanie danych autoryzacyjnych z systemu.</p><p>Za chwilę zostaniesz przekierowany na stronę logowania..</p><div style={{textAlign: 'center'}}><Spin size={'large'}/></div></>,
      icon: <LogoutOutlined style={{ color: '#ef343a' }} />,
      duration: 3
    });


    //await cookies.remove('token');
    await fetcher('auth/logout');



    setTimeout(() => {
      cookies.remove('token');
      loggedInContext?.setLoggedIn(false);
    },2000);

  };

  const userMenuItems: MenuItem[] = [
    getMenuItem(<Link to={'/profile/settings/'}>Ustawienia</Link>, '1', <SettingOutlined />),
    getMenuItem(<a onClick={logout}>Wyloguj</a>, '2', <LogoutOutlined />),
  ];


  return (
    <>
      {notificationHolder}
    <Menu items={userMenuItems} mode={'vertical'}></Menu>
    </>
  )
}
