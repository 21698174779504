import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Progress, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { fetcher } from '../../../../../extras/fetcher';
import moment from 'moment';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import { Link } from 'react-router-dom';
import { MainBreadcrumbItem } from '../../../../MainBreadcrumb/MainBreadcrumb';
import { TableHeader } from '../TableHeader';
import { TableFooter } from '../TableFooter';
import { ModalMoveMultipleLocations } from '../ModalMoveMultipleLocations';
import { SelectedMenuContext } from '../../../../../contexts/selectedMenu.context';
import { exportToExcel } from '../../../../../extras/exportToExcel';
import { myDateFormat } from '../../../../../extras/myDateFormat';
import { PermError } from '../../../NotFoundPage/PermError';
import { myNumberSorter, myStringSorter } from '../../../../../extras/sorters';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}



export const StanReg2 = (props: Props) => {


  const [tableData, setTableData] = useState<genRack[]>([]);
  const [rackType, setRackType] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [notVirtual, setNotVirtual] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<genRack[]>([]);
  const [modalMultipleLocMoveOpened, setModalMultipleLocMoveOpened] = useState<boolean>(false);

  const { windowHeight } = useWindowDimensions();
  const selectedMenuContext = useContext(SelectedMenuContext);

  const [permError, setPermError] = useState<boolean>(false);


  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[]=[{
        href: '',
        key: 'Ikea',
        label: 'Ikea',
      },
    {
      href: '',
      key: 'Stan',
        label: 'Stan',
    },

      {
        href: '/stock/rack-total/',
        key: 'Lokalizacje2',
        label: 'Lokalizacje2',
        menu: [
          {
            href: '/stock/rack/',
            label: 'Lokalizacje',
          },
          {
            href: '/stock/rack-total/',
            label: 'Lokalizacje2',
          },
          {
            href: '/stock/material/',
            label: 'Materiał',
          },
        ]

      },
  ];


    props.handleSetMainTitle('Lokalizacje Magazynowe');
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['rack-total']);

  },[]);


  interface genRack {
    /* Unikalny numer tylko dla React */
    uniqueId: number;
    /*id regału*/
    id: number;
    /*typ lokalizacji (id)*/
    mag_type: string;
    /* nazwa lokalizacji */
    name: string;
    kod_ikea: string;
    kod_elica: string;
    /* ilość sztuk kodu w lokalizacji */
    totalPcs: number;
    /* ilość palet w lokalizacji danego kodu */
    plt: number;
    typeName: string;

    /* zajęta przestrzeń lokalizacji procentowo przez dany kod */
    saturation: number;
    /* zajęta przestrzeń lokalizacji procentowo przez wszystkie kody łącznie */
    totalSaturation: number;
    lastInventoryDate: Date;
    lastInventoryDateFormatted: any;
  }

  const columns: ColumnsType<genRack> = [
    {
      title: 'Typ',
      dataIndex: 'mag_type',
      width: 80,
      filterMode: 'tree',
      filters: [
      ...(rackType.map((ikeaMagType: any) => {
          return {
            text: ikeaMagType.name,
            value: String(ikeaMagType.id),
          };
        })),
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      //@ts-ignore
      onFilter: (value: string, record) => record.mag_type.indexOf(value) === 0,

      sorter: (a, b) => Number(a.mag_type) - Number(b.mag_type),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record, index) => {
        const currentType: any = rackType.find((ikeaMagType: any) =>ikeaMagType.id === Number(record.mag_type));
        return  <Tooltip key={index} placement="right" title={currentType.desc}>{record.mag_type}</Tooltip>
      },

    },
    {
      title: 'Nazwa',
      width: 200,
      dataIndex: 'name',
      filterMode: 'tree',
      defaultSortOrder: 'descend',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myStringSorter(a.name, b.name),

      //@ts-ignore
      onFilter: (value: string, record) => record.name.indexOf(value) === 0,
      filters: [
        {
          text: 'Lokalizacja A',
          value: 'A',
        },
        {
          text: 'Lokalizacja B',
          value: 'B',
        },
        {
          text: 'Regały',
          value: '010',
        },
      ],
      filterSearch: true,

      render: (value) => {
        return (
          <Link to={`/stock/rack/${value}`}>{value}</Link>
        )
      },

    },
    {
      title: 'Kod Klienta',
      width: 150,
      dataIndex: 'kod_ikea',
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,
      filters: [
        ...Array.from(new Set((tableData.map((code) => code.kod_ikea)))).map((code) => {

            return {
              text: code,
              value: code,
            };

        }).sort((a,b) => myStringSorter(a.value, b.value)).filter((x) => x.value != ''),
      ],

      onFilter: (value, record) => record.kod_ikea.includes((value) as string),

      sorter: (a, b) => myStringSorter(a.kod_ikea, b.kod_ikea),
      render: (value, record) => {
        return (
          <Link to={`/stock/material/${record.kod_elica}`}>{record.kod_ikea}</Link>
        )
      },
    },

    {
      title: 'Kod',
      width: 150,
      dataIndex: 'kod_elica',
      sortDirections: ['descend', 'ascend'],

      filterSearch: true,
      filters: [
        ...Array.from(new Set((tableData.map((code) => code.kod_elica)))).map((code) => {

          return {
            text: code,
            value: code,
          };

        }).sort((a,b) => myStringSorter(a.value, b.value)).filter((x) => x.value != ''),
      ],

      onFilter: (value, record) => record.kod_elica.includes((value) as string),

      sorter: (a, b) => myStringSorter(a.kod_elica, b.kod_elica),
      render: (value, record) => {
        return (
          <Link to={`/stock/material/${record.kod_elica}`}>{record.kod_elica}</Link>
        )
      },
    },


    {
      title: 'Sztuk',
      width: 120,
      dataIndex: 'totalPcs',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => myNumberSorter(a.totalPcs, b.totalPcs),
    },

    {
      title: 'Palet',
      width: 120,
      dataIndex: 'plt',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => myNumberSorter(a.plt, b.plt),
    },

    {
      title: 'Wypełnienie',
      width: 180,
      dataIndex: 'saturation',
      sortDirections: ['descend', 'ascend'],
      render: (value, record, index) => <div style={{borderWidth: 1, borderColor: 'red', textAlign: 'center', display: 'flex'}} key={index}><Progress percent={record.saturation} showInfo={false} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} /><div style={{display: 'inline', width: '50px'}}>{record.saturation}%</div></div>,
      sorter: (a, b) => myNumberSorter(a.saturation, b.saturation),
    },

    {
      title: 'Wypełnienie Całkowite',
      width: 180,
      dataIndex: 'totalSaturation',
      sortDirections: ['descend', 'ascend'],
      render: (value, record, index) => <div style={{borderWidth: 1, borderColor: 'red', textAlign: 'center', display: 'flex'}} key={index}><Progress percent={record.totalSaturation} showInfo={false} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} /><div style={{display: 'inline', width: '50px'}}>{record.totalSaturation}%</div></div>,
      sorter: (a, b) => myNumberSorter(a.totalSaturation, b.saturation),
    },


    {
      title: 'Ostatnia Inwentaryzacja',
      dataIndex: 'lastInventoryDateFormatted',
      sortDirections: ['descend', 'ascend'],
      width: 200,
      sorter: (a, b) => {
        const dataA = a.lastInventoryDate === null ? 0 : moment(a.lastInventoryDate).unix();
        const dataB = b.lastInventoryDate === null ? 0 : moment(b.lastInventoryDate).unix();
        return dataA - dataB;
      }
    },

  ];


  const getData = async () => {
    await setLoading(true);

    const data = notVirtual ? await fetcher('ikea/all-racks') : await fetcher('ikea/all-racks/v');

    if (data.status === 403) {
      setLoading(false);
      setPermError(true);
      return;
    }


    const dataRackType= await fetcher('ikea/rack-type');

    await setRackType(dataRackType.result);




    let remapData: genRack[] = [];

    let uniqueId = 0;

    await data.result.map((record: any) => {


      if(record.total.length > 0) {
        record.total.map((total: any) => {

          remapData.push({
                uniqueId: ++uniqueId,
                id: record.id,
                kod_elica: total.kod_elica,
                kod_ikea: total.kod_ikea,
                mag_type: String(record.mag_type),
                name: record.name,
                plt: total.plt,
                saturation: Math.round(total.saturation*100),
                totalPcs: total.totalPcs,
                totalSaturation: Math.round(record.totalSaturation*100),
                typeName: total.typeName,
                lastInventoryDate: record.lastInventoryDate,
                lastInventoryDateFormatted: record.lastInventoryDate !== null ? moment(record.lastInventoryDate).format('DD/MM/YYYY HH:mm:ss') : '',
              })
        });
      } else {
        remapData.push({
          uniqueId: ++uniqueId,
          id: record.id,
          kod_elica: '',
          kod_ikea: '',
          mag_type: String(record.mag_type),
          name: record.name,
          plt: 0,
          saturation: 0,
          totalPcs: 0,
          totalSaturation: Math.round(record.totalSaturation*100),
          typeName: '',
          lastInventoryDate: record.lastInventoryDate,
          lastInventoryDateFormatted: record.lastInventoryDate !== null ? moment(record.lastInventoryDate).format('DD/MM/YYYY HH:mm:ss') : '',
        })
      }
    });




    setTableData(remapData);
    setTableSelected([]);
    setSelectedRowKeys([]);

    await setLoading(false);

  }


  useEffect(() => {
    getData();
  },[notVirtual]);


/*  const onChange: TableProps<genRack>['onChange'] = (pagination, filters, sorter, extra) => {
   /// console.log('params', pagination, filters, sorter, extra);
  };*/

  const handleExcelClick = (data: genRack[]) => {
    const dataToExport = data.map((data: genRack,index) => {

      return {
        id: index+1,
        typ: data.mag_type,
        lokalizacja: data.name,
        'Kod Klienta': data.kod_ikea,
        'Kod': data.kod_elica,
        Sztuk: data.totalPcs,
        Palet: data.plt,
        'Wypełnienie': data.saturation,
        'Wypełnienie Całkowite': data.totalSaturation,
        ostatnia_inwentaryzacja: myDateFormat(data.lastInventoryDate),
     }
    })
    exportToExcel(dataToExport);
  };

  const handleSetModalMultiplePalletsOpened = (opened: boolean) => {
    setModalMultipleLocMoveOpened(opened);
  }

  const handleMovePallet = () => {
    handleSetModalMultiplePalletsOpened(true);
  }




  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: genRack[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };


  const CheckBoxNotVirtual: JSX.Element = <Checkbox checked={notVirtual} onChange={(e) => setNotVirtual(e.target.checked)}>Nie wyświetlaj wirtualnych lokalizacji</Checkbox>


  if(permError) {
    return <PermError/>
  }

  return (
    <>
    <Table
      rowKey={'uniqueId'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={() => <TableHeader tableDataToPrint={{data: tableSelected.length === 0 ? tableData : tableSelected, allColumns: columns, selectedColumns: ['Nazwa','Kod Klienta', 'Kod','Sztuk', 'Palet']}} getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} handleMovePalletClick={handleMovePallet} additional={CheckBoxNotVirtual} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight*.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter handleMovePalletClick={handleMovePallet} selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />

      <ModalMoveMultipleLocations open={modalMultipleLocMoveOpened} racksId={tableSelected.map(item => item.id )} handleSetModalOpened={handleSetModalMultiplePalletsOpened} handleGetData={getData} />
    </>
      );
}
