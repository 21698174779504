import React, { useEffect, useState } from 'react';
import { fetcher } from '../../../../extras/fetcher';
import { Alert, AutoComplete, Button, Modal, Progress, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import { ColumnsType } from 'antd/es/table';
import { IkeaDaneInterface } from '../../../../types/ikeaDane.interface';
import { PermError } from '../../NotFoundPage/PermError';

interface Props {
  open : boolean;
  palletsId:  number[];
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;
}
export const ModalMovePalletsFromDelivery = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [dataRack, setDataRack] = useState([]);
  const [pltData,setPltData] = useState<TableData[]>([]);
  const [newRackName, setNewRackName] = useState<string>('');

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [pltMoved, setPltMoved] = useState<number>(0);
  const [pltError, setPltError] = useState<number>(0);

  const [permError, setPermError] = useState<boolean>(false);


  interface TableData {
    id: number;
    kod: string;
    kod_klienta: string;
    sscc: string;
    udc: number;
    status: any;
    rackName: string;
  }

  useEffect(() => {

    (async () => {
      setLoading(true);
      const dataRack = await fetcher('ikea/rack');

      if(dataRack.status === 403) {
        setPermError(true);
        return;
      }

      setDataRack(dataRack.result);


      const myPallets = await (await (props.palletsId.map(async (id) => {

        const oneData = await fetcher(`ikea/pallet/id/${id}`);

        const myPallet :IkeaDaneInterface = oneData.result;

        return {
          id: myPallet.id,
          kod: myPallet.ikeaCode.kod_elica,
          kod_klienta: myPallet.ikeaCode.kod_ikea,
          sscc: myPallet.ikea2,
          status: '',
          udc: myPallet.udc,
          rackName: myPallet.ikeaMag.name,
        }
      })));

      await setPltData(await Promise.all(myPallets));

      await setPltMoved(0);
      await setPltError(0);
      setNewRackName('');

      setLoading(false);
    })();

  },[props.palletsId]);

  const onChange = (value: string) => {
    setNewRackName(value.toUpperCase());
  }


  const handleOk = async () => {
    props.handleSetModalOpened(false);
    props.handleGetData();
  }


  const tableColumns: ColumnsType<TableData> = [
    {
      title: 'Lokalizacja źródłowa',
      dataIndex: 'rackName',
      fixed: true,
      width: 160,
    },
    {
      title: 'Kod',
      dataIndex: 'kod',
      width: 150,
    },
    {
      title: 'Kod Klienta',
      dataIndex: 'kod_klienta',
      width: 125,
    },
    {
      title: 'SSCC',
      dataIndex: 'sscc',
      width: 300,
    },
    {
      title: 'UDC',
      dataIndex: 'udc',
      width: 125,
    },
    {
      title: 'Status',
      dataIndex: 'status',
    }



  ];


  const startMove = async () => {
    setLoading(true);
    setPltError(0);
    setPltMoved(0);
    const newArr = await ([...pltData.map(async (onePallet) => {
      // move pallet

      const data = await fetcher('ikea/pallet/move-from-delivery','PATCH', {ikea2: onePallet.sscc, newRackName});

      if(data.result.success) {
        setPltMoved((count) => count+1);
        return {
          ...onePallet,
          status: data.result.error ? <Alert message={data.result.error} banner type={'warning'} showIcon /> : <Alert message={'OK'} banner type={'success'} showIcon />,
        }
      } else {

        setPltError((count) => count+1);
        return {
          ...onePallet,
          status: <Alert message={data.result.error} banner type={'error'} showIcon />,
        }

      }


    })]);

    setPltData(await Promise.all(newArr));

    setLoading(false);

  };

  return <Modal bodyStyle={{padding: 10}} width={'70%'} title="Przenoszenie Palet" open={props.open} cancelButtonProps={{ style: { display: 'none' } }} okText={'Zamknij'} onOk={handleOk} onCancel={handleOk} destroyOnClose={true} confirmLoading={moveLoading}>
    {permError ? <PermError/> : <>
      <Title level={5}>Wybierz nową lokalizację</Title>
      <AutoComplete
        style={{width: 250, margin: 10}}
        showSearch
        placeholder="Wybierz lokalizacje docelową"
        optionFilterProp="children"
        showArrow={false}

        onChange={onChange}
        value={newRackName}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
          ...dataRack.map((data: any) => {
            return {
              label: data.name,
              value: data.name,
            };
          })
        ]}
      />


      <Table
        loading={loading}
        rowKey={'id'}
        columns={tableColumns}
        dataSource={pltData}
        pagination={false}
        scroll={{ y: 250 }}
        size={'small'}
        bordered
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align={'center'}><Progress type="circle" percent={(100/pltData.length)*pltMoved} width={45} /></Table.Summary.Cell>
              <Table.Summary.Cell colSpan={2} index={1}>Ilość: {pltData.length}<br/>Przeniesionych: {pltMoved}<br/>Liczba błędów: {pltError}<br/></Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1} index={2} align={'center'}><Button disabled={pltMoved === pltData.length && pltMoved !== 0  } onClick={startMove}>Rozpocznij przenoszenie</Button></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </>}
  </Modal>;





}
