import React, { useContext, useEffect, useState } from 'react';
import { Input, message, Modal, Table } from 'antd';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import { Link, useParams } from 'react-router-dom';
import { MainBreadcrumbItem } from '../../../../MainBreadcrumb/MainBreadcrumb';
import { ColumnsType } from 'antd/es/table';
import { IkeaDaneInterface } from '../../../../../types/ikeaDane.interface';
import { fetcher } from '../../../../../extras/fetcher';
import moment from 'moment/moment';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { TerminalUsersInterface } from '../../../../../types/terminalUsersInterface';
import { ModalMoveOnePallet } from '../ModalMoveOnePallet';
import { TableHeader } from '../TableHeader';
import { TableFooter } from '../TableFooter';
import { ModalMoveMultiplePallets } from '../ModalMoveMultiplePallets';
import { ModalIkeaIdDetails } from '../ModalIkeaIdDetails';
import { SelectedMenuContext } from '../../../../../contexts/selectedMenu.context';
import { exportToExcel } from '../../../../../extras/exportToExcel';
import { myDateFormat } from '../../../../../extras/myDateFormat';
import { PermError } from '../../../NotFoundPage/PermError';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}

export const TableOneRack = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const { windowHeight } = useWindowDimensions();
  const [oneRackData, setOneRackData] = useState<DataType[]>([]);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [modalMultipleMoveOpened, setModalMultipleMoveOpened] = useState<boolean>(false);
  const [currentPalletId, setCurrentPalletId] = useState<number | null>(null);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [modalIkeaDetailsOpened, setModalIkeaDetailsOpened] = useState<boolean>(false);

  const linkParams =  useParams();

  const selectedMenuContext = useContext(SelectedMenuContext);

  const [permError, setPermError] = useState<boolean>(false);


  const handleSetModalIkeaDetailsOpened = (opened: boolean) => {
    setModalIkeaDetailsOpened(opened);
  }

  const handleSetModalOnePalletOpened = (opened: boolean) => {
    setModalOpened(opened);
    if(!opened) {
      setCurrentPalletId(null);
    }
  }

  const handleSetModalMultiplePalletsOpened = (opened: boolean) => {
    setModalMultipleMoveOpened(opened);
  }



  const getData = async () => {
    await fetcher(`ikea/rack/${linkParams.rackName}/all`)
      .then(res => {
        //setOneRackData(res.result);


        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }


        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        const ikeaDane: IkeaDaneInterface[] = res.result;

        const data : DataType[] = ikeaDane.map(({ikea2, ikeaCode,mag_date, udc, date,date_stamp, terminalUsers, id  }) => {

          return {
            id,
            ikea2,
            kod_elica: ikeaCode.kod_elica,
            kod_ikea: ikeaCode.kod_ikea,
            magDate: mag_date,
            regDate: date,
            pcs: ikeaCode.ilosc,
            udc,
            week: date_stamp,
            terminalUsers: terminalUsers!,
          };
        });

        setTableSelected([]);
        setSelectedRowKeys([]);
        setOneRackData(data);

        setLoading(false);
      });
  }

  useEffect(() => {

    setLoading(true);
    if(!linkParams.rackName) {
      return;
    }
    getData();
    props.handleSetMainTitle(`Lokalizacja: ${linkParams.rackName}`);
    selectedMenuContext?.setSelectedKey(['rack']);

    const breadcrumbItems: MainBreadcrumbItem[] =[{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Stan',
        label: 'Stan',
      },
      {
        href: '/stock/rack/',
        key: 'Lokalizacje',
        label: 'Lokalizacje',
        menu: [
          {
            href: '/stock/rack/',
            label: 'Lokalizacje',
          },
          {
            href: '/stock/rack-total/',
            label: 'Lokalizacje2',
          },
          {
            href: '/stock/material/',
            label: 'Materiał',
          },
        ]

      },

      {
        href: `/stock/rack/${linkParams.rackName}`,
        key: linkParams.rackName,
        label: linkParams.rackName,
      },

    ];

    props.setBreadcrumbHandler(breadcrumbItems);

  },[linkParams.rackName]);


  interface DataType {
    id: number;
    kod_elica: string;
    kod_ikea: string;
    pcs: number;
    ikea2: string;
    udc: number;
    week: number;
    regDate: Date | string;
    magDate: Date | null | string;
    terminalUsers: TerminalUsersInterface;
  }


  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm } : FilterDropdownProps) : JSX.Element => {

    return <div><Input
      allowClear
      value={selectedKeys[0]}
      onChange={(e) => {setSelectedKeys(e.target.value ? [e.target.value] : []);

        confirm({
          closeDropdown: false,
        });

      }}
      onPressEnter={() => confirm()}
    ></Input></div>

  }


  const handlePalletMoveClick = () => {

    if(tableSelected.length === 1) {
      handleSetModalOnePalletOpened(true);
      setCurrentPalletId(tableSelected[0].id);
      return;
    }

    if(tableSelected.length > 1) {
      ///Przenoszenie kilku pozycji
      handleSetModalMultiplePalletsOpened(true);
      return;
    }


    if(tableSelected.length === 0) {
      ///Nie zaznaczono żadnych pozycji
      message.open({
        type: 'warning',
        content: 'Żadna pozycja nie została zaznaczona!',
      });
      return;
    }



  }


  const columns : ColumnsType<DataType>= [
    {
      width: 130,
      title: 'Kod',
      dataIndex: 'kod_elica',
      key: 'kod_elica',
      sorter: (a,b) => a.kod_elica.localeCompare(b.kod_elica, undefined, {
        numeric: true,
      }),

      onFilter: (value, record) => record.kod_elica.toLowerCase().includes(String(value).toLowerCase()),
      filters: [
      ...Array.from(new Set(oneRackData.map(row => row.kod_elica))).map((kod_elica) => {
        return {
          text: kod_elica,
          value: kod_elica,
        }
      }),
      ],
      filterSearch: true,
      //filterDropdown,
      render: (value, record, index) => <Link to={`/stock/material/${record.kod_elica}`}>{value}</Link>
    },
    {
      width: 130,
      title: 'Kod Klienta',
      dataIndex: 'kod_ikea',
      key: 'kod_ikea',
      sorter: (a,b) => a.kod_ikea.localeCompare(b.kod_ikea, undefined, {
        numeric: true,
      }),

      onFilter: (value, record) => record.kod_ikea.indexOf(String(value)) === 0,
      filters: [
        ...Array.from(new Set(oneRackData.map(row => row.kod_ikea))).map((kod_ikea) => {
          return {
            text: kod_ikea,
            value: kod_ikea,
          }
        }),
      ],
      filterSearch: true,
      render: (value, record, index) => <Link to={`/stock/material/${record.kod_elica}`}>{value}</Link>
    },
    {
      width: 80,
      title: 'Szt',
      dataIndex: 'pcs',
      key: 'pcs',
      sorter: (a,b) => a.pcs - b.pcs,

      onFilter: (value, record) => record.pcs === value,
      filters: [
        ...Array.from(new Set(oneRackData.map(row => row.pcs))).map((pcs) => {
          return {
            text: String(pcs),
            value: pcs,
          }
        }),
      ],
      filterSearch: true,
    },
    {
      width: 180,
      title: 'SSCC',
      dataIndex: 'ikea2',
      key: 'ikea2',
      sorter: (a,b) => a.ikea2.localeCompare(b.ikea2, undefined, {
        numeric: true,
      }),

      onFilter: (value, record) => record.ikea2.toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,
      render: (value, record, index) => <a onClick={() => {setCurrentPalletId(record.id); handleSetModalIkeaDetailsOpened(true)}}>{value}</a>
    },
    {
      width: 100,
      title: 'UDC',
      dataIndex: 'udc',
      key: 'udc',

      sorter: (a,b) => a.udc - b.udc,

      onFilter: (value, record) => String(record.udc).toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,
    },
    {
      width: 100,
      title: 'tydzień',
      dataIndex: 'week',
      key: 'week',

      sorter: (a,b) => a.week - b.week,

      onFilter: (value, record) => String(record.week).toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,
    },
    {
      width: 200,
      title: 'Data streczowania',
      dataIndex: 'regDate',
      key: 'regDate',

      render: date => date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '',

      sorter: (a, b) => {
        const dataA = a.regDate === null ? 0 : moment(a.regDate).unix();
        const dataB = b.regDate === null ? 0 : moment(b.regDate).unix();
        return dataA - dataB;
      },
      onFilter: (value, record) => String(moment(record.regDate).format('DD/MM/YYYY HH:mm:ss')).toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,

    },
    {
      width: 250,
      title: 'Rejestracja przez',
      key: 'user',
      sorter: (a, b) => {
        if(!a.terminalUsers) {
          return 0;
        }
        const aName = a.terminalUsers.name ? a.terminalUsers.name : '';
        const bName = b.terminalUsers.name ? b.terminalUsers.name : '';
        return aName.localeCompare(bName, undefined, {
        numeric: false,
      })
    },

      onFilter: (value, record) => record.terminalUsers.name.includes(String(value)),

      //@ts-ignore
      filters: [
        ...Array.from(new Set(oneRackData.map(row => {
          if(row.terminalUsers) {
            return row.terminalUsers.name
          }
        }))).map((name) => {
          return {
            text: name,
            value: name,
          }
        }),
      ],

      render: (value,record) => <>{record.terminalUsers ? record.terminalUsers.name : ''}</>,

      filterSearch: true,
    },
    {
      width: 200,
      title: 'Data magazynowania',
      dataIndex: 'magDate',
      key: 'magDate',
      render: date => date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '',

      sorter: (a, b) => {
        const dataA = a.magDate === null ? 0 : moment(a.magDate).unix();
        const dataB = b.magDate === null ? 0 : moment(b.magDate).unix();
        return dataA - dataB;
      },
      onFilter: (value, record) => String(moment(record.magDate).format('DD/MM/YYYY HH:mm:ss')).toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,
    },
  ];



  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    //  console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
/*    getCheckboxProps: (record: DataType) => ({
      disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
      name: record.kod_elica,
    }),*/
  };


  const handleExcelClick = (data: DataType[]) => {
    const dataToExport = data.map((data: DataType,index) => {

      return {
        id: index+1,
        kod: data.kod_elica,
        kod_klienta: data.kod_ikea,
        ilość: data.pcs,
        SSCC: data.ikea2,
        udc: data.udc,
        tydzień: data.week,
        'Data streczowania': myDateFormat(data.regDate),
        'Rejestracja przez': data.terminalUsers ? data.terminalUsers.name : '',
        'Data magazynowania': myDateFormat(data.magDate),
      }
    })
    exportToExcel(dataToExport);
  };

  if(permError) {
    return <PermError/>
  }

  return (<>
    <Table columns={columns}
           scroll={{ y: windowHeight*.6}}
           pagination={false}
           loading={loading}
           showHeader={true}
           bordered={true}
           rowKey={'id'}

           title={() => <TableHeader tableDataToPrint={{data: tableSelected.length === 0 ? oneRackData : tableSelected, allColumns: columns, selectedColumns: ['Kod','Kod Klienta', 'Szt','SSCC', 'UDC', 'tydzień']}} getData={getData} handleExcelExportAllClick={() => handleExcelClick(oneRackData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} handleMovePalletClick={handlePalletMoveClick} selectedCount={tableSelected.length}/>}

           footer={() => <TableFooter handleMovePalletClick={handlePalletMoveClick} selectedCount={tableSelected.length} totalCount={oneRackData.length}/> }
           rowSelection={{
             selectedRowKeys,
             type: 'checkbox',
           ...rowSelection,
           }}
           dataSource={oneRackData}
           style={{opacity: 0.9}}
           size={'small'}

           />
    {currentPalletId && <ModalMoveOnePallet handleGetData={getData} handleSetModalOpened={handleSetModalOnePalletOpened} open={modalOpened} palletId={currentPalletId} /> }
      {currentPalletId && <ModalIkeaIdDetails handleGetData={getData} handleSetModalOpened={handleSetModalIkeaDetailsOpened} open={modalIkeaDetailsOpened} palletId={currentPalletId} /> }

      <ModalMoveMultiplePallets open={modalMultipleMoveOpened} palletsId={tableSelected.map(item => item.id)} handleSetModalOpened={handleSetModalMultiplePalletsOpened} handleGetData={getData} />
    </>
  );

}
