import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';
import React, { useContext, useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useParams } from 'react-router-dom';
import { SelectedMenuContext } from '../../../contexts/selectedMenu.context';
import { fetcher } from '../../../extras/fetcher';
import { Button, Card, Descriptions, Modal, Spin } from 'antd';
import { PermError } from '../NotFoundPage/PermError';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { PersonEmployeeInterface } from '../../../types/personEmployee.interface';
import { TableLockers } from './TableLockers';
import { ModalAddLockerToEmployee } from './ModalAddLockerToEmployee';
import { ModalEditEmployeeComments } from './ModalEditEmployeeComments';
import { ModalEditEmployeeName } from './ModalEditEmployeeName';
import { ModalEditEmployeeRfidId } from './ModalEditEmployeeRfidId';
import { ModalEditEmployeeBranch } from './ModalEditEmployeeBranch';
import { ModalEditEmployeeAgreement } from './ModalEditEmployeeAgreement';
import { RfidCalendar } from './Rfid/RfidCalendar';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}


export const OneEmployee = (props: Props) => {


  const [loading, setLoading] = useState(false);
  const { windowHeight } = useWindowDimensions();


  const [permError, setPermError] = useState<boolean>(false);

  const [addLockerOpened, setAddLockerOpened] = useState<boolean>(false);
  const [editCommentsModalOpened, setEditCommentsModalOpened] = useState<boolean>(false);
  const [editEmployeeNameModalOpened, setEditEmployeeNameModalOpened] = useState<boolean>(false);
  const [editEmployeeRfidIdModalOpened, setEditEmployeeRfidIdModalOpened] = useState<boolean>(false);
  const [editEmployeeBranchModalOpened, setEditEmployeeBranchModalOpened] = useState<boolean>(false);
  const [editEmployeeAgreementModalOpened, setEditEmployeeAgreementModalOpened] = useState<boolean>(false);



  const [employee, setEmployee] = useState<PersonEmployeeInterface>();

  const linkParams =  useParams();

  const selectedMenuContext = useContext(SelectedMenuContext);



  const getData = async () => {
    setLoading(true);
    await fetcher(`person/employees/${linkParams.employeeId}`)
      .then(res => {
        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }


        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }


        setEmployee(res.result);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData();
  },[linkParams.employeeId]);

  useEffect(() => {

    props.handleSetMainTitle(`Pracownik - ${employee ? employee.name : ''}`);
    selectedMenuContext?.setSelectedKey(['hr_employees']);

    const breadcrumbItems: MainBreadcrumbItem[] =[{
      href: '',
      key: 'hr',
      label: 'Zarządzanie HR',
    },
      {
        href: '/person/employees/',
        key: 'hr_employees',
        label: 'Pracownicy',
      },

      {
        href: `/person/employees/${linkParams.employeeId}`,
        key: 'hr_employees1',
        label: employee ? employee.name : '',
      },

    ];

    props.setBreadcrumbHandler(breadcrumbItems);

  },[employee]);



  if(permError) {
    return <PermError/>
  }


  return !loading ? <>
    <Card title="Informacje o Pracowniku" bordered={false} style={{opacity: 0.9, marginTop: 20, textAlign: 'left'}} >
      <Descriptions title="" bordered layout={'vertical'} column={2} size={'small'}>
        <Descriptions.Item span={2} label="Imię i Nazwisko">
          {employee?.name}
          <Button style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'} onClick={() => {
            setEditEmployeeNameModalOpened(true);
          }
          }><EditOutlined style={{fontSize: 12}}/></Button>


        </Descriptions.Item>
        <Descriptions.Item label="Oddział">
          {employee?.personBranch.name}
          <Button style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'} onClick={() => {
            setEditEmployeeBranchModalOpened(true);
          }
          }><EditOutlined style={{fontSize: 12}}/></Button>
        </Descriptions.Item>
        <Descriptions.Item label="Rodzaj Umowy">
          {employee?.personAgreement.name}
          <Button style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'} onClick={() => {
            setEditEmployeeAgreementModalOpened(true);
          }
          }><EditOutlined style={{fontSize: 12}}/></Button>

        </Descriptions.Item>
        <Descriptions.Item label="RFID">{employee?.rfidId}
          <Button style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'} onClick={() => {
            setEditEmployeeRfidIdModalOpened(true);
          }
          }><EditOutlined style={{fontSize: 12}}/></Button>

        </Descriptions.Item>

        <Descriptions.Item label="Szafki" style={{flex: 1, justifyContent: 'flex-end'}}>
          <TableLockers data={employee ? employee.personLocker : []} getData={getData} />
          <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10}}>
            <Button onClick={() => setAddLockerOpened(true)} icon={<PlusOutlined />}>Dodaj Szafkę</Button>
          </div>

        </Descriptions.Item>
        <Descriptions.Item label="Komentarz">{employee?.comments}
          <Button style={{ borderRadius: 50, padding: 0, marginLeft: 5, height: 25, width: 25}} type={'default'} onClick={() => {
            setEditCommentsModalOpened(true);
          }
          }><EditOutlined style={{fontSize: 12}}/></Button>


        </Descriptions.Item>




      </Descriptions>
    </Card>

    <Card title="Kalendarz Frekwencji" bordered={false} style={{opacity: 0.9, marginTop: 20, textAlign: 'left'}} >
      {employee && employee.rfidId ? <RfidCalendar rfidId={employee.rfidId} /> : 'Brak rfid.'}
    </Card>

    {employee && <ModalAddLockerToEmployee open={addLockerOpened} handleSetModalOpened={setAddLockerOpened} handleGetData={getData} employeeId={employee.id}/>}
    {employee && <ModalEditEmployeeComments open={editCommentsModalOpened} handleSetModalOpened={setEditCommentsModalOpened} handleGetData={getData} employeeId={employee.id}/>}
    {employee && <ModalEditEmployeeName open={editEmployeeNameModalOpened} handleSetModalOpened={setEditEmployeeNameModalOpened} handleGetData={getData} employeeId={employee.id}/>}
    {employee && <ModalEditEmployeeRfidId open={editEmployeeRfidIdModalOpened} handleSetModalOpened={setEditEmployeeRfidIdModalOpened} handleGetData={getData} employeeId={employee.id}/>}
    {employee && <ModalEditEmployeeBranch open={editEmployeeBranchModalOpened} handleSetModalOpened={setEditEmployeeBranchModalOpened} handleGetData={getData} employeeId={employee.id}/>}
    {employee && <ModalEditEmployeeAgreement open={editEmployeeAgreementModalOpened} handleSetModalOpened={setEditEmployeeAgreementModalOpened} handleGetData={getData} employeeId={employee.id}/>}
    </> : <Spin size={'large'}/>

}
