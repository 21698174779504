import React, { useEffect, useState } from 'react';
import { Input, message, Modal, Spin } from 'antd';
import { PermError } from '../NotFoundPage/PermError';
import { fetcher } from '../../../extras/fetcher';
import { PersonEmployeeInterface } from '../../../types/personEmployee.interface';


interface Props {
  open: boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleGetData: () => void;

  employeeId: string;
}

export const ModalEditEmployeeRfidId = (props: Props) => {

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [permError, setPermError] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<PersonEmployeeInterface>();


  const getData = async () => {
    await fetcher(`person/employees/${props.employeeId}`)
      .then(res => {
        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }


        if (res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }



        setEmployeeData(res.result);

      })

  }

  useEffect(() => {
    setLoading(true);
    getData().then(r => setLoading(false));
  }, [props.employeeId]);


  const handleCancel = async () => {
    props.handleSetModalOpened(false);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {

    setEmployeeData((data) => {
      if (data) {
        return {
          ...data,
          rfidId: Number(e.target.value),
        }
      }

    });
  };

  const handleOk = async () => {
    const data = await fetcher(`person/employees/${props.employeeId}/rfid-id`, 'PUT', {employeeId: props.employeeId, rfidId: employeeData?.rfidId});

    if (data.status === 403) {
      messageApi.error('Brak wymaganych uprawnień!');
    }

    if(data.result.error) {
      messageApi.error(data.result.error);
      return;
    }

    if(data.result.success) {
      messageApi.success('Imię i nazwisko zostało zmienione!');
      props.handleSetModalOpened(false);
      props.handleGetData();
    } else {
      messageApi.error('Nieznany błąd!');
    }

  }

  if(permError) {
    return <PermError/>
  }

  return (
    <Modal bodyStyle={{padding: 10}} title={`Zmiana Imienia i Nazwiska`} open={props.open} onOk={handleOk}
           onCancel={handleCancel} confirmLoading={moveLoading}>
      {
        loading ? <div style={{textAlign: 'center'}}>
          <Spin size="large" />

      </div> : <>
      {permError ? <PermError/> : <>
        <h4>Rfid</h4>
        <Input value={employeeData?.rfidId} onChange={onChangeInput}/>
        {contextHolder}
      </>}

</>
     }
    </Modal>

  );

};
