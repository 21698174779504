import React, { useContext, useEffect, useState } from 'react';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { Checkbox, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { Link } from 'react-router-dom';
import { myDateSorter, myNumberSorter } from '../../../../extras/sorters';
import { fetcher } from '../../../../extras/fetcher';
import { myDateFormat } from '../../../../extras/myDateFormat';
import { exportToExcel } from '../../../../extras/exportToExcel';
import { PermError } from '../../NotFoundPage/PermError';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';
import { checkRule } from '../../../../extras/checkRule';
import { NavListInterface } from '../../../../types/navList.interface';
import { TableHeader } from '../../Ikea/Stan/TableHeader';
import { TableFooter } from '../../Ikea/Stan/TableFooter';
import { NavStatus } from './NavStatus';
import { DeleteNav } from './DeleteNav';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const NavList = (props: Props) => {
  const [tableData, setTableData] = useState<NavListInterface[]>([]);
  const [notOld, setNotOld] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<NavListInterface[]>([]);

  const [modalStatusOpened, setModalStatusOpened] = useState<boolean>(false);
  const [currentTripNr, setCurrentTripNr] = useState<number | null>(null);


  const [modalAddTripOpened, setModalAddTripOpened] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const { windowHeight } = useWindowDimensions();

  const [permError, setPermError] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);

  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[]=[{
      href: '',
      key: 'Nav',
      label: 'Nav',
    },

      {
        href: 'nav/list/',
        key: 'Nav-List',
        label: 'Lista Wysyłek',
      },
    ];


    props.handleSetMainTitle('Lista Wysyłek');
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['nav-list']);

  },[]);




  const columns: ColumnsType<NavListInterface> = [
    {
      title: 'Nav',
      width: 120,
      dataIndex: 'nav',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(tableData.map((arr) => arr.nav))).map((val) => {
          return {
            text: `NAV${val}`,
            value: `NAV${val}`,
          }
        }),
      ],

      onFilter: (value, record) => record.nav.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myNumberSorter(a.nav,b.nav),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record, index) => <Link to={`${record.id}/`}>{`NAV${value}`}</Link>,

    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.status,b.status),

      render: (value, record, index) => <NavStatus key={index} status={record.status} username={record.creditedByUser ? `${record.creditedByUser.firstname} ${record.creditedByUser.lastname}` : ''} />

    },

    {
      title: 'Trip',
      dataIndex: 'tripNr',
      filterMode: 'tree',
      width: 100,
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.tripNr,b.tripNr),

    },

    {
      width: 180,
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myDateSorter(a.createdAt, b.createdAt),

      render: (value, record) => (record as any).createdAt2,

    },

    {
      width: 180,
      title: 'Data wydruku',
      dataIndex: 'printedAt',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myDateSorter(a.printedAt,b.printedAt),
      render: (value, record) => (record as any).printedAt2,

    },

    {
      width: 180,
      title: 'Pobrany w sap',
      dataIndex: 'sapAt',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myDateSorter(a.sapAt,b.sapAt),

      render: (value, record) => (record as any).sapAt2,

    },


    {
      width: 50,
      title: '',
      align: 'center',
      filterMode: 'tree',
      render: (value, record) =>  <DeleteNav key={record.id} disabled={!checkRule('a2',loggedIn?.user?.rule!)} navId={record.id} navNr={record.nav} getData={getData} />

    },

  ];


  const getData = async () => {
    await setLoading(true);

    const data = notOld ? await fetcher('nav/list') : await fetcher('nav/list/all');

    if(data.status === 403) {
      setPermError(true);
      return;
    }

    const myData = await data.result.map((record: NavListInterface) => {

      return {
        ...record,
        createdAt2: myDateFormat(record.createdAt),
        sapAt2: myDateFormat(record.sapAt),
        printedAt2: myDateFormat(record.printedAt),
      }
    });



    setTableData(myData);
    setTableSelected([]);
    setSelectedRowKeys([]);

    await setLoading(false);

  }

  useEffect(() => {
    getData();
  },[notOld]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: NavListInterface[]) => {
      //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  const handleExcelClick = (data: NavListInterface[]) => {





    const dataToExport = data.map((data: NavListInterface,index) => {

      let text = '';

      switch (data.status) {
        case 7:
          text = 'Zakończony';
          break;

        case 6:
          text = 'Błędy (Panattoni)';
          break;

        case 5:
          text = 'Kontrola rozładunku...';
          break;
        case 4:
          text = 'Zaksięgowany';
          break;
        case 3:
          text = 'Błędy księgowania!';
          break;
        case 2:
          text = `Księgowanie...`;
          break;
        case 1:
          text = 'Załadowany';
          break;
        case 0:
          text = 'Utworzony';
      }


     return {
        id: index+1,
        Nav: `NAV${data.nav}`,
        Status: text,
        Trip: data.tripNr ? data.tripNr : '',
        'Data utworzenia': myDateFormat(data.createdAt),
        'Data wydruku': data.printedAt ? myDateFormat(data.printedAt) : '',
        'Pobrany w sap': data.sapAt ? myDateFormat(data.sapAt) : '',
      }
    })
    exportToExcel(dataToExport);

  }


  const handleSetModalStatusOpened = (open: boolean) => {
    setModalStatusOpened(open);
  }

  const onChange: TableProps<NavListInterface>['onChange'] = (pagination, filters, sorter, extra) => {
    /// console.log('params', pagination, filters, sorter, extra);
  };

  const CheckBoxNotOld: JSX.Element = <Checkbox checked={notOld} onChange={(e) => setNotOld(e.target.checked)}>Ukryj nieaktualne</Checkbox>


  if(permError) {
    return <PermError/>
  }

  return  <>
    <Table
      rowKey={'id'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={(records) => <TableHeader getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} additional={<>{CheckBoxNotOld}</>} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} onChange={onChange} style={{opacity: 0.9}} size={'small'} />

{/*    { currentTripNr && <ModalTripStatus open={modalStatusOpened} tripNr={currentTripNr} handleSetModalOpened={handleSetModalStatusOpened} handleGetData={getData} /> }*/}


  </>
}
