import React, { useEffect, useState } from 'react';
import { Badge, BadgeProps, Calendar } from 'antd';
import { Dayjs } from 'dayjs';
import { ClockCircleOutlined } from '@ant-design/icons';

import './RfidCalendar.css';
import { fetcher } from '../../../../extras/fetcher';
import { myTimeFormat } from '../../../../extras/myDateFormat';


interface Props {
  rfidId: number;
}

export const RfidCalendar = (props: Props) => {

  const [currentValue, setCurrentValue] = useState();

  const [currentData, setCurrentData] = useState<DataFormat[]>([]);

  const getData = async (month?: string) => {


    const data = month ? await fetcher(`person/rfid/${props.rfidId}/${month}`) : await fetcher(`person/rfid/${props.rfidId}`);


    setCurrentData(data.result);

  }

  useEffect(() => {
    getData();
  },[]);


  interface DataFormat {
    date: string;
    scanList: string[];
    totalTime: number;
  }


  interface ListaDataInterface {
    type: string;
    content: string;
  }




  const getListData = (value: Dayjs): ListaDataInterface[] => {
    let listData;

    //console.log(value.format('YYYY-MM-DD'));

    currentData.filter((d) => d.date === value.format('YYYY-MM-DD'))
      .map((data) => {
        listData = [
        ...data.scanList.map((scan) => {
            return {
              type: '', content: myTimeFormat(scan),
            }
          }),
          { type: 'success', content: data.totalTime },
        ];
    });

    return listData || [];
  };


  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => {

          if(item.type === '') {
            return <li key={item.content} style={{textAlign: 'center'}}>
              <span><Badge status='success' text={item.content}/></span>
            </li>
          } else {
            return <li key={item.content} style={{textAlign: 'center'}}>
              <span><Badge style={{ color: '#f5222d', padding: 4, marginBottom: 2}} count={<ClockCircleOutlined />} status={item.type as BadgeProps['status']} />{item.content}</span>
            </li>
          }


        })}
      </ul>
    );
  };


  const onChangeHandler = (day: Dayjs) => {
  //  console.log(day.format('YYYY-MM'));
    getData(day.format('YYYY-MM'));
  }

  return <Calendar className={'calendar'} fullscreen={true} onPanelChange={onChangeHandler} mode={'month'} dateCellRender={dateCellRender}/>;


}
