import { DatePicker, DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  getData: (dateString?: string) => void;
  handleSetDate: (dateString: string) => void;
}

export const PhotosDatePicker = (props: Props) => {

  const onChange: DatePickerProps['onChange'] = (date: Dayjs | null, dateString: string) => {
    props.handleSetDate(dateString);
    props.getData(dateString);
  };


  return <DatePicker allowClear={false} defaultValue={dayjs()} onChange={onChange} />

}
