import { Button, Table } from 'antd';
import { TableHeader } from '../../Ikea/Stan/TableHeader';
import { TableFooter } from '../../Ikea/Stan/TableFooter';
import React, { useContext, useState } from 'react';
import { NavListInterface } from '../../../../types/navList.interface';
import { checkRule } from '../../../../extras/checkRule';
import { PlusOutlined } from '@ant-design/icons';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';
import { ModalAddDelivery } from './ModalAddDelivery';
import { NavDeliveryInterface } from '../../../../types/navDelivery.interface';
import { ColumnsType } from 'antd/es/table';
import { myNumberSorter } from '../../../../extras/sorters';
import { ModalAddSapPositions } from './ModalAddSapPositions';
import { ModalDeliveryPosition } from './ModalDeliveryPosition';
import { ModalAddTrip } from './ModalAddTrip';
import { DeleteDelivery } from './DeleteDelivery';

interface Props {
 getData: () => void;
 navList: NavListInterface;
 navDelivery: NavDeliveryInterface[];
 loading: boolean;
}
export const DeliveryListTable = (props: Props) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const loggedIn = useContext(LoggedInContext);

  const [modalAddDeliveryOpened, setModalAddDeliveryOpened] = useState(false);
  const [modalAddTripOpened, setModalAddTripOpened] = useState(false);

  const [modalAddSapPositionOpened, setModalAddSapPositionOpened] = useState(false);
  const [modalDeliveryPositionOpened, setModalDeliveryPositionOpened] = useState(false);

  const [currentDelivery, setCurrentDelivery] = useState<NavDeliveryInterface>();



  const columns: ColumnsType<NavDeliveryInterface> = [
    {
      title: 'Delivery',
      width: 120,
      dataIndex: 'deliveryNr',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(props.navDelivery.map((arr) => arr.deliveryNr))).map((val) => {
          return {
            text: val,
            value: val,
          }
        }),
      ],

      onFilter: (value, record) => record.deliveryNr.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myNumberSorter(a.deliveryNr,b.deliveryNr),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record, index) => <Button onClick={() => {
          setCurrentDelivery(record);
          setModalDeliveryPositionOpened(true);
        }
      } type={'link'}>{value}</Button>,

    },
    {
      title: 'Pozycje',
      filterMode: 'tree',
      width: 180,
      align: 'center',

      render: (value, record) => <Button danger={record.navPosition.length === 0} disabled={!checkRule('c2',loggedIn?.user?.rule!)} onClick={() => {
        setCurrentDelivery(record);
        setModalAddSapPositionOpened(true);
      }} size={'small'} icon={<PlusOutlined />}>{record.navPosition.length === 0 ? 'Dodaj Pozycję SAP' : 'Zmień pozycję SAP'}</Button>,

    },


    {
      width: 50,
      title: '',
      align: 'center',
      filterMode: 'tree',
      render: (value, record) =>  <DeleteDelivery deliveryId={record.id} navId={props.navList.id} disabled={!checkRule('c2',loggedIn?.user?.rule!)} deliveryNr={Number(record.deliveryNr)} getData={props.getData} />,

    },

  ];





  const ButtonAddDelivery: JSX.Element =  <Button disabled={!checkRule('c2',loggedIn?.user?.rule!)} onClick={() => setModalAddDeliveryOpened(true)} size={'small'} icon={<PlusOutlined />}>Dodaj Delivery</Button>
  const ButtonAddTrip: JSX.Element =  <Button disabled={!checkRule('c2',loggedIn?.user?.rule!)} onClick={() => setModalAddTripOpened(true)} size={'small'} icon={<PlusOutlined />}>{props.navList.tripNr ? `Trip: ${props.navList.tripNr} (Zmień)` : 'Dodaj Trip'}</Button>


  return <>

    <Table
    rowKey={'id'}
    title={(records) => <TableHeader getData={props.getData} additional={<>{ButtonAddDelivery}{ButtonAddTrip}</>} selectedCount={selectedRowKeys.length}/>} columns={columns} pagination={false} loading={props.loading} showHeader={false} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={props.navDelivery.length}/>} dataSource={props.navDelivery} style={{opacity: 0.9}} size={'small'} />

    <ModalAddDelivery open={modalAddDeliveryOpened} handleSetModalOpened={setModalAddDeliveryOpened} handleGetData={props.getData} />
    <ModalAddTrip open={modalAddTripOpened} handleSetModalOpened={setModalAddTripOpened} handleGetData={props.getData} />
    {currentDelivery && <ModalAddSapPositions open={modalAddSapPositionOpened} navId={props.navList.id} deliveryId={currentDelivery.id}
                           handleSetModalOpened={setModalAddSapPositionOpened} getData={props.getData}/>}

    {
      currentDelivery && <ModalDeliveryPosition loading={props.loading} deliveryNr={currentDelivery.deliveryNr} navPosition={currentDelivery.navPosition} open={modalDeliveryPositionOpened} handleSetModalOpened={setModalDeliveryPositionOpened} getData={props.getData} />
    }

  </>
}
