import React, { useContext, useEffect, useState } from 'react';
import { MainBreadcrumbItem } from '../../../../MainBreadcrumb/MainBreadcrumb';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import { Link, useParams } from 'react-router-dom';
import { TerminalUsersInterface } from '../../../../../types/terminalUsersInterface';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { Checkbox, Input, message, Modal, Table } from 'antd';
import { TableHeader } from '../TableHeader';
import { TableFooter } from '../TableFooter';
import { ModalMoveOnePallet } from '../ModalMoveOnePallet';
import { ModalMoveMultiplePallets } from '../ModalMoveMultiplePallets';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { fetcher } from '../../../../../extras/fetcher';
import { IkeaDaneInterface } from '../../../../../types/ikeaDane.interface';
import { ModalIkeaIdDetails } from '../ModalIkeaIdDetails';
import { SelectedMenuContext } from '../../../../../contexts/selectedMenu.context';
import { myDateFormat } from '../../../../../extras/myDateFormat';
import { exportToExcel } from '../../../../../extras/exportToExcel';
import { PermError } from '../../../NotFoundPage/PermError';
import { myStringSorter } from '../../../../../extras/sorters';


interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const TableOneCode = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const { windowHeight } = useWindowDimensions();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [currentPalletId, setCurrentPalletId] = useState<number | null>(null);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);
  const [tableData, setTableData] = useState<DataType[]>([]);

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [modalMultipleMoveOpened, setModalMultipleMoveOpened] = useState<boolean>(false);

  const [notVirtual, setNotVirtual] = useState<boolean>(true);
  const [modalIkeaDetailsOpened, setModalIkeaDetailsOpened] = useState<boolean>(false);

  const linkParams =  useParams();

  const selectedMenuContext = useContext(SelectedMenuContext);

  const [permError, setPermError] = useState<boolean>(false);

  const handleSetModalOnePalletOpened = (opened: boolean) => {
    setModalOpened(opened);
    if(!opened) {
      setCurrentPalletId(null);
    }
  }

  const handleSetModalMultiplePalletsOpened = (opened: boolean) => {
    setModalMultipleMoveOpened(opened);
  }


  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm} : FilterDropdownProps) : JSX.Element => {

    return <div><Input
      allowClear
      value={selectedKeys[0]}
      onChange={(e) => {setSelectedKeys(e.target.value ? [e.target.value] : []);

        confirm({
          closeDropdown: false,
        });

      }}
      onPressEnter={() => confirm()}
    ></Input></div>

  }


  const getData = async () => {

    setLoading(true);


    await fetcher(`ikea/code/${notVirtual ? '' : 'v/'}${linkParams.code}`)
      .then((res) => {

        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }

        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        const ikeaDane: IkeaDaneInterface[] = res.result;
        const data : DataType[] = ikeaDane.map((ikea) => {

          return {
            id: ikea.id,
            rackName: ikea.ikeaMag.name,
            pcs: ikea.ikeaCode.ilosc,
            ikea2: ikea.ikea2,
            udc: ikea.udc,
            week: ikea.date_stamp,
            regDate: ikea.date,
            magDate: ikea.mag_date,
            terminalUsers: ikea.terminalUsers!,
          };

        });

        setTableSelected([]);
        setSelectedRowKeys([]);
        setTableData(data);
        setLoading(false);

      });


  }


  useEffect(() => {

    if(!linkParams.code) {
      return;
    }

    getData();

    props.handleSetMainTitle(`Materiał: ${linkParams.code}`);

    selectedMenuContext?.setSelectedKey(['material']);

    const breadcrumbItems: MainBreadcrumbItem[] =[{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Stan',
        label: 'Stan',
      },

      {
        href: '/stock/material/',
        key: 'Material',
        label: 'Materiał',
        menu: [
          {
            href: '/stock/material/',
            label: 'Materiał',
          },
          {
            href: '/stock/rack/',
            label: 'Lokalizacje',
          },
          {
            href: '/stock/rack-total/',
            label: 'Lokalizacje2',
          },
        ]

      },

      {
        href: `/stock/material/${linkParams.code}`,
        key: linkParams.code,
        label: linkParams.code,
      },

    ];

    props.setBreadcrumbHandler(breadcrumbItems);

  },[notVirtual]);

  interface DataType {
    id: number;
    rackName: string;
    pcs: number;
    ikea2: string;
    udc: number;
    week: number;
    regDate: Date | string;
    magDate: Date | null | string;
    terminalUsers: TerminalUsersInterface;
  }

  const columns : ColumnsType<DataType>= [
    {
      shouldCellUpdate: () => false,
      width: 130,
      title: 'Lokalizacja',
      dataIndex: 'rackName',
      key: 'rackName',
      sorter: (a,b) => a.rackName.localeCompare(b.rackName, undefined, {
        numeric: true,
      }),

      onFilter: (value, record) => record.rackName.toLowerCase().includes(String(value).toLowerCase()),
      filters: [
        ...Array.from(new Set(tableData.map(row => row.rackName))).map((rackName) => {
          return {
            text: rackName,
            value: rackName,
          }
        }),
      ],
      filterSearch: true,
      //filterDropdown,

      render: (value) => {
        return (
          <Link to={`/stock/rack/${value}`}>{value}</Link>
        )
      }

    },
    {
      shouldCellUpdate: () => false,
      width: 80,
      title: 'Szt',
      dataIndex: 'pcs',
      key: 'pcs',
      sorter: (a,b) => a.pcs - b.pcs,

      onFilter: (value, record) => record.pcs === value,
      filters: [
        ...Array.from(new Set(tableData.map(row => row.pcs))).map((pcs) => {
          return {
            text: String(pcs),
            value: pcs,
          }
        }),
      ],
      filterSearch: true,
    },
    {
      shouldCellUpdate: () => false,
      width: 180,
      title: 'SSCC',
      dataIndex: 'ikea2',
      key: 'ikea2',
      sorter: (a,b) => a.ikea2.localeCompare(b.ikea2, undefined, {
        numeric: true,
      }),

      onFilter: (value, record) => record.ikea2.toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,

      render: (value, record) => <a onClick={() => {setCurrentPalletId(record.id); handleSetModalIkeaDetailsOpened(true)}}>{value}</a>

    },
    {
      shouldCellUpdate: () => false,
      width: 100,
      title: 'UDC',
      dataIndex: 'udc',
      key: 'udc',

      sorter: (a,b) => a.udc - b.udc,

      onFilter: (value, record) => String(record.udc).toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,
    },
    {
      shouldCellUpdate: () => false,
      width: 100,
      title: 'tydzień',
      dataIndex: 'week',
      key: 'week',

      sorter: (a,b) => a.week - b.week,

      onFilter: (value, record) => String(record.week).toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,
    },
    {
      shouldCellUpdate: () => false,
      width: 200,
      title: 'Data streczowania',
      dataIndex: 'regDate',
      key: 'regDate',

      render: date => date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '',

      sorter: (a, b) => {
        const dataA = a.regDate === null ? 0 : moment(a.regDate).unix();
        const dataB = b.regDate === null ? 0 : moment(b.regDate).unix();
        return dataA - dataB;
      },
      onFilter: (value, record) => String(moment(record.regDate).format('DD/MM/YYYY HH:mm:ss')).toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,

    },
    {
      shouldCellUpdate: () => false,
      width: 250,
      title: 'Rejestracja przez',
      key: 'user',
      sorter: (a, b) => myStringSorter(a.terminalUsers ? a.terminalUsers.name : '',b.terminalUsers ? b.terminalUsers.name : ''),

      onFilter: (value, record) => record.terminalUsers.name.includes(String(value)),

      //@ts-ignore
      filters: [
        ...Array.from(new Set(tableData.map(row => {
          if(row.terminalUsers) {
            return row.terminalUsers.name
          }
        }))).map((name) => {
          return {
            text: name,
            value: name,
          }
        }),
      ],

      render: (value,record) => <>{record.terminalUsers ? record.terminalUsers.name : ''}</>,

      filterSearch: true,
    },
    {
      shouldCellUpdate: () => false,
      width: 200,
      title: 'Data magazynowania',
      dataIndex: 'magDate',
      key: 'magDate',
      render: date => date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '',

      sorter: (a, b) => {
        const dataA = a.magDate === null ? 0 : moment(a.magDate).unix();
        const dataB = b.magDate === null ? 0 : moment(b.magDate).unix();
        return dataA - dataB;
      },
      onFilter: (value, record) => String(moment(record.magDate).format('DD/MM/YYYY HH:mm:ss')).toLowerCase().includes(String(value).toLowerCase()),
      filterDropdown,
    },
  ];

  const handlePalletMoveClick = () => {
    if(tableSelected.length === 1) {
      handleSetModalOnePalletOpened(true);
      setCurrentPalletId(tableSelected[0].id);
      return;

    }
    if(tableSelected.length > 1) {
      ///Przenoszenie kilku pozycji
      handleSetModalMultiplePalletsOpened(true);
      return;


    }
    if(tableSelected.length === 0) {
      ///Nie zaznaczono żadnych pozycji
      message.open({
        type: 'warning',
        content: 'Żadna pozycja nie została zaznaczona!',
      });
      return;
    }


  }

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  const handleSetModalIkeaDetailsOpened = (opened: boolean) => {
    setModalIkeaDetailsOpened(opened);
  }

  const handleExcelClick = (data: DataType[]) => {
    const dataToExport = data.map((data: DataType,index) => {
      return {
        id: index+1,
        Lokalizacja: data.rackName,
        ilość: data.pcs,
        SSCC: data.ikea2,
        udc: data.udc,
        tydzień: data.week,
        'Data streczowania': myDateFormat(data.regDate),
        'Rejestracja przez': data.terminalUsers ? data.terminalUsers.name : '',
        'Data magazynowania': myDateFormat(data.magDate),
      }
    })
    exportToExcel(dataToExport);
  };


  const CheckBoxNotVirtual: JSX.Element = <Checkbox checked={notVirtual} onChange={(e) => setNotVirtual(e.target.checked)}>Nie wyświetlaj wirtualnych lokalizacji</Checkbox>

  if(permError) {
    return <PermError/>
  }

  return (<>
      <Table columns={columns}
             scroll={{ y: windowHeight*.6}}
             pagination={false}
             loading={loading}
             showHeader={true}
             bordered={true}
             rowKey={'id'}

             title={() => <TableHeader tableDataToPrint={{data: tableSelected.length === 0 ? tableData : tableSelected, allColumns: columns, selectedColumns: ['Lokalizacja', 'Szt','SSCC', 'UDC', 'tydzień']}} getData={getData} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} handleExcelExportAllClick={() => handleExcelClick(tableData)} additional={CheckBoxNotVirtual} handleMovePalletClick={handlePalletMoveClick} selectedCount={tableSelected.length}/>}

             footer={() => <TableFooter handleMovePalletClick={handlePalletMoveClick} selectedCount={tableSelected.length} totalCount={tableData.length}/> }
             rowSelection={{
               selectedRowKeys,
               type: 'checkbox',
               ...rowSelection,
             }}
             dataSource={tableData}
             style={{opacity: 0.9}}
             size={'small'}

      />
      {currentPalletId && <ModalMoveOnePallet handleGetData={getData} handleSetModalOpened={handleSetModalOnePalletOpened} open={modalOpened} palletId={currentPalletId} /> }
      {currentPalletId && <ModalIkeaIdDetails handleGetData={getData} handleSetModalOpened={handleSetModalIkeaDetailsOpened} open={modalIkeaDetailsOpened} palletId={currentPalletId} /> }

      <ModalMoveMultiplePallets open={modalMultipleMoveOpened} palletsId={tableSelected.map(item => item.id)} handleSetModalOpened={handleSetModalMultiplePalletsOpened} handleGetData={getData} />


    </>
  );
}
