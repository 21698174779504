import React, { useEffect, useRef, useState } from 'react';

import './PrintTable.css';
import ReactToPrint from 'react-to-print';
import { ColumnsType } from 'antd/es/table';

import logo from '../../logo.png';

export interface TableDataToPrint {
  allColumns: ColumnsType<any>;
  data: any[];
  selectedColumns?: string[];
}

interface PropsRef {
  data?: TableDataToPrint;
}

interface Props extends PropsRef {
  children: React.ReactNode;
  disabled?: boolean;
}
export const PrintTableRef = React.forwardRef((props: PropsRef, ref: any) => {


  const [title, setTitle] = useState<string[]>([]);
  const [dataIndex, setDataIndex] = useState<string[]>([]);
  const [trArray, setTrArray] = useState([]);

  useEffect(() => {

    ///Najpierw wyciągamy wszystkie title
    const bufTitle = props.data?.selectedColumns ? props.data?.selectedColumns: [];
    setTitle(bufTitle);

    //Wyciągamy wszystkie data index na podstawie buf index

    const bufDataIndex = props.data?.allColumns.filter((col) => bufTitle.findIndex((x) => x === col.title) !== -1)
      .map((n: any) => n.dataIndex);

    setDataIndex(bufDataIndex ? bufDataIndex : []);


    const glArr: any = [];

    const bufAllData = props.data?.data.map((dt,ix) => {

      const arr: any = [];

      bufDataIndex?.map((dataIx) => {

        if (Array.isArray(dataIx)) {
          let txt = '';
          if(dataIx.length === 2) {
            try {
              txt = dt[dataIx[0]][dataIx[1]];
            } catch (e) {
              txt = '';
            } finally {
              arr.push(txt);
            }
          } else if (dataIx.length === 3) {
            try {
              txt = dt[dataIx[0]][dataIx[1]][dataIx[2]];
            } catch (e) {
              txt = '';
            } finally {
              arr.push(txt);
            }


          }
        }
        else {
          arr.push(dt[dataIx]);
        }


      });

        glArr.push(arr);

    });

    setTrArray(glArr);
    console.log({glArr: glArr});

   console.log(props.data);
  },[props.data]);



  return (<div style={{display: 'inline'}} ref={ref}>
      <img src={logo} className={'pr-logo'} width={'40%'} style={{marginBottom: '20px'}} alt="SL Concept"/>
    <table className={'pr-table'}>
      <tbody style={{width: '100%'}}>
      <tr>
        {
          title.map((col) => <td className={'td-head'}>{col}</td>)
        }
      </tr>
      {
        trArray.map((item: any) => (
          <tr>
            {item.map((x: any) => <td>{x}</td>)}
          </tr>
        ))
      }

      </tbody>
    </table>
    </div>
  );

});


export const PrintTable = (props: Props) => {

  const printRef = useRef(null);

  return (
    <>
      <ReactToPrint
        trigger={() => <span>{props.children}</span>}
        content={() => props.disabled ? null : printRef.current}
      />
      <PrintTableRef data={props.data} ref={printRef} />
    </>
  )
}


