import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import React, { useContext, useEffect, useState } from 'react';
import { NavListInterface } from '../../../../types/navList.interface';
import { Badge, message, Table } from 'antd';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';
import { Link, useParams } from 'react-router-dom';
import { NavCompOneCodeInterface } from '../../../../types/nav-comp-one-code.interface';
import { ColumnsType } from 'antd/es/table';
import { myNumberSorter, myStringSorter } from '../../../../extras/sorters';
import { fetcher } from '../../../../extras/fetcher';
import { exportToExcel } from '../../../../extras/exportToExcel';
import { PermError } from '../../NotFoundPage/PermError';
import { NavStatusInfo } from './NavStatusInfo';
import { TableHeader } from '../../Ikea/Stan/TableHeader';
import { TableFooter } from '../../Ikea/Stan/TableFooter';
import { ButtonTransferNav } from './ButtonTransferNav';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const OneNavCode = (props: Props) => {
  const [tableData, setTableData] = useState<NavCompOneCodeInterface[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<NavCompOneCodeInterface[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [navList, setNavList] = useState<NavListInterface>();

  const [loading, setLoading] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const {windowHeight} = useWindowDimensions();

  const [permError, setPermError] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);

  const linkParams = useParams();

  useEffect(() => {

    const oneCodeB: MainBreadcrumbItem[] =
      linkParams.code ? [{
        href: `/nav/list/${linkParams.navId}/code/${linkParams.code}`,
        key: 'navOneCode',
        label: linkParams.code,
      }] : [];

    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Nav',
      label: 'Nav',
    },

      {
        href: '/nav/list/',
        key: 'Nav-List',
        label: 'Lista Wysyłek',
      },

      {
        href: `/nav/list/${linkParams.navId}/`,
        key: 'oneNav',
        label: navList ? `NAV${navList.nav}` : '...',
      },

      {
        href: `/nav/list/${linkParams.navId}/code/`,
        key: 'navCode',
        label: 'Pobrane Kody',
      },

      ...oneCodeB,

    ];


    props.handleSetMainTitle(navList ? `NAV${navList.nav}` : '...');
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['nav-list']);

  }, [navList]);

  const columns: ColumnsType<NavCompOneCodeInterface> = [
    {
      title: 'Kod',
      width: 120,
      dataIndex: 'code',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(tableData.map((arr) => arr.code))).map((val) => {
          return {
            text: val,
            value: val,
          }
        }),
      ],

      onFilter: (value, record) => record.code.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myStringSorter(a.code,b.code),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record) => <Link to={`/nav/list/${linkParams.navId}/code/${value}`}>{value}</Link>,

    },

    {
      title: 'UDC',
      dataIndex: 'udc',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.udc,b.udc),

    },

    {
      title: 'Jelcz',
      dataIndex: 'jelcz',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.jelcz,b.jelcz),

    },

    {
      title: 'LX02',
      dataIndex: 'lx02',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.lx02,b.lx02),
      render: (value, record) => <Badge count={value} showZero overflowCount={9999} color={record.jelcz === record.lx02 ? '#52c41a' : '#f5222d'} />
    },

    {
      title: 'Panattoni',
      dataIndex: 'panattoni',
      filterMode: 'tree',
      width: 180,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.panattoni,b.panattoni),
      render: (value, record) => (
        <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'column'}}>
        <Badge count={value} overflowCount={9999} showZero color={record.jelcz === record.panattoni ? '#52c41a' : '#f5222d'} />
          {record.jelczNavId && record.jelczNavId !== record.panattoniNavId ? <ButtonTransferNav getData={getData} key={record.udc} udc={record.udc} navId={Number(linkParams.navId)}/> : null}
        </div>
      )
    },


  ];

  const getNavComp = async () => {
    const res = await fetcher(`nav/list/${linkParams.navId}/comp/code/${linkParams.code ? linkParams.code : ''}`);
    setTableData(res.result ? res.result : []);
  }

  const getData = async () => {
    await setLoading(true);

    const data =  await fetcher(`nav/list/${linkParams.navId}`);

    if(data.status === 403) {
      setPermError(true);
      return;
    }

    await getNavComp();

    setNavList(data.result as NavListInterface);


    await setLoading(false);

  }

  useEffect(() => {
    getData();
  },[linkParams.code, linkParams.navId]);

  const handleExcelClick = (data: NavCompOneCodeInterface[]) => {



    const dataToExport = data.map((data: NavCompOneCodeInterface,index) => {

      return {
        id: index+1,
        Kod: data.code,
        UDC: data.udc,
        Jelcz: data.jelcz,
        LX02: data.lx02,
        Panattoni: data.panattoni,
      }
    })
    exportToExcel(dataToExport);

  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: NavCompOneCodeInterface[]) => {
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  if(permError) {
    return <PermError/>
  }

  return <>
    { navList && <NavStatusInfo status={navList.status} creditedByUser={navList.creditedByUser ? navList.creditedByUser : null} />}
    <h3>Pobrane Pozycje ({linkParams.code ? linkParams.code : 'Wszystkie'})</h3>
    {contextHolder}

    <Table
      rowKey={'udc'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={(records) => <TableHeader getData={getData} handleExcelExportAllClick={() => handleExcelClick(tableData)} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} additional={<></>} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />




  </>

}
