import React from 'react';
import { Header } from 'antd/es/layout/layout';
import { Col, MenuTheme, Row } from 'antd';
import { UserAvatar } from '../UserAvatar/UserAvatar';

interface Props {
  theme: MenuTheme;

}

export const MainHeader = (props: Props) => {

  return (
    <Header className="site-layout-background" style={{ padding: 0, backgroundColor: props.theme === 'light' ? '#fff' : undefined, }} >
      <Row style={{flexWrap: 'nowrap', justifyContent: 'space-between', marginLeft: 20, marginRight: 20}}>
        <Col>
            {/*<HeaderMenu theme={props.theme}/>*/}
        </Col>
        <Col>
            <UserAvatar/>
        </Col>
      </Row>
    </Header>
  )
}
