import React, { useEffect } from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';

interface Props {
  handleSetMainTitle: (title: string) => void;
  setBreadcrumbHandler: (items: MainBreadcrumbItem[] | null) => void;
}

export const NotFoundPage = (props: Props) => {

  const navigate = useNavigate();

  useEffect(() => {
    props.handleSetMainTitle('');
    props.setBreadcrumbHandler(null);
  },[]);

  return (
    <Result
      status="404"
      title="404"
      subTitle="Podana strona nie istnieje."
      extra={<Button type="primary" onClick={() => navigate('/')}>Wróć na stronę główną.</Button>}
    />

  )

}
