import React, { useContext } from 'react';
import { Avatar, Popover, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import './UserAvatar.css';
import { UserMenu } from '../UserMenu/UserMenu';
import { LoggedInContext } from '../../contexts/loggedIn.context';

interface Props {

}

export const UserAvatar = (props: Props) => {

  const loggedInContext = useContext(LoggedInContext);
  const PopoverContent: React.ReactNode = <UserMenu/>

  if(!(loggedInContext?.user)) {
    return <Spin size={'large'}/>
  }

  return (
    <Popover className={'no-printMe'} content={PopoverContent} title={`${loggedInContext?.user.firstname} ${loggedInContext?.user.lastname}` } trigger="hover" placement={'bottomRight'}>
      <Avatar size="large" className={'UserAvatar'} icon={<UserOutlined />} />
    </Popover>
  );
}
