import React, { useContext, useEffect, useState } from 'react';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { Link, useParams } from 'react-router-dom';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { ColumnsType } from 'antd/es/table';
import { myStringSorter } from '../../../../extras/sorters';
import { Table } from 'antd';
import { TableFooter } from '../../Ikea/Stan/TableFooter';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { fetcher } from '../../../../extras/fetcher';
import { ModalAddUser } from './ModalAddUser';
import { TableHeader } from '../UsersTerm/TableHeader';
import { PermError } from '../../NotFoundPage/PermError';

interface Props {
  handleSetMainTitle: (title: string) => void;
  setBreadcrumbHandler: (items: MainBreadcrumbItem[] | null) => void;
}
export const Users = (props: Props) => {

  const [tableData, setTableData] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [permError, setPermError] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const linkParams = useParams();



  const {windowHeight} = useWindowDimensions();

  interface DataType {
    id: number;
    username: string;
    fullName: string;
    organization: string;
  }

  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Admin',
      label: 'Administracja',
    },
      {
        href: '',
        key: 'users',
        label: 'Użytkownicy',
      },

      {
        href: `/admin/users/`,
        key: 'users1',
        label: 'Panel',
      },
    ];


    props.handleSetMainTitle(`Użytkownicy - Panel`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['users1']);

  }, []);


  const columns: ColumnsType<DataType> = [
    {
      title: 'Użytkownik',
      width: 500,
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(tableData.map((arr) => arr.username))).map((username) => {
          return {
            text: username,
            value: username,
          }
        }),
      ],

      onFilter: (value, record) => record.username.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myStringSorter(a.username, b.username),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record, index) => <Link to={`/admin/users/${record.id}`}>{record.username}</Link>,

    },

    {
      title: 'Imię i Nazwisko',
      width: 500,
      dataIndex: 'fullName',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(tableData.map((arr) => arr.fullName))).map((fullName) => {
          return {
            text: fullName,
            value: fullName,
          }
        }),
      ],

      onFilter: (value, record) => record.username.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myStringSorter(a.username, b.username),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

    },
    {
      title: 'Organizacja',
      dataIndex: 'organization',
      filterMode: 'tree',
      width: 250,

    },

  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const getData = async () => {
    await setLoading(true);

    const data = await fetcher(`admin/users/`);

    if(data.status === 403) {
      setPermError(true);
    }

    const myData = data.result.map((data: any) => {
      return {
        ...data,
        fullName: `${data.firstname} ${data.lastname}`,
      }
    })


    setTableData(myData);
    setTableSelected([]);
    setSelectedRowKeys([]);

    await setLoading(false);

  };

  useEffect(() => {
    getData();
  },[]);


  if(permError) {
    return <PermError/>
  }

  return     <><Table
    rowKey={'id'}
    rowSelection={{
      selectedRowKeys,
      type: 'checkbox',
      ...rowSelection,
    }}

    title={(records) => <TableHeader handleAddUserClick={() => setShowAddUserModal(true)} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />

    <ModalAddUser open={showAddUserModal} handleSetModalOpened={setShowAddUserModal} handleGetData={getData}/>

  </>
}
