import React, { useContext, useEffect, useState } from 'react';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetcher } from '../../../../extras/fetcher';
import { myDateFormat } from '../../../../extras/myDateFormat';
import { Badge, Button, Table, Tag, Tooltip } from 'antd';
import { TableHeader } from '../Stan/TableHeader';
import { TableFooter } from '../Stan/TableFooter';
import { ColumnsType, TableProps } from 'antd/es/table';
import { myDateSorter, myNumberSorter, myStringSorter } from '../../../../extras/sorters';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { ModalAddDelivery } from './ModalAddDelivery';
import { ModalDeliveryStatus } from './ModalDeliveryStatus';
import { Ecis } from './Ecis';
import { CheckDeliveryAlert } from './CheckDeliveryAlert';
import { DeleteDelivery } from './DeleteDelivery';
import { TripFillingLevel } from './TripFillingLevel';
import { PermError } from '../../NotFoundPage/PermError';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';
import { checkRule } from '../../../../extras/checkRule';
import { DeliveryPrinted } from './DeliveryPrinted';

interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}

export const OneTrip = (props: Props) => {

  const [tableData, setTableData] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);
  const [currentDeliveryNr, setCurrentDeliveryNr] = useState<number | null>(null);
  const [modalSapAtOpened, setModalSapAtOpened] = useState<boolean>(false);


  const [modalAddDeliveryOpened,setModalAddDeliveryOpened] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const selectedMenuContext = useContext(SelectedMenuContext);
  const {windowHeight} = useWindowDimensions();

  const [permError, setPermError] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);

  const linkParams = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[] = [{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Wysylka',
        label: 'Wysyłka',
      },

      {
        href: '/trip/',
        key: 'Tripy',
        label: 'Tripy',
      },

      {
        href: `/trip/${linkParams.trip}`,
        key: 'oneTrip',
        label: linkParams.trip,
      },

      {
        href: `/trip/${linkParams.trip}/all-delivery`,
        key: 'allDelivery',
        label: 'Wszystkie Delivery',
      },
    ];


    props.handleSetMainTitle(`Trip: ${linkParams.trip}`);
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['tripy']);

  }, []);


  interface DataType {
    id: number;
    nr: number;
    createdAt: Date;
    sapAt: Date;
    ecis: string;
    pcsPlan: number;
    pcsReady: number;
    pltPlan: number;
    pltReady: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Delivery',
      width: 150,
      dataIndex: 'nr',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(tableData.map((arr) => arr.nr))).map((delivery) => {
          return {
            text: delivery,
            value: delivery,
          }
        }),
      ],

      onFilter: (value, record) => record.nr.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myNumberSorter(a.nr, b.nr),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

      render: (value, record, index) => <Link to={`/trip/${linkParams.trip}/${value}`}>{value}</Link>,

    },
    {
      title: 'Pobrane w sap',
      dataIndex: 'sapAt',
      filterMode: 'tree',
      width: 250,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myDateSorter(a.sapAt, b.sapAt),

      render: (value, record) => {
        const img = record.sapAt ? <Tag color="success">{myDateFormat(record.sapAt)}</Tag>:<Tag color="error">Nie</Tag>;
        return <Button onClick={() => {setCurrentDeliveryNr(record.nr); setModalSapAtOpened(true) }} type={'text'}>{img}</Button>
      }

    },

    {
      title: 'ECIS',
      dataIndex: 'ecis',
      filterMode: 'tree',
      width: 250,
      align: 'center',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myStringSorter(a.ecis,b.ecis),

      render: (value, record) => <Ecis deliveryNr={record.nr} tripNr={Number(linkParams.trip)}/>


    },

    {
      width: 150,
      dataIndex: 'pcsPlan',
      title: 'Ilość Planowana',
      align: 'center',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.pcsPlan,b.pcsPlan),

      render : (value) => value === 0 ? <Badge overflowCount={9999} showZero color={'#f5222d'} count={value}/> : value

    },

    {
      width: 150,
      title: 'Ilość Pobrana',
      dataIndex: 'pcsReady',
      align: 'center',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.pcsReady,b.pcsReady),

      render: (value, record) =>  <Tooltip title={`${record.pcsReady}/${record.pcsPlan}`}><Badge overflowCount={9999} showZero color={record.pcsReady === record.pcsPlan && record.pcsReady !== 0 ? '#52c41a' : record.pcsReady === 0 ? '#f5222d' : '#faad14'} count={record.pcsReady}/></Tooltip>

    },

    {
      width: 200,
      title: 'Pobrana Ilość Palet',
      dataIndex: 'pltReady',
      align: 'center',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.pltReady,b.pltReady),
      render: (value, record) =>   <Tooltip title={`${record.pltReady}/${record.pltPlan}`}>
      <Badge overflowCount={9999} showZero color={record.pltReady === record.pltPlan && record.pltReady !== 0 ? '#52c41a' : record.pltReady === 0 ? '#f5222d' : '#faad14'} count={record.pltReady}/></Tooltip>

    },

    {
      width: 100,
      title: 'Wydruk',
      align: 'center',
      render: (value, record) =>  <DeliveryPrinted tripNr={Number(linkParams.trip)} deliveryNr={record.nr} />

    },

    {
      width: 50,
      title: '',
      align: 'center',
      filterMode: 'tree',
      render: (value, record) =>  <DeleteDelivery disabled={!checkRule('a2',loggedIn?.user?.rule!)} deliveryNr={Number(record.nr)} tripNr={Number(linkParams.trip)} getData={getData} />

    },


  ];



  const getData = async () => {
    await setLoading(true);

    const data = await fetcher(`ikea/trip/${linkParams.trip}/delivery`);

    if(data.status === 403) {
      setPermError(true);
      return;
    }

    const myData = data.result.map((record: any): DataType => {

      return {
        id: record.id,
        nr: record.nr,
        createdAt: record.createdAt,
        sapAt: record.sapAt,
        ecis: '',
        pcsPlan: record.deliveryData.reduce((prev: any, curr: any) => prev+curr.total,0),
        pcsReady: record.deliveryData.reduce((prev: any, curr: any) => prev+curr.collected,0),
        pltPlan: record.deliveryData.reduce((prev: any, curr: any) => prev+curr.totalPlt,0),
        pltReady: record.deliveryData.reduce((prev: any, curr: any) => prev+curr.collectedPlt,0),
      };
    });

    setTableData(myData);
    setTableSelected([]);
    setSelectedRowKeys([]);

    await setLoading(false);

  };

  useEffect(() => {
    getData();
  },[]);

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    /// console.log('params', pagination, filters, sorter, extra);
  };


  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };

  function handleExcelClick(data: DataType[]) {

  }

  const ButtonAddDelivery: JSX.Element =  <Button disabled={!checkRule('a2',loggedIn?.user?.rule!)} onClick={() => setModalAddDeliveryOpened(true)} size={'small'} icon={<PlusOutlined />}>Dodaj Delivery</Button>
  const ButtonAllCodes: JSX.Element =  <Button onClick={() => navigate('total')} size={'small'} icon={<EyeOutlined />}>Wszystkie Kody</Button>

  if(permError) {
    return <PermError/>
  }

  return  <>

    {
      tableData.map((x,index) => <CheckDeliveryAlert key={index} deliveryNr={String(x.nr)} tripNr={linkParams.trip!}/>)
    }

    <Table
      rowKey={'id'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}

      title={(records) => <TableHeader getData={getData} additional={<>{ButtonAddDelivery} {ButtonAllCodes}</>} selectedCount={selectedRowKeys.length}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={tableData.length}/>} dataSource={tableData} onChange={onChange} style={{opacity: 0.9}} size={'small'} />


    <ModalAddDelivery open={modalAddDeliveryOpened} handleSetModalOpened={setModalAddDeliveryOpened} handleGetData={getData}/>
    { currentDeliveryNr && <ModalDeliveryStatus open={modalSapAtOpened} tripNr={Number(linkParams.trip)} deliveryNr={currentDeliveryNr} handleSetModalOpened={setModalSapAtOpened} handleGetData={getData}/> }



    <TripFillingLevel tripNr={Number(linkParams.trip)}/>

  </>
};
