import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { Card, Input, Modal, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IkeaMagInterface } from '../../../../types/ikeaMag.interface';
import { UsersInterface } from '../../../../types/users.interface';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { fetcher } from '../../../../extras/fetcher';
import { IkeaDaneInterface } from '../../../../types/ikeaDane.interface';
import { TerminalUsersInterface } from '../../../../types/terminalUsersInterface';
import { PermError } from '../../NotFoundPage/PermError';
import { exportToExcel } from '../../../../extras/exportToExcel';
import { TableHeader } from '../Stan/TableHeader';
import { TableFooter } from '../Stan/TableFooter';
import { myDateSorter } from '../../../../extras/sorters';
import { myDateFormat } from '../../../../extras/myDateFormat';
import { AdditionalDescription } from './AdditionalDescription';


interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const HistoriaSSCC = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const { windowHeight } = useWindowDimensions();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<DataType[]>([]);
  const [tableData, setTableData] = useState<DataType[]>([]);

  const linkParams =  useParams();

  const selectedMenuContext = useContext(SelectedMenuContext);

  const [permError, setPermError] = useState<boolean>(false);




  const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm} : FilterDropdownProps) : JSX.Element => {

    return <div><Input
      allowClear
      value={selectedKeys[0]}
      onChange={(e) => {setSelectedKeys(e.target.value ? [e.target.value] : []);

        confirm({
          closeDropdown: false,
        });

      }}
      onPressEnter={() => confirm()}
    ></Input></div>

  }


  const getData = async () => {

    setLoading(true);


    await fetcher(`ikea/history/sscc/${linkParams.sscc}`)
      .then((res) => {

        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }

        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }

        const data : DataType[] = res.result;

        setTableSelected([]);
        setSelectedRowKeys([]);
        setTableData(data);
        setLoading(false);

      });


  }


  useEffect(() => {

    if(!linkParams.sscc) {
      return;
    }

    getData();

    props.handleSetMainTitle(`SSCC: ${linkParams.sscc}`);

    selectedMenuContext?.setSelectedKey(['historia-sscc']);

    const breadcrumbItems: MainBreadcrumbItem[] =[{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Historia',
        label: 'Historia',
      },

      {
        href: '',
        key: 'historia-sscc',
        label: 'SSCC',
      },

      {
        href: `/history/sscc/${linkParams.sscc}`,
        key: linkParams.sscc!,
        label: linkParams.sscc,
      },

    ];

    props.setBreadcrumbHandler(breadcrumbItems);

  },[]);


  const getType = (typeNumber: number) : string => {

    /* Rodzaj wykonanej operacji
*
* 0. Rejestracja
* 1. Magazynowanie Automatyczne
* 2. Przeniesienie
* 3. Pobranie do wysyłki
* 4. Usunięcie z wysyłki
* 5. Storno
*
* */

    switch (typeNumber) {
      case 0 : return 'Rejestracja';
      case 1 : return 'Magazynowanie';
      case 2 : return 'Przeniesienie';
      case 3 : return 'Pobranie do wysyłki';
      case 4 : return 'Usunięcie z wysyłki';
      case 5 : return 'Storno';
    }
    return '';
  }

  interface DataType {
    id: number;
    type: number;
    createdAt: Date | string;
    ikeaDane: IkeaDaneInterface;
    ikeaHistoryStorno: IkeaDaneInterface;
    currentMag: IkeaMagInterface;
    newMag: IkeaMagInterface;
    delivery: null | any;
    terminalUsers: TerminalUsersInterface;
    user: UsersInterface;
  }

  const columns : ColumnsType<DataType>= [
    {
      width: 130,
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a,b) => myDateSorter(a.createdAt, b.createdAt),
      render: (value) => myDateFormat(value),
    },
    {
      width: 150,
      title: 'Typ Akcji',
      dataIndex: 'type',
      key: 'type',

      render: (value) => {
        return getType(value);
      }

    },
    {
      width: 180,
      title: 'Źródłowy Magazyn',
      dataIndex: 'currentMag',
      key: 'currentMag',

      render: (value, record) => record.currentMag ? <Link to={`/stock/rack/${record.currentMag.name}`}>{record.currentMag.name}</Link> : '',

    },

    {
      width: 180,
      title: 'Docelowy Magazyn',
      dataIndex: 'newMag',
      key: 'newMag',

      render: (value, record) => record.newMag ? <Link to={`/stock/rack/${record.newMag.name}`}>{record.newMag.name}</Link> : '',

    },


    {
      width: 180,
      title: 'Delivery',
      dataIndex: 'delivery',
      key: 'delivery',

      render: (value, record) => record.delivery ? <><Link to={`/trip/${record.delivery.ikeaTrip.nr}/${record.delivery.nr}`}>{record.delivery.nr}</Link> <Link to={`/trip/${record.delivery.ikeaTrip.nr}`}>({record.delivery.ikeaTrip.nr})</Link></> : '',

    },

    {
      width: 180,
      title: 'Użytkownik',
      dataIndex: 'user',
      key: 'user',

      render: (value, record) => record.user ? <Tooltip title={record.user.organization}>{record.user.firstname} {record.user.lastname} (Panel)</Tooltip> : <Tooltip title={record.terminalUsers.organization}>{record.terminalUsers.name} (Terminal)</Tooltip>,

    },

  ];


  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    /*    getCheckboxProps: (record: DataType) => ({
          disabled: record.kod_elica === 'PRF123', // Column configuration not to be checked
          name: record.kod_elica,
        }),*/
  };


  const handleExcelClick = (data: DataType[]) => {
    const dataToExport = data.map((data: DataType,index) => {
      return {
        id: index+1,
        Data: myDateFormat(data.createdAt),
        'Typ Akcji': getType(data.type),
        'Źródłowy Magazyn': data.currentMag ? data.currentMag.name : '',
        'Docelowy Magazyn': data.newMag ? data.newMag.name : '',
        Delivery: data.delivery ? data.delivery.nr : '',
        'Użytkownik': data.user ? `${data.user.firstname} ${data.user.lastname} (Panel)`: `${data.terminalUsers.name} (Terminal)`,
      }
    });
    exportToExcel(dataToExport);
  };


  if(permError) {
    return <PermError/>
  }

  return (<>
           <Table columns={columns}
             scroll={{ y: windowHeight*.6}}
             pagination={false}
             loading={loading}
             showHeader={true}
             bordered={true}
             rowKey={'id'}

             title={() => <TableHeader getData={getData} handleExcelExportSelectedClick={() => handleExcelClick(tableSelected)} handleExcelExportAllClick={() => handleExcelClick(tableData)}  selectedCount={tableSelected.length}/>}

             footer={() => <TableFooter selectedCount={tableSelected.length} totalCount={tableData.length}/> }
             rowSelection={{
               selectedRowKeys,
               type: 'checkbox',
               ...rowSelection,
             }}
             dataSource={tableData}
             style={{opacity: 0.9}}
             size={'small'}

      />

      <Card title="Dodatkowe Informacje" bordered={false} style={{opacity: 0.9, marginTop: 20, textAlign: 'left'}} >
        {tableData[0] && tableData[0].ikeaDane && <>

            <AdditionalDescription delivery={tableData[0].delivery} ikeaDane={tableData[0].ikeaDane} />
        </>

        }

        {tableData[0] && tableData[0].ikeaHistoryStorno && <>
            <AdditionalDescription delivery={tableData[0].delivery} ikeaDane={tableData[0].ikeaHistoryStorno} />
        </>

        }

      </Card>


    </>
  );
}
