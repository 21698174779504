import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { MainBreadcrumbItem } from '../../../MainBreadcrumb/MainBreadcrumb';
import { SelectedMenuContext } from '../../../../contexts/selectedMenu.context';
import { LongTermChart } from './LongTermChart';
import { OneDayChart } from './OneDayChart';
import moment from 'moment';
import { OneYearChart } from './OneYearChart';


interface Props {
  setBreadcrumbHandler: (items: MainBreadcrumbItem[]) => void;
  handleSetMainTitle: (title: string) => void;
}
export const StatsReg = (props: Props) => {

  const linkParams =  useParams();

  const selectedMenuContext = useContext(SelectedMenuContext);

  const [yesterdayMaximize, setYesterdayMaximize] = useState(false);
  const [todayMaximize, setTodayMaximize] = useState(false);



  useEffect(() => {

    props.handleSetMainTitle(`Statystyki - Rejestracja Palet`);

    selectedMenuContext?.setSelectedKey(['stats-reg']);

    const breadcrumbItems: MainBreadcrumbItem[] =[{
      href: '',
      key: 'Ikea',
      label: 'Ikea',
    },
      {
        href: '',
        key: 'Stats',
        label: 'Statystyki',
      },

      {
        href: '/stats/reg',
        key: 'stats-reg',
        label: 'Rejestracja Palet',
      },

    ];

    props.setBreadcrumbHandler(breadcrumbItems);

  },[]);




  return (<>
    <LongTermChart type={'reg'} />
      <Row gutter={16}>
        <Col xl={todayMaximize ? 24 : 12} sm={24} style={{marginTop: 20}} onDoubleClick={() => setTodayMaximize(x => !x)}>
          <OneDayChart type={'reg'} day={moment(new Date()).format('YYYY-MM-DD')} name={'Dzisiaj'} title={'Dzisiaj'}/>
        </Col>
        <Col xl={yesterdayMaximize ? 24 : 12} sm={24} style={{marginTop: 20}} onDoubleClick={() => setYesterdayMaximize(x => !x)}>
          <OneDayChart type={'reg'} day={moment(new Date()).subtract('1','day').format('YYYY-MM-DD')} name={'Wczoraj'} title={'Wczoraj'}/>
        </Col>
      </Row>

    <Row gutter={16}>
      <Col xs={24} style={{marginTop: 20}} >
        <OneYearChart type={'reg'}  name={'year1'}/>
      </Col>
    </Row>



    </>
  );
}
