import React, { useEffect, useState } from 'react';
import { Progress, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

interface DataType {
  key: React.Key;
  kod_ikea: string,
  kod_elica: string,
  ilosc: number,
  typeName: string,
  size: number,
  stacking: number,
  plt: number,
  code_id: number,
  maxPltWithRack: number,
  saturation: number,
  totalPcs: number
}

interface Props {
 data : DataType[],
}


export const TableCodeLocation = (props: Props) => {

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    setData(props.data.map((row,index) => {
      return {
        ...row,
        key: index,
      }
    }))
  },[]);

  const columns: ColumnsType<DataType> = [
    {
      title: 'Materiał',
      dataIndex: 'kod_elica',
      key: 'kod_elica',
      render: (value, record, index) => <Link to={`/stock/material/${record.kod_elica}`}>{value}</Link>
    },
    {
      title: 'Kod Klienta',
      dataIndex: 'kod_ikea',
      key: 'kod_ikea',
      render: (value, record, index) => <Link to={`/stock/material/${record.kod_elica}`}>{value}</Link>
    },

    {
      title: 'Typ',
      dataIndex: 'typeName',
      key: 'typeName',
    },

    {
      title: 'Ilość sztuk',
      dataIndex: 'totalPcs',
      key: 'totalPcs',
    },
    {
      title: 'Ilość Palet',
      dataIndex: 'plt',
      key: 'plt',
    },

    {
      title: 'Wypełnienie',
      dataIndex: 'saturation',
      key: 'saturation',
      render: (value, record, index) => {
        const saturationPercentage = Math.round(record.saturation * 100);
        return <div key={index}><Progress style={{width: '90px',}} percent={saturationPercentage} showInfo={false}
                                          status="active" strokeColor={{from: '#108ee9', to: '#87d068'}}/>
          <div style={{display: 'inline', width: '25px'}}>{saturationPercentage}%</div>
        </div>
      },
    },

  ];


  return (
    <Table columns={columns} pagination={{pageSizeOptions: [5, 10], defaultPageSize: 5, hideOnSinglePage: true}}
           showHeader={true} bordered={false} dataSource={data} style={{opacity: 0.9}} size={'small'}/>
  )

}

