import React from 'react';
import { Footer } from 'antd/es/layout/layout';

import './MainFooter.css';

export const MainFooter = () => {
  return (
    <Footer className={'MainFooter'}>© Copyright 2023 SL Concept Sp. z o.o.</Footer>
  )
}
