import React, { useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import { MainBreadcrumb, MainBreadcrumbItem } from '../MainBreadcrumb/MainBreadcrumb';

import './MainContent.css';
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../logo.png';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { AutoLogout } from '../AutoLogout/AutoLogout';

interface Props {
  content: React.ElementType;
  //breadcrumbItems?: MainBreadcrumbItem[];
}

export const MainContent = (props: Props) => {


  const [breadcrumbItems, setBreadcrumbItems] = useState<MainBreadcrumbItem[]>([]);
  const [mainTitle, setMainTitle] = useState<string>('');
  const navigate = useNavigate();
  const { windowWidth } = useWindowDimensions();


  const handleSetBreadcrumbItems = (items: MainBreadcrumbItem[]) => {
    setBreadcrumbItems(items);
  }

  const handleSetMainTitle = (title: string) => {
    setMainTitle(title);
  }



  return (
    <Content style={{ margin: '0 0' }}>
      <AutoLogout />
      { windowWidth >= 768 && <MainBreadcrumb breadcrumbItems={breadcrumbItems}/> }
      { windowWidth >= 768 && <div className={'no-printMe'}
            style={{textAlign: 'left', marginLeft: 10, marginTop: 5, fontSize: 16, opacity: 0.8}}>
        <Button type={'text'} onClick={() => navigate(-1)}><IoChevronBackCircleOutline/></Button>
      </div>}
      <Link to={'/'}><img src={logo} className={windowWidth >= 768 ? 'printMeHide' : ''} width={'250'} style={{margin: 20}} /></Link>
      <h1 style={{fontSize: windowWidth < 768 ? 15 : undefined}} className={'no-printMe'}>{mainTitle}</h1>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360, }}>

        <props.content handleSetMainTitle={handleSetMainTitle} setBreadcrumbHandler={handleSetBreadcrumbItems}/>
      </div>
    </Content>
  )
}
