import React, { useContext, useEffect, useState } from 'react';
import { Button, message, Upload, UploadProps } from 'antd';
import { CopyOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { API_URL } from '../../../../config';
import { checkRule } from '../../../../extras/checkRule';
import { LoggedInContext } from '../../../../contexts/loggedIn.context';
import { ModalLx02 } from './ModalLx02';
import { fetcher } from '../../../../extras/fetcher';
import { Lx02ExcelInterface } from '../../../../types/lx02Excel.interface';


interface Props {
  navId: number;
}

export const UploadLX02 = (props: Props) => {

  const [data, setData] = useState<{isExist: boolean; p341: boolean; data: Lx02ExcelInterface[], nav: number;}>();

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const loggedIn = useContext(LoggedInContext);

  const [messageApi, contextHolder] = message.useMessage();


  const getData = async () => {
    await fetcher(`nav/list/${props.navId}/lx02/check`,'GET').then((data) => {
      setData(data.result);
    });

  };

  useEffect(() => {

    (async () => {
        await getData();
    })();

  },[]);

  const uploadProps: UploadProps = {
    name: 'file',
    action: `${API_URL}nav/list/${props.navId}/lx02`,
    withCredentials: true,
    headers: {
      mode: 'cors',
      method: 'POST',
    },
    beforeUpload: (file) => {

      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error(`${file.name} nie jest plikiem Excel - LX02!`);
      }

      const sizeOk = file.size <= 380000;

      if (!sizeOk) {
        message.error(`${file.name} jest za duży!`);
      }

      return (isExcel && sizeOk) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      //  console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        getData();
        if(info.file.response.error) {
          return message.error(info.file.response.error);
        }

        message.success(`${info.file.name} LX02 został dodany!`);
      } else if (info.file.status === 'error') {
        message.error(`Błąd przy uploadzie pliku: ${info.file.name}.`);
      }
    },
    showUploadList: false,
  };

  const copyToClip = (text: string) => {
    navigator.clipboard.writeText(text);


    messageApi.open({
      type: 'success',
      content: 'Skopiowano do schowka!',
    });

  }


  return (
  <>
     {!!!data?.isExist ? <Upload {...uploadProps} accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
             disabled={!checkRule('c2', loggedIn?.user?.rule!)}>
      <Button disabled={!checkRule('c2', loggedIn?.user?.rule!)} size={'small'} icon={<UploadOutlined />}>Import
        LX02</Button>
    </Upload>:
       <>
         <Button size={'small'} icon={<EyeOutlined />} onClick={() => {
           setModalOpened(true);
             }}><span style={{color: data.p341 ? 'red' : 'green'}}>Podgląd LX02</span>
          </Button>

         {data.p341 && <>

             <Button size={'small'} icon={<CopyOutlined />} onClick={() => {

               const codeList = data.data.filter((x: any) => x.plant === 'P341')
                 .map((x: any) => x.code);
               // Kopiowanie kodów do schowka

               const uniqueCode: string[] = Array.from(new Set(codeList));
               copyToClip(uniqueCode.join('\n'));
             }}><span style={{color: 'red'}}>Kopiuj Kody P341</span>
             </Button>

             <Upload {...uploadProps} accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                  disabled={!checkRule('c2', loggedIn?.user?.rule!)}>
           <Button disabled={!checkRule('c2', loggedIn?.user?.rule!)} size={'small'} icon={<UploadOutlined />}>Uploaduj
             Ponownie LX02</Button>
         </Upload></>}

       </>

     }

    { data && <ModalLx02 lx02Data={data.data} open={modalOpened} handleSetModalOpened={setModalOpened} nav={data.nav}/>}

    {contextHolder}
  </>
  );
}
