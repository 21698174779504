import React, { useEffect, useState } from 'react';
import { Card, InputNumber, Modal, Spin } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Cookies from 'universal-cookie';
import { fetcher } from '../../../../extras/fetcher';
import { PermError } from '../../NotFoundPage/PermError';
import { myNumberSorter } from '../../../../extras/sorters';

interface Props {
  name: string;
  title? : string;
  type: 'reg' | 'storage';
}
export const OneYearChart = (props: Props) => {

  const cookies = new Cookies();

  const [dataChart1, setDataChart1] = useState<any[]>([]);
  const [dataChart1Year, setDataChart1Year] = useState<number>(2023);

  const [chartColors] = useState(['#5499C7', '#AF7AC5', '#52BE80', '#F4D03F', '#F5B041']);

  const [permError, setPermError] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);



  useEffect(() => {
    getData();
  },[dataChart1Year]);


  const getData = async () => {

    setLoading(true);

    await fetcher(`ikea/stats/${props.type}/year/` + (dataChart1Year ? dataChart1Year : ''))
      .then((res) => {

        if (res.status === 403) {
          setLoading(false);
          setPermError(true);
          return;
        }

        if(res.result.error) {
          setLoading(false);
          return Modal.error({
            title: 'Błąd',
            content: res.result.error,
          });

        }


        const dateChartFormat = res.result;

        const allMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        allMonths.map((m) => {
          const exist = dateChartFormat.findIndex((x: any) => x.month === m);

          if(exist === -1) {
            dateChartFormat.push({
              count: 0,
              month: m,
            });
          }

        });


        setDataChart1(dateChartFormat.sort((a: any,b : any) => myNumberSorter(a.month, b.month)));
        setLoading(false);

      });

  }


  return (
    permError ? <PermError/> : <Card title={

      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{width: 200}}></div>
        <div style={{width: 200}}>{props.title ? props.title : `Wykres Roczny: ${dataChart1Year}`}</div>
        <div style={{fontSize: 'smaller', justifyContent: 'flex-end', display: 'flex', width: 200}}><div style={{marginTop: 5}}>Rok:</div> <InputNumber value={dataChart1Year} onChange={(value) => setDataChart1Year(value as number)} bordered={false} style={{width: '65px', backgroundColor: 'transparent', fontSize: 'smaller'}}/></div>
      </div>

    } size={'small'} style={{height: 350}}>
      <Spin spinning={loading}>
      <ResponsiveContainer width={'99%'} height={300}>
        <BarChart
          width={500}
          height={350}
          data={dataChart1}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey={"month"} />
          <YAxis dataKey={'count'} />
          <Tooltip/>

          <Legend/>
          {
            <Bar dataKey={'count'} name={'Łącznie'} fill={'#ef343a'} />
          }


        </BarChart>
      </ResponsiveContainer>
      </Spin>
    </Card>
  )
}
