import { ColumnsType } from 'antd/es/table';
import { Lx02ExcelInterface } from '../../../../types/lx02Excel.interface';
import { myNumberSorter, myStringSorter } from '../../../../extras/sorters';
import { Table } from 'antd';
import React, { useState } from 'react';
import { TableFooter } from '../../Ikea/Stan/TableFooter';

interface Props {
  lx02Data: Lx02ExcelInterface[];
}
export const TableLx02 = (props: Props) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSelected, setTableSelected] = useState<Lx02ExcelInterface[]>([]);



  const columns: ColumnsType<Lx02ExcelInterface> = [
    {
      title: 'Materiał',
      width: 130,
      dataIndex: 'code',
      filterMode: 'tree',
      filters: [
        ...Array.from(new Set(props.lx02Data.map((arr) => arr.code))).map((val) => {
          return {
            text: val,
            value: val,
          }
        }),
      ],

      onFilter: (value, record) => record.code.toString().indexOf(String(value)) === 0,

      sorter: (a, b) => myStringSorter(a.code,b.code),
      sortDirections: ['descend', 'ascend'],
      filterSearch: true,

    },
    {
      title: 'Zakład',
      dataIndex: 'plant',
      filterMode: 'tree',
      width: 80,
      sorter: (a,b) => myStringSorter(a.plant,b.plant),

      render: (value) => <span style={{ color: value === 'P341' ? 'red' : 'black', fontWeight: value === 'P341' ? 'bold' : ''}}>{value}</span>

    },

    {
      title: 'Skład',
      width: 80,
      dataIndex: 'composition',
      filterMode: 'tree',
      sorter: (a,b) => myStringSorter(a.composition, b.composition),

    },

    {
      width: 140,
      title: 'Dostępny zapas',
      dataIndex: 'qty',
      filterMode: 'tree',
      sorter: (a,b) => myNumberSorter(a.qty,b.qty),

    },

    {
      width: 165,
      title: 'Jednostka składow.',
      dataIndex: 'udc',
      filterMode: 'tree',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: (a,b) => myNumberSorter(a.udc,b.udc),

    },

  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Lx02ExcelInterface[]) => {
      setTableSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <Table

      rowKey={'udc'}
      rowSelection={{
        selectedRowKeys,
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns} pagination={false} showHeader={true} bordered={true} footer={() => <TableFooter selectedCount={selectedRowKeys.length} totalCount={props.lx02Data.length}/>} dataSource={props.lx02Data} style={{opacity: 0.9}} size={'small'} />
  )


}
